import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./loginPage.css";
import { Formik } from 'formik';
import { ForgetSchema } from '../../Validations/Forget.js';
import { devInstance as axios } from "../../Plugin/axios";
export const ForgetPassword = () => {
    const [eye, seteye] = useState(true);
    const [password, setpassword] = useState("password");
    const [type, settype] = useState(false);
    const [success,setsuccess]=useState('')
    const [err,seterr]=useState('')
    const[resp,setresp]=useState('')
    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
            settype(true);
        } else {
            setpassword("password");
            seteye(true);
            settype(false);
        }
    }
    return (
        <>
        <Formik
        initialValues={{ ad_email: ''}}
        validationSchema={ForgetSchema}
        onSubmit={(values) => {
    axios.post('/forgetpassword',values).then((res)=>{
        if(res.data.code===403){
            seterr(res.data.message)
         }else{
            setresp(res.data.message)
         }
     
   
    })
        
          
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
            <form onSubmit={handleSubmit}>
            <div className='container-fluid admin-login'>
                <div className='row admin-login'>
                    <div className='col-md-6'>
                        <img src="./login-banner.png" />
                    </div>
                    <div className='col-md-6 admin-inputs'>
                        <div className='admin-content'>
                            <h4>ABACUSLY - LMS</h4>
                            <h6>Forgot Password</h6>
                            <div class="form-group">
                            <p style={{color:'red', }}>{err}</p>
                                <p style={{color:'green', }}>{resp}</p>
                            {errors.ad_email && touched.ad_email && <p className='err2' style={{color:'red', }}>{errors.ad_email}</p>}
                                <input type="email" class="form-control"  placeholder="Please enter Login Email id" name="ad_email" id={errors.ad_email && touched.ad_email? "error":""} onChange={handleChange} onBlur={handleBlur} value={values.ad_email} />
                            </div>
                            <div>
                               <button className='sign-in'    
>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            </form>)}
             </Formik>
            
        </>
    )
}
