import React, { useState,useEffect } from 'react';
import BlogEdit from "../../../src/images/purple-pencil.svg";
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
export const UserChangePassword = () => {
    const [password,setpassword]=useState("")
    const [confirmpassword,setconfirmpassword]=useState("");
    const [ip, setIp] = useState();
    const [city, setCity] = useState();
    const { user } = useSelector((state) => state.user);
    const token = user.token
    const [data,setData]=useState('');
    const[errors,seterr]=useState('')
    const [datap,setDatap]=useState([]);
    const navigate = useNavigate();
    const {link} = useParams();
    const baseurl = axios.defaults.baseURL
    useEffect(()=>{
        getData();
        fetchdata();
        fetchdatap()
      },[])
      const fetchdata = ()=>{
        axios
        .get("/edituser/"+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            console.log(res.data.data)
            setData(res.data.data);
        });
    }
    const fetchdatap = ()=>{
        axios
        .get("/passwordhistoryuser/"+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            console.log(res.data.data)
            setDatap(res.data.data);
        });
    }
      const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIp(res.data.IPv4)
        setCity(res.data.city)
      }
    const handlesubmit=()=>{
        const data ={password,confirmpassword,ip,city}
        
        
        axios.post('/change-password/'+link,data,{
            headers: {
           'x-access-token': token,
                }}).then((res)=>{
                    if(res.data.code == 400){
                        seterr(res.data.message)
                      }
                    if(res.data.code ===200){
                        toast.success('Password Changed succesfully')
                        navigate('/app/manage-user')
                      }else{
                        toast.error('Please try again later')
                      }
        })
    }
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>User Profile</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>User Profile</p>
            </div>
            <div className='user-view-section'>
                <div className='user-view-col'>
                    <div className='profile-info1'>
                        <div>
                        <img src={baseurl+data.img} width={200} height={200} />
                        </div>
                        <div>
                            <p>Students Name: <span>{data.first_name+" "+data.last_name}</span></p>
                            <p>Student Mobile Number: <span>{data.mobile_number}</span></p>
                            <p>WhatsApp Mobile No : <span>{data.whatsapp_numbers}</span></p>
                            <p>Student Email Id: <span>{data.student_email}</span></p>
                            <p>Parents Email Id: <span>{data.parent_email}</span></p>
                            <p>Gender <span>{data.gender}</span></p>
                            <p>DOB: <span>{data.dob ? (data.dob.split("T"))[0] : "NA"}</span></p>
                        </div>
                    </div>
                    <div></div>
                    <div className='profile-info2'>
                        <div>
                            <p>Full Address: <span>{data.address}</span></p>
                            <p>Country : <span>{data.country_id}</span></p>
                            <p>State: <span>{data.state_id}</span></p>
                            <p>City: <span>{data.city_id}</span></p>
                            <p>Registration Date & Time: <span> {data.createdAt ? (data.createdAt.split("T"))[0] : "NA"}</span></p>
                        </div>
                        <div>
                            <img src={BlogEdit} />
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <h3>Order & Analytics</h3>
                    <div className='order-analytics '>
                    <Link to={"/app/view-user/"+link}><button >Order History</button></Link>
                        <Link to={"/app/user-analytics/"+link}><button >Analytics</button></Link>
                        <Link to={"/app/user-change-password/"+link}><button className='account-btn'>Change Password</button></Link>
                    </div>
                </div>
                <div className='user-change-pass'>
               
                    <div class="form-group user-change-pass-mr">
                        <label for="exampleInputEmail1">Enter New Password</label>
                        <input type="password" class="form-control" placeholder="Enter New password" onChange={(e)=>setpassword(e.target.value)} />
                        <p style={{color:"red"}}>{errors}</p>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Confirm New Password</label>
                        <input type="password" class="form-control" placeholder="Confirm New Password" onChange={(e)=>setconfirmpassword(e.target.value)} />
                    </div>
                    <div>
                        <button onClick={handlesubmit}>Update</button>
                    </div>
                </div>
                <div className=''>
                    <div className='row'>
                        <div className='col-6 pass-history'>
                            <h6>Password Changed history</h6>
                            <div className='user-details-cols gray-bg'>
                                <div>
                                    <h5>Date & Time</h5>
                                </div>
                                <div className=''>
                                    <h5>IP Address</h5>
                                </div>
                                <div className=''>
                                    <h5>Location</h5>
                                </div>
                            </div>
                            {datap && datap.map((item,i)=>(
                                <div className='user-details-cols white-bg' key={i}>
                                <div>
                                    <h5>{item.createdAt}</h5>
                                </div>
                                <div className=''>
                                    <h5>{item.ip}</h5>
                                </div>
                                <div className=''>
                                    <h5>{item.location}</h5>
                                </div>
                            </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
