import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import BlogDelete from "../../../src/images/purple-delete.svg"
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
const ContactUsEnquiry = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    const [data, setdata] = useState([]);
    const deleteenquiry=(id)=>{
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete('/deletecontactusenquiry/'+id, {
                    headers: {
                        'x-access-token': token,
                        'Accept': 'application/json',
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then(res => {
                        fetchdata()
                        toast.success('succesfully deleted');
                    })
                    .catch(err => toast.error('Error in  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }

    useEffect(() => {
        fetchdata();
    }, [])
    const fetchdata=()=>{
        axios.get('/getallcontactusenquiry', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setdata(res.data.data)
        })
    }
  return (
    <div>
    <div className='hide-in-desktop'>
        <div className='d-flex profile-section'>
            <p className='profile-head'>Manage ContactUs Enquiry</p>
            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
            <p className='profile-pera'>Manage ContactUs Enquiry</p>
        </div>
    </div>
    <div className='hide-in-mobile'>
        <div className='profile-section'>
            <p className='profile-head'>Manage ContactUs Enquiry</p>
            <div className='d-flex'>
                <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Manage ContactUs Enquiry</p>
            </div>
        </div>
    </div>
    <div className='pages-tabs admin-table'>
        <div className='d-flex justify-content-between'>
            <h6>Manage ContactUs Enquiry</h6>
            
        </div>
        <div className='mobile-table-scroll'>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Date & Time</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Message</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((item, i) => (

                        <tr key={i}>
                            <td>{item.createdAt}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.subject}</td>
                            <td>{item.mobile_number}</td>
                            <td>{item.message}</td>
                            
                            <td className='blog-icons'>
                                {/* <Link to={'/app/edit-slider/'+item._id} data-toggle="tooltip" title="Edit Slider"> <img src={BlogEdit} /></Link>
                                <Link to="#" onClick={() => setViewId(item._id)} data-toggle="tooltip" title="View Slider Details"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link> */}
                                <Link to="#" onClick={() => deleteenquiry(item._id)} data-toggle="tooltip" title="Delete"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>

                    ))}
                </tbody>
            </table>
        </div>
    </div>
    
    <ToastContainer />
</div>
  )
}

export default ContactUsEnquiry

