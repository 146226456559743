
import React,{useState,useEffect} from 'react';
import BlogImg from "../../../src/images/blog.png"
import { devInstance as axios } from "../../Plugin/axios";
export const ViewContactModal = (props) => {
    
    const [data,setData]=useState('')
    const baseurl=axios.defaults.baseURL;
    useEffect(()=>{
        viewe()
        
    },[])
    const viewe = () => {
        const data = { "_id":props.data };
        axios.post('/getcontactbyid',data).then(res => {
            setData(res.data.data)

        });
    };
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Address Name:</h6>
                    <p>{data.AddressName}</p>
                </div>
                <div>
                    <h6>Country:</h6>
                    <p>{data.country}</p>
                </div>
                <div>
                    <h6>State:</h6>
                    <p>{data.state}</p>
                </div>
                <div>
                    <h6>City</h6>
                    <p>{data.city}</p>
                </div>
                <div>
                    <h6>Zip Code</h6>
                    <p>{data.ZipCode}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Full Address : </h6>
                <p>{data.FullAddress}</p>
            </div>
            <div className='video-description'>
                <h6>Map : </h6>
                <span style={{height:"100px"}} dangerouslySetInnerHTML={{__html:data.GoogleIframe}}></span>
            </div>

        </div>
    )
}
