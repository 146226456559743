import React, { useEffect, useState } from "react";
import { BlogEditor } from "../BlogPage/MyEditor";
import "./manageCourses.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { ToastContainer, toast } from "react-toastify";
import { Calculate } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
export const ManageCoursesForm = () => {
  const [rows, setRows] = useState([{}]);
  const columnsArray = ["name"]; // pass columns here dynamically

  const handleAddRow = () => {
    const item = {};
    setInputFields([...inputFields, { heading: "", detail: "" }])
    setRows([...rows, item]);
  };

  const handleRemoveSpecificRow = (idx) => {
    const rows1 = [...inputFields];
    rows1.splice(idx, 1);
    setInputFields(rows1);
    const tempRows = [...rows];
    tempRows.splice(idx, 1);
    setRows(tempRows);
  };

  const updateState = (index, event) => {
    const { name, value } = event.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
    // setRows(list);
    console.log(inputFields)
  };

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;
  const { user } = useSelector((state) => state.user);
  const token = user.token;
  const [Category, setCategory] = useState([]);
  const [fetchData, setFetchdata] = useState(true);
  const fetchDatacall = () => {
    setFetchdata((t) => !t);
  };
  const [Course_Name, setCourse_Name] = useState("");
  const [Course_Inclusion, setCourse_Inclusion] = useState("");
  const [Course_Duration, setCourse_Duration] = useState("");
  const [Course_Fees, setCourse_Fees] = useState("");
  const [Course_Discount, setCourse_Discount] = useState(0);
  const [Course_Offered_Price, setCourse_Offered_Price] = useState("");
  const [Course_Meta_Title, setCourse_Meta_Title] = useState("");
  const [Course_Meta_Description, setCourse_Meta_Description] = useState("");
  const [Course_Canonical, setCourse_Canonical] = useState("");
  const [Course_Twitter_Card, setCourse_Twitter_Card] = useState("");
  const [Course_OG_Tag, setCourse_OG_Tag] = useState("");
  const [Course_Meta_Keyword, setCourse_Meta_Keyword] = useState("");
  const [CategoryId, setCategoryId] = useState("");
  const [is_TOP, setis_TOP] = useState(0);
  const [is_Popular, setis_Popular] = useState(0);
  const [Course_URL_Slug, setCourse_URL_Slug] = useState("");
  const [learn, setlearn] = useState("");
  const [Course_Short_Description, setCourse_Short_Description] = useState("");
  const [Course_Details_Description, setCourse_Details_Description] = useState("");
  const [Features_Heading, setFeatures_Heading] = useState("");
  const [Features_Details, setFeatures_Details] = useState("");
  const [inputFields, setInputFields] = useState([{ heading: "", detail: "" }]);
  const bodynew1 = React.createRef();
  const bodynew2 = React.createRef();
  const bodynew3 = React.createRef();
  const [file, setFile] = useState();
  const [img, setimg] = useState('');
  const navigate = useNavigate();
  const handleCourse_Name = (e) => {
    setCourse_Name(e.target.value);
    var b = e.target.value.toLowerCase().replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    setCourse_URL_Slug(b);
  };
  const handleFeatures_Details = (e) => {
    setFeatures_Details(e.target.value);
  };
  const handleFeatures_Heading = (e) => {
    setFeatures_Heading(e.target.value);
  };
  const handleCourse_Details_Description = (e) => {
    setCourse_Details_Description(e.target.value);
  };
  const handleCourse_Short_Description = (e) => {
    setCourse_Short_Description(e.target.value);
  };
  const handleCourse_URL_Slug = (e) => {
    setCourse_URL_Slug(e.target.value);
  };
  const handleCourse_Inclusion = (e) => {
    setCourse_Inclusion(e.target.value);
  };
  const handleCourse_Duration = (e) => {
    setCourse_Duration(e.target.value);
  };
  const handleCourse_Fees = (e) => {
    setCourse_Fees(e.target.value);
    setCourse_Offered_Price(e.target.value)
  };
  const handleCourse_Discount = (e) => {
    let d = e.target.value;
    if (e.target.value >= 100) {
      alert("Discount Percentage Not Be Graeter or Equal to 100%")
    } else if (e.target.value < 0) {
      alert("Discount Percentage Not Be less than 0 %")
    } else {
      setCourse_Discount(e.target.value);
      setCourse_Offered_Price(Course_Fees * (100 - d) / 100)
    }


  };
  const handleCourse_Offered_Price = (e) => {
    setCourse_Offered_Price(e.target.value);

  };
  const handleCourse_Meta_Title = (e) => {
    setCourse_Meta_Title(e.target.value);
  };
  const handleCourse_Meta_Description = (e) => {
    setCourse_Meta_Description(e.target.value);
  };
  const handleCourse_Canonical = (e) => {
    setCourse_Canonical(e.target.value);
  };
  const handleCategoryId = (e) => {
    setCategoryId(e.target.value);
  };
  const handleCourse_Twitter_Card = (e) => {
    setCourse_Twitter_Card(e.target.value);
  };
  const handleCourse_OG_Tag = (e) => {
    setCourse_OG_Tag(e.target.value);
  };
  const handleCourse_Meta_Keyword = (e) => {
    setCourse_Meta_Keyword(e.target.value);
  };


  const handlesubmit = (e) => {
    const view1 = bodynew1.current.view;
    const view2 = bodynew2.current.view;
    const view3 = bodynew3.current.view;
    var body = EditorUtils.getHtml(view1.state);
    var body2 = EditorUtils.getHtml(view2.state);
    var body3 = EditorUtils.getHtml(view3.state);
    var heading = [];
    var detail = [];
    inputFields.map((item) => {

      heading.push(item.heading);
      detail.push(item.detail);
    })
    const data = {
      Course_Canonical,
      Course_Discount,
      Course_Duration,
      Course_Fees,
      Course_Meta_Description,
      Course_Meta_Keyword,
      Course_Meta_Title,
      Course_Meta_Title,
      Course_Name,
      Course_OG_Tag,
      Course_Offered_Price,
      is_Popular,
      is_TOP,
      "learn": body2,
      Course_Twitter_Card,
      CategoryId,
      Course_URL_Slug,
      "Course_Inclusion": body,
      img,
      Course_Short_Description,
      "Course_Details_Description": body3,
      "Features_Heading": JSON.stringify(heading),
      "Features_Details": JSON.stringify(detail),
    }
    axios.post("/api/course/AddCourse", data, {
      headers: {
        "x-access-token": token,
        "Accept": "application/json",
        "Content-Type": "multipart/form-data"
        // "Content-Type": "multipart/form-data"
      },
    })
      .then((res) => {
        if (res.data.code === 200) {
          toast.success("Course added successfully");
          navigate('/app/manage-course');
        } else {
          toast.error("Please try again later");
        }
      });
  }
  const handleimg = (e) => {
    console.log(e.target.files[0])
    setFile(URL.createObjectURL(e.target.files[0]));
    setimg(e.target.files[0]);
  };
  const CourseSchema = Yup.object().shape({
    Course_Canonical: Yup.string().required("Category Name is required"),
    Course_Discount: Yup.number()
      .positive("Must be more than 0")
      .integer("Must be more than 0")
      .required("Course Fee is required"),
    Course_Duration: Yup.string().required("Course Duration is required*"),
    Course_Fees: Yup.number()
      .positive("Must be more than 0")
      .integer("Must be more than 0")
      .required("Course Fee is required"),

    Course_Name: Yup.string().required("Course Name is required*"),
    Course_Offered_Price: Yup.number()
      .positive("Must be more than 0")
      .integer("Must be more than 0")
      .required("Course Fee is required"),
    CategoryId: Yup.string().required(" Category are required*"),
    Course_URL_Slug: Yup.string().required(" URL Slug is required*"),
    Course_Inclusion: Yup.string().required(" Course Inclusion  is required*"),
    Course_Short_Description: Yup.string().required(
      " Short Description Inclusion  is required*"
    ),
    Features_Details: Yup.string().required("Features Details  is required*"),
    Features_Heading: Yup.string().required("Features heading  is required*"),
  });

  useEffect(() => {
    axios
      .get("/api/category/getCategory", {
        headers: {
          "x-access-token": token,
          Accept: "application/json",

        },
      })
      .then((res) => {
        setCategory(res.data.Data);
      });
  }, []);

  const formik = useFormik({

    enableReinitialize: true,
    initialValues: {
      Course_Canonical: "",
      Course_Discount: "",
      Course_Duration: "",
      Course_Fees: "",
      Course_Meta_Description: "",
      Course_Meta_Keyword: "",
      Course_Meta_Title: "",
      Course_Meta_Title: "",
      Course_Name: "",
      Course_OG_Tag: "",
      Course_Offered_Price: Course_Offered_Price,
      is_Popular: is_Popular,
      is_TOP: is_TOP,
      learn: learn,
      Course_Twitter_Card: "",
      CategoryId: "",
      Course_URL_Slug: "",
      Course_Inclusion: "",
      Course_Short_Description: "",
      Course_Details_Description: "",
      Features_Heading: "",
      Features_Details: "",
    },
    validationSchema: CourseSchema,
    onSubmit: (values) => {
      const view1 = bodynew1.current.view;
      const view2 = bodynew2.current.view;
      const view3 = bodynew3.current.view;
      var body = EditorUtils.getHtml(view1.state);
      var body2 = EditorUtils.getHtml(view2.state);
      var body3 = EditorUtils.getHtml(view3.state);
      values.Course_Inclusion = body;
      values.learn = body2;
      values.Course_Details_Description = body3;
      axios.post("/api/course/AddCourse", values, {
        headers: {
          "x-access-token": token,
          Accept: "application/json",
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            toast.success("Course added successfully");
          } else {
            toast.error("Please try again later");
          }
        });
    },
  });
  const { errors, touched, values } = formik;

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <div className='hide-in-desktop'>
        <div className='d-flex profile-section'>
          <p className='profile-head'>Manage Course</p>
          <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
          <p className='profile-pera'>Manage Course</p>
        </div>
      </div>
      <div className='hide-in-mobile'>
        <div className='profile-section'>
          <p className='profile-head'>Manage Course</p>
          <div className='d-flex'>
            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
            <p className='profile-pera'>Manage Course</p>
          </div>
        </div>
      </div>
      <div className="draft-input2">
        <div className="draft-mr">
          <div class="form-group status-dropdown">
            <label for="exampleInputEmail1">Select Category</label>
            <select
              class="form-select"
              aria-label="Default select example"
              name="CategoryId"
              value={CategoryId}
              onChange={(e) => {
                handleCategoryId(e);
                formik.handleChange(e);
              }}
            >
              <option selected>Select Category</option>
              {Category?.map((item, i) => (
                <option key={i} value={item?.Cat_Category_Name}>
                  {item?.Cat_Category_Name}
                </option>
              ))}
            </select>
            {errors.CategoryId && touched.CategoryId && (
              <p style={{ color: "red" }}>{errors.CategoryId}</p>
            )}
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Course Name </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter course name here"
              name="Course_Name"
              value={Course_Name}
              onChange={(e) => {
                handleCourse_Name(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_Name && touched.Course_Name && (
            <p style={{ color: "red" }}>{errors.Course_Name}</p>
          )}
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Course Duration (in Hours) </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter course duration here"
              name="Course_Duration"
              value={Course_Duration}
              onChange={(e) => {
                handleCourse_Duration(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_Duration && touched.Course_Duration && (
            <p style={{ color: "red" }}>{errors.Course_Duration}</p>
          )}
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">URL Slug </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter url slug tittle"
              name="Course_URL_Slug"
              value={Course_URL_Slug}
              onChange={(e) => {
                handleCourse_URL_Slug(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_URL_Slug && touched.Course_URL_Slug && (
            <p style={{ color: "red" }}>{errors.Course_URL_Slug}</p>
          )}
        </div>
      </div>
      <div className="draft-input2">
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Short Description </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter short description here"
              name="Course_Short_Description"
              value={Course_Short_Description}
              onChange={(e) => {
                handleCourse_Short_Description(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_Short_Description &&
            touched.Course_Short_Description && (
              <p style={{ color: "red" }}>{errors.Course_Short_Description}</p>
            )}
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Image </label>
            <input type="file" class="form-control" placeholder="" onChange={(e) => {
              handleimg(e);
            }} />
            {file && (
              <img src={file} style={{ "height": 100, "width": 100 }} />
            )}
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Course MRP</label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter course MRP here"
              name="Course_Fees"
              value={Course_Fees}
              onChange={(e) => {
                handleCourse_Fees(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_Fees && touched.Course_Fees && (
            <p style={{ color: "red" }}>{errors.Course_Fees}</p>
          )}
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Discount </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter discount here"
              name="Course_Discount"
              value={Course_Discount}
              onChange={(e) => {
                handleCourse_Discount(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_Discount && touched.Course_Discount && (
            <p style={{ color: "red" }}>{errors.Course_Discount}</p>
          )}
        </div>
      </div>
      <div className="draft-input2">

        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Offered Price </label>
            <input
              type="text"
              readOnly
              class="form-control"
              placeholder="Enter Offered Price here"
              name="Course_Offered_Price"
              value={Course_Offered_Price}
              onChange={(e) => {
                handleCourse_Offered_Price(e);
                formik.handleChange(e);
              }}
            />
          </div>
          {errors.Course_Offered_Price && touched.Course_Offered_Price && (
            <p style={{ color: "red" }}>{errors.Course_Offered_Price}</p>
          )}
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <div className="popular-swicth">
              <lable>Popular Course</lable>
              <div className="toggle-switch">
                <input type="checkbox" className="checkbox1" value="1" onChange={(e) => setis_Popular(e.target.checked ? "1" : "0")} name="checkbox" checked={is_Popular == "1" ? true : ""} id="fgfgfg" />
                <label className="label" htmlFor="fgfgfg">
                  <span className="inner" />
                  <span className="switch" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <div className="popular-swicth">
              <lable>Top Course</lable>
              <div className="toggle-switch">
                <input type="checkbox" className="checkbox1" name="checkbox" id="fgfgfgh" value="1" onChange={(e) => setis_TOP(e.target.checked ? "1" : "0")} checked={is_TOP == "1" ? true : ""} />
                <label className="label" htmlFor="fgfgfgh">
                  <span className="inner" />
                  <span className="switch" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Editor */}
      <div>
        <div class="about-editor">
          <label for="exampleInputEmail1">Course Inclusion</label>
          <Editor
            tools={[
              [Bold, Italic, Underline, Strikethrough],
              [Subscript, Superscript],
              [AlignLeft, AlignCenter, AlignRight, AlignJustify],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              FontSize,
              FontName,
              FormatBlock,
              [Undo, Redo],
              [Link, Unlink, InsertImage, ViewHtml],
              [InsertTable],
              [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
              [DeleteRow, DeleteColumn, DeleteTable],
              [MergeCells, SplitCell],
            ]}
            contentStyle={{
              height: 300,
            }}
            ref={bodynew1}
            value={Course_Inclusion}
            name="Course_Inclusion"
            onChange={(e) => {
              handleCourse_Inclusion(e);
            }}
          />
        </div>
        {errors.Course_Inclusion && touched.Course_Inclusion && (
          <p style={{ color: "red" }}>{errors.Course_Inclusion}</p>
        )}
      </div>
      <div>
        <div className="about-editor">
          <label for="exampleInputEmail1">What You Learn</label>
          <Editor
            tools={[
              [Bold, Italic, Underline, Strikethrough],
              [Subscript, Superscript],
              [AlignLeft, AlignCenter, AlignRight, AlignJustify],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              FontSize,
              FontName,
              FormatBlock,
              [Undo, Redo],
              [Link, Unlink, InsertImage, ViewHtml],
              [InsertTable],
              [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
              [DeleteRow, DeleteColumn, DeleteTable],
              [MergeCells, SplitCell],
            ]}
            contentStyle={{
              height: 300,
            }}
            ref={bodynew2}
            value={learn}
            name="learn"
            onChange={(e) => {
              setlearn(e.target.value);
            }}
          />

          {errors.learn && touched.learn && (
            <p style={{ color: "red" }}>{errors.learn}</p>
          )}
        </div>
      </div>
      <div>
        <div className="about-editor">
          <label for="exampleInputEmail1">Course Detail</label>
          <Editor
            tools={[
              [Bold, Italic, Underline, Strikethrough],
              [Subscript, Superscript],
              [AlignLeft, AlignCenter, AlignRight, AlignJustify],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              FontSize,
              FontName,
              FormatBlock,
              [Undo, Redo],
              [Link, Unlink, InsertImage, ViewHtml],
              [InsertTable],
              [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
              [DeleteRow, DeleteColumn, DeleteTable],
              [MergeCells, SplitCell],
            ]}
            contentStyle={{
              height: 300,
            }}
            ref={bodynew3}
            value={Course_Details_Description}
            name="Course_Details_Description"
            onChange={(e) => {
              handleCourse_Details_Description(e);
            }}
          />
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-md-12 column">
          <table className="table table-hover" id="tab_logic">
            <tbody>
              {inputFields.map((item, idx) => (
                <tr key={idx}>
                  {columnsArray.map((column, index) => (
                    <td key={index}>
                      <label>Features Heading</label>
                      <input
                        type="text"
                        column={column}
                        value={item.title}
                        index={idx}
                        className="form-control"
                        name="heading"
                        onChange={(event) => updateState(idx, event)}
                        placeholder="hello"
                      />
                    </td>
                  ))}
                  {columnsArray.map((column, index) => (
                    <td key={index}>
                      <label>Features Details</label>
                      <input
                        type="text"
                        column={column}
                        value={item.detail}
                        index={idx}
                        name="detail"
                        className="form-control"
                        onChange={(event) => updateState(idx, event)}
                        placeholder="hello"
                      />
                    </td>
                  ))}
                  <td>
                    <div>
                      {(inputFields.length !== 1) && (
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleRemoveSpecificRow(idx)}
                          style={{ marginTop: "35px" }}
                        >
                          Remove
                        </button>
                      )
                      }
                      <button
                        className="btn btn-outline-primary btn-sm"
                        style={{ marginTop: "35px", marginLeft: "10px" }}
                        onClick={(e) => {
                          handleAddRow();
                        }}
                      >
                        Add Row
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="">
        <h1 className="draft-mr2 seo-head">SEO Tags</h1>
        <div className="draft-input1">
          <div className="">
            <div class="form-group">
              <label for="exampleInputEmail1">Meta Title </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Meta Title"
                name="Course_Meta_Title"
                value={values.Course_Meta_Title}
                onChange={(e) => {
                  handleCourse_Meta_Title(e);
                  formik.handleChange(e);
                }}
              />
            </div>
          </div>
          <div className="draft-mr2">
            <div class="form-group">
              <label for="exampleInputEmail1">Meta Descriptions </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Meta Descriptions here"
                name="Course_Meta_Description"
                value={values.Course_Meta_Description}
                onChange={(e) => {
                  handleCourse_Meta_Description(e);
                  formik.handleChange(e);
                }}
              />
            </div>
          </div>
          <div className="">
            <div class="form-group">
              <label for="exampleInputEmail1">Meta Keywords </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Meta Keywords here"
                name="Course_Meta_Keyword"
                value={values.Course_Meta_Keyword}
                onChange={(e) => {
                  handleCourse_Meta_Keyword(e);
                  formik.handleChange(e);
                }}
              />
            </div>
          </div>

          <div className="draft-mr2">
            <div class="form-group">
              <label for="exampleInputEmail1">Canonical URL </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter canonical url here"
                name="Course_Canonical"
                value={values.Course_Canonical}
                onChange={(e) => {
                  handleCourse_Canonical(e);
                  formik.handleChange(e);
                }}
              />
            </div>
          </div>
          <div className="">
            <div class="form-group">
              <label for="exampleInputEmail1">Twitter Cards </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Twitter Cards"
                name="Course_Twitter_Card"
                value={values.Course_Twitter_Card}
                onChange={(e) => {
                  handleCourse_Twitter_Card(e);
                  formik.handleChange(e);
                }}
              />
            </div>
            <div className="">
              <div class="form-group">
                <label for="exampleInputEmail1">OG Tags </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter OG Tags here"
                  name="Course_OG_Tag"
                  value={values.Course_OG_Tag}
                  onChange={(e) => {
                    handleCourse_OG_Tag(e);
                    formik.handleChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="draft-update-btn margin-top-1">
          <button
            type="button"
            id="next"
            onClick={handlesubmit}
          >
            Update
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
