import React, {useState} from 'react';
import "./loginPage.css";
import { Link ,useParams} from 'react-router-dom';
import { Formik } from 'formik';
import { confirmpass } from '../../Validations/confirmpass';
import { devInstance as axios } from "../../Plugin/axios";
export const ChangePassword = () => {
    const [eye, seteye] = useState(true);
    const [password, setpassword] = useState("password");
    const [type, settype] = useState(false);
    const [resp, setresp] = useState('');
    const {token} =useParams();
    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
            settype(true);
        } else {
            setpassword("password");
            seteye(true);
            settype(false);
        }
    }
    return (
        <>
          <Formik
        initialValues={{ ad_password: '',confirmPassword:''}}
        validationSchema={confirmpass}
        onSubmit={(values) => {
            const data= {values,token}
        axios.post('/restpassword',data).then((res)=>{
           setresp(res.data.message)
        })
          
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
            <form onSubmit={handleSubmit}>
            <div className='container-fluid admin-login'>
                <div className='row admin-login'>
                    <div className='col-md-6'>
                        <img src="../login-banner.png" />
                    </div>
                    <div className='col-md-6 admin-inputs'>
                        <div className='admin-content'>
                            <h4>ABACUSLY - LMS</h4>
                            <h6>Change Password</h6>
                          
                            {errors.ad_password && touched.ad_password && <p className='err2' style={{color:'red',marginBottom:'-1.5rem' }}>{errors.ad_password}</p>}
                            <div class="input-icons eye-icon-password">
                           
                                <input class="input-field" type={password} name="ad_password"placeholder='Enter New password'id={errors.ad_password && touched.ad_password? "error":""} onChange={handleChange} onBlur={handleBlur} value={values.ad_password}/>
                                <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                    aria-hidden="true"></i>
                            </div>
                            <div class="input-icons eye-icon-password1">
                               <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                    aria-hidden="true"></i>
                                <input class="input-field" type={password} name="confirmPassword"placeholder='Confirm New password'id={errors. confirmPassword && touched. confirmPassword? "error":""}onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                               
                            </div>
                            {errors.ad_password && touched.ad_password && <p className='err2' style={{color:'red', }}>{errors.ad_password}</p>}
                            <p style={{color:'green', }}>{resp}</p>
                            {errors.confirmPassword && touched.confirmPassword && <p className='err2' style={{color:'red', }}>{errors.confirmPassword}</p>}
                            <div>
                                <button className='sign-in'>Update Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div></form>)}
             </Formik>
        </>
    )
}
