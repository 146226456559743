import React,{useEffect,useState} from 'react';
import BlogImg from "../../../../src/images/blog.png"
import { devInstance as axios } from '../../../Plugin/axios';

export const WedgetModal6 = () => {
    const [data,setData] = useState('');
    const baseurl=axios.defaults.baseURL;
    useEffect(()=>{
        axios.get('/getwidgets7').then((res)=>{
            setData(res.data.data[0])
       
                })
      },[])
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Heading:</h6>
                    <p>{data.heading}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Detail Description : </h6>
                <p dangerouslySetInnerHTML={{__html:data.Detail_Description}}></p>
            </div>
        </div>
    )
}
