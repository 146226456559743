import React,{useState,useEffect} from 'react';
import "./user.css";
import BlogEdit from "../../../src/images/purple-pencil.svg";
import { Link, useParams } from 'react-router-dom';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogAdd from "../../../src/images/blog-add.svg";
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
export const ViewUser = () => {
    const [data,setData]=useState('');
    const [order,setorder]=useState([]);
    const {link} = useParams();
    const baseurl = axios.defaults.baseURL
    const { user } = useSelector((state) => state.user);
    const token =user.token
    useEffect(()=>{
        fetchdata();
    },[])
    const fetchdata = ()=>{
        axios.get("/edituser/"+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            console.log(res.data.data)
            setData(res.data.data);
        });

        axios.get("/getuserorder/"+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            setorder(res.data.data);
        });
    }
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>User Profile</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>User Profile</p>
            </div>
            <div className='user-view-section'>
                <div className='user-view-col'>
                    <div className='profile-info1'>
                        <div>
                        <img src={baseurl+data.img} width={200} height={200} />
                        </div>
                        <div>
                            <p>Students Name: <span>{data.first_name+" "+data.last_name}</span></p>
                            <p>Student Mobile Number: <span>{data.mobile_number}</span></p>
                            <p>WhatsApp Mobile No : <span>{data.whatsapp_numbers}</span></p>
                            <p>Student Email Id: <span>{data.student_email}</span></p>
                            <p>Parents Email Id: <span>{data.parent_email}</span></p>
                            <p>Gender <span>{data.gender}</span></p>
                            <p>DOB: <span>{data.dob ? (data.dob.split("T"))[0] : "NA"}</span></p>
                        </div>
                    </div>
                    <div></div>
                    <div className='profile-info2'>
                        <div>
                            <p>Full Address: <span>{data.address}</span></p>
                            <p>Country : <span>{data.country_id}</span></p>
                            <p>State: <span>{data.state_id}</span></p>
                            <p>City: <span>{data.city_id}</span></p>
                            <p>Registration Date & Time: <span> {data.createdAt ? (data.createdAt.split("T"))[0] : "NA"}</span></p>
                        </div>
                        <div>
                            <img src={BlogEdit} />
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <h3>Order & Analytics</h3>
                    <div className='order-analytics'>
                        <Link to={"/app/view-user/"+link}><button className='account-btn'>Order History</button></Link>
                        <Link to={"/app/user-analytics/"+link}><button >Analytics</button></Link>
                        <Link to={"/app/user-change-password/"+link}><button >Change Password</button></Link>
                    </div>
                </div>
                <div className='order-analytics admin-table table-scroll'>
                    <div className='d-flex justify-content-between'>
                        <h6>Order History</h6>
                    </div>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Order ID</th>
                                <th scope="col">Course Category</th>
                                <th scope="col">Course Name</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Pre-Discount</th>
                                <th scope="col">Coupon Discount</th>
                                <th scope="col">Billed Amount</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order && order.map((item,i)=>(
                                <tr key={i}>
                                <td>{item.createdAt}</td>
                                <td>#{item.order_id}</td>
                                <td>{item.category_name}</td>
                                <td>{item.course_name}</td>
                                <td>${item.mrp}</td>
                                <td>${item.prediscount}</td>
                                <td>${item.coupon_discount_amount}</td>
                                <td>${item.order_amount_with_gst}</td>
                                <td>{item.payment_status}</td>
                                <td>#{item.trns_id}</td>
                                <td className='blog-icons'>
                                    {/* <Link to="#" data-toggle="tooltip" title="Edit Order"> <img src={BlogEdit} /></Link> */}
                                    <Link to={"/app/view-order/"+item._id} data-toggle="tooltip" title="View Order Details"><img src={BlogEye} /></Link>
                                    <Link to="#" data-toggle="tooltip" title="Delete Order"><img src={BlogDelete} /></Link>
                                </td>
                            </tr>
                            ))}
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}
