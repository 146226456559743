import { createSlice } from "@reduxjs/toolkit"
const initialState = {
    user:null,
    loading:false,
    error:false,
  }
const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        loginStart:(state)=>{
            state.loading=true
        },
        loginSuccess:(state,action)=>{
            state.loading=false
            state.user=action.payload;
        },
        loginFail:(state)=>{
            state.loading=false
            state.error=true
        },
        logout:(state)=>{
          state.user=null
        },
    
    }
});

export const {loginStart,loginSuccess,loginFail,logout}=userSlice.actions;

export default userSlice.reducer