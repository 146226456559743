import React, { useState,useEffect } from 'react'

import { Editor, EditorTools,EditorUtils } from '@progress/kendo-react-editor';

import { BlogEditor } from '../BlogPage/MyEditor'
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';
import content from '../BlogPage/Content.js';
import 'react-toastify/dist/ReactToastify.css';;
export const RefundPolicy = () => {
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
      } = EditorTools;
      const { user } = useSelector((state) => state.user);
      const token = user.token
      const body1 = React.createRef();
    const [header_title,setheader_title]=useState('');
    const [body,setbody]=useState('');
    const handlebody = (e) => {
        setbody(e.target.value);
      };
      const handleheader_title = (e) => {
        setheader_title(e.target.value);
      };

      useEffect(()=>{
        getpolicy();
      },[])
      const getpolicy=async()=>{
        await axios.get('/refund').then((res)=>{

            setheader_title(res.data.data[0].header_title);
            setbody(res.data.data[0].body[0]);
        })
      }
      const handleSubmit =async(e)=>{
        const view1 = body1.current.view;
        var body = EditorUtils.getHtml(view1.state);
        const data={header_title,body}
      
          axios.put('/refund',data, {
              headers: {
                  'x-access-token': token,
                  'Accept': 'application/json',
                //   "Content-Type": "multipart/form-data"
                 }}).then((res)=>{
                  if(res.data.code===200){
                      toast.success('Hey Your Refund Policy page details successfully Updated')
                  }else{
                      toast.error('Please try again later')
                  }
          })
       }
    return (
        <div>
           <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Refund and cancellation Policy</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Refund and cancellation Policy</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Refund and cancellation Policy</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Refund and cancellation Policy</p>
                    </div>
                </div>
            </div>
            <div className='about-section'>
                <div className="about-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading / Title</label>
                            <input value={header_title} type="text" class="form-control" placeholder="Enter you heading / title here" onChange={handleheader_title} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='about-editor'>
                    <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
    height: 300
  }}value={body} ref={body1} onChange={handlebody}/>
                    </div>
                    <div className="draft-update-btn">
                        <button onClick={handleSubmit}>Update</button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
