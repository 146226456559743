import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import "./header.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { devInstance as axios } from "../../Plugin/axios";
import { Row, Col, Container, Button } from "react-bootstrap";
import "react-bootstrap-submenu/dist/index.css";
import Accordion from 'react-bootstrap/Accordion';

export const BottomHeader = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div>
                <div className='bottom-header hide-in-desktop'>
                    <ul>
                        <li><img src="../images/home-icon.svg" />Dashboard
                            <ul class="dropdown1">
                                <li><Link to="/app/dashboard"><img src="../icons/lms-dashboard.svg" />LMS Dashboard</Link></li>
                                <li><Link to="/#"><img src="../icons/analatics-dashboard.svg" />Analytics Dashboard</Link></li>
                            </ul>
                        </li>
                        <li><img src="../images/study-icon.svg" />Courses
                            <ul class="dropdown1">
                                <li><Link to="/app/course-category"><img src="../images/user-icon.svg" />Course Category</Link></li>
                                <li><Link to="/app/manage-course"><img src="../images/study-icon.svg" width="7%" />Courses</Link></li>
                                <li><Link to="/app/manage-video"><img src="../icons/video-learning.svg" width="8%" />Video Tutorials</Link></li>
                            </ul>
                        </li>
                        <li><img src="../images/user-icon.svg" />Users & Orders
                            <ul class="dropdown1">
                                <li><Link to="/app/manage-user"><img src="../images/user-icon.svg" />Manage Users</Link></li>
                                <li><Link to="/app/manage-order"><img src="../icons/order.svg" width="7%" />Manage Orders</Link></li>
                                <li><Link to="/app/manage-transaction"><img src="../icons/manage-refund.svg" width="7%" />Manage Transactions</Link></li>
                                {/* <li><Link to="/app/student-review"><img src="../icons/review.svg" width="7%" />Manage Reviews</Link></li> */}
                                {/* <li><Link to="/app/refund-cancel-request"><img src="../icons/manage-refund.svg" width="7%" />Refunds & Cancellations Request</Link></li> */}
                            </ul>
                        </li>
                        <li><img src="../images/building-icon.svg" />Company Profile
                            <ul class="dropdown1">
                                <li><Link to="/app/home-widget"><img src="../images/home-icon.svg" />Home Page Widgets</Link></li>
                                <li><Link to="/app/about"><img src="../icons/about-us.png" width="7%" />About Us</Link></li>
                                <li><Link to="/app/our-team"><img src="../icons/admissionenq.svg" width="7%" />Our Team</Link></li>
                                <li><Link to="/app/manage-slider"><img src="../icons/manage-slider.png" width="7%" />Manage Slider</Link></li>
                                <li><Link to="/app/contact-us"><img src="../icons/support.svg" width="7%" />Contact Us</Link></li>
                                <li><Link to="/app/terms-condition"><img src="../icons/manage-user.svg" width="7%" />Terms & Conditions</Link></li>
                                <li><Link to="/app/privacy-policy"><img src="../icons/policy.svg" width="7%" />Privacy Policy</Link></li>
                                <li><Link to="/app/refund-policy"><img src="../icons/manage-refund.svg" width="7%" />Refunds & Cancellation Policy</Link></li>
                                <li><Link to="/app/cookies-policy"><img src="../icons/cookies.svg" width="7%" />Cookies Policy</Link></li>
                                <li><Link to="/app/#"><img src="../icons/tr.svg" width="7%" />Career</Link></li>
                            </ul>
                        </li>

                        <li><img src="../icons/blog.svg" width="15%" />Blog
                            <ul class="dropdown1">
                                {/* <li><Link to="/app/blog"><img src="../icons/blog.svg" width="7%" />Manage Blog Category</Link></li> */}
                                <li><Link to="/app/blogPage"><img src="../icons/manage-blog.svg" width="7%" />Manage Blogs</Link></li>
                            </ul>
                        </li>
                        <li><img src="../icons/support.svg" width="15%" />Help & Support
                            <ul class="dropdown1">
                                <li><Link to="/app/querysubject-page"><img src="../icons/admissionenq.svg" width="7%" />Manage Query Subjects</Link></li>
                                <li><Link to="/app/callback-page"><img src="../icons/admissionenq.svg" width="7%" />Manage Call Backs</Link></li>
                                <li><Link to="/app/ticket-page"><img src="../icons/admissionenq.svg" width="7%" />Manage Tickets</Link></li>
                            </ul>
                        </li>
                        {/* <li><img src="../images/home-icon.svg" />News Centre</li> */}
                        <li><img src="../icons/enq.svg" width="10%" />Enquiries
                            <ul class="dropdown1">
                                {/* <li><Link to="/app/admission-enquiry"><img src="../icons/admissionenq.svg" width="7%" />Manage Admission Inquiries</Link></li> */}
                                <li><Link to="/app/contactus-enquiry"><img src="../icons/manage-user.svg" width="7%" />Contact Us Inquiries</Link></li>
                                <li><Link to="/app/newsletter-enquiry"><img src="../icons/subscribe.svg" width="7%" />Newsletter Subscriptions</Link></li>
                            </ul>
                        </li>
                        {/* <li><img src="../images/home-icon.svg" />SEO Settings</li> */}
                    </ul>
                </div>

                {/* Mobile Header */}
                {/* <div className='hide-in-mobile'>
                    <div className='d-flex justify-content-between' style={{ padding: "10px" }}>
                        <div>
                            <Button variant="primary" onClick={handleShow} className="me-2">
                                Show
                            </Button>
                            <Offcanvas show={show} onHide={handleClose} {...props}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Dashboard</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/dashboard"><img src="../icons/lms-dashboard.svg" />LMS Dashboard</Link></li>
                                                    <li><Link to="/#"><img src="../icons/analatics-dashboard.svg" />Analytics Dashboard</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Courses</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/course-category"><img src="../images/user-icon.svg" />Course Category</Link></li>
                                                    <li><Link to="/app/manage-course"><img src="../images/study-icon.svg" width="7%" />Courses</Link></li>
                                                    <li><Link to="/app/manage-video"><img src="../icons/video-learning.svg" width="8%" />Video Tutorials</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Users & Orders</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/manage-user"><img src="../images/user-icon.svg" />Manage Users</Link></li>
                                                    <li><Link to="/app/manage-order"><img src="../icons/order.svg" width="7%" />Manage Orders</Link></li>
                                                    <li><Link to="/app/manage-transaction"><img src="../icons/manage-refund.svg" width="7%" />Manage Transactions</Link></li>
                                                    <li><Link to="/app/student-review"><img src="../icons/review.svg" width="7%" />Manage Reviews</Link></li>
                                                    <li><Link to="/app/refund-cancel-request"><img src="../icons/manage-refund.svg" width="7%" />Refunds & Cancellations Request</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Company Profile</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/course-category"><img src="../images/user-icon.svg" />Course Category</Link></li>
                                                    <li><Link to="/app/manage-course"><img src="../images/study-icon.svg" width="7%" />Courses</Link></li>
                                                    <li><Link to="/app/manage-video"><img src="../icons/video-learning.svg" width="8%" />Video Tutorials</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div> */}
                        {/* <div className='header-img'>
                            <img src={`${baseurl + logo}`} />
                        </div> */}
                    {/* </div> */}
                {/* </div> */}
            </div>
        </>
    )
}
