import React,{useState,useEffect} from 'react'
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
export const Invoice = () => {
    const [data,setData]=useState('');
    const [comp,setcomp]=useState('');
    const [user1,setuser]=useState('');
    const { user } = useSelector((state) => state.user);
    const token = user.token
    const {link} = useParams();
    useEffect(()=>{
        fetchdata();
    },[])
    const fetchdata = ()=>{
        axios.get('/vieworder/'+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            setData(res.data.data);
            setuser(res.data.user);
        });
        axios.get('/get_AdminGstUT', {
            headers: {
              'x-access-token': token,
              'Accept': 'application/json',
      
            }
          }).then((res) => {
            setcomp(res.data.Data[0])
          });
    }
    return (
        <div className='invoice-bg'>
            <div className='invoice-details'>
                <div>
                    <img src="./images/logo.png" />
                    <h3 className='invoice-mr'>INVOICE TO</h3>
                    <p>Student Name: <span>{data.name}</span></p>
                    <p>Address: <span>{user1.address}</span></p>
                    <p>Phone Number: <span>{data.mobile_number}</span></p>
                    <p>Email ID: <span>{data.email}</span></p>
                </div>
                <div>
                    <div>
                        <h3>INVOICE</h3>
                        <p>Company Name: <span>{comp.ads_companyname}</span></p>
                        <p>Company Address: <span>{comp.ads_address}</span></p>
                        <p>Tax Number: <span>{comp.ads_gstnumber}</span></p>
                    </div>
                    <div className='invoice-mr'>
                        <h3>Payment Details</h3>
                        <p>Invoice Number: <span>#{data.invoice_id}</span></p>
                        <p>Order Date: <span>{data.createdAt}</span></p>
                        <p>Payment Status: <span>{data.payment_status}</span></p>
                    </div>
                </div>
            </div>

            {/* invoice table */}

            <div className='invoice-table-mt'>
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Order Detail</th>
                            <th scope="col">Cost </th>
                            <th scope="col">Pre Discount</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>{data.course_name}</td>
                            <td>${data.mrp}</td>
                            <td>${data.prediscount}</td>
                            <td>1</td>
                            <td>${data.subtotal}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='bill-amount'>
                <ul>
                    <li>Sub Total <span>${data.subtotal}</span></li>
                    <li>Pre Discount <span className='color-green'>${data.prediscount}</span></li>
                    <li>Coupon Discount <span className='color-green'>${data.coupon_discount_amount}</span></li>
                    <li>GST <span className='color-red'>${data.gst_amount}</span></li>
                    <li>Total Biled Amount<span>${data.order_amount_with_gst}</span></li>
                </ul>
            </div>
        </div>
    )
}
