import * as Yup from 'yup';
import "./accountPage.css";
import { Link, useNavigate } from "react-router-dom";
import { React, useState } from "react";
import { useSelector } from "react-redux";
import { devInstance as axios } from "../../Plugin/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { Formik } from 'formik';
import { logout } from '../../redux/UserSlice';
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
export const AccountPage = () => {
  const { user } = useSelector((state) => state.user);
  const [newCPwd, setNewcppwd] = useState("");
  const [pwd, setPwd] = useState([]);
  const [newPwd, setNewpwd] = useState("");
  const [login, setlogin] = useState([]);
  const [field, setField] = useState([]);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);
  const [ip, setIp] = useState();
  const [city, setCity] = useState();
  const [resp, setresp] = useState('')
  const [nwePwd, setnwePwd] = useState('');
  const navigate = useNavigate();
  const [errors, seterr] = useState('')
  const dispatch = useDispatch();
  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }



  const confirmpass = Yup.object().shape({
    newPwd: Yup.string()
      .min(4, 'Password Too Short!')
      .max(50, 'Password Too Long!')

      .required('password is required, please enter password'),
    newCPwd: Yup.string().oneOf([Yup.ref('newPwd'), null], "password must be match")
  });


  const token = user.token;

  useEffect(() => {
    axios
      .get("/getpwdhist", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setPwd(res.data.Data);
      });

  }, []);

  useEffect(() => {
    axios
      .get("/getloginfailed", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setField(res.data.Data);
      });
  }, []);

  useEffect(() => {
    getData();
    axios
      .get("/getloginSuccess", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setlogin(res.data.Data);

      });


  }, []);
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIp(res.data.IPv4)
    setCity(res.data.city)
  }
  const handlesubmitchangepassword = () => {
    axios.put('/update_password', { newPwd, newCPwd, ip, city, "BrowserName": browserName }, {
      headers: {
        'x-access-token': token,
      }
    }).then((res) => {
      if (res.data.code == 400) {
        seterr(res.data.message)
      } else {
        Swal.fire({
          icon: "success",
          title: 'Password change succesfully!',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'ok',
          denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            dispatch(logout());
            navigate("/");
          } else if (result.isDenied) {

          }
        })


      }

    }).catch((response) => {
      seterr(response.data.message)
    })
  }
  return (
    <div>
      <div className='hide-in-desktop'>
        <div className='d-flex profile-section'>
          <p className='profile-head'>Account Settings</p>
          <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
          <p className='profile-pera'>Account Settings</p>
        </div>
      </div>
      <div className='hide-in-mobile'>
        <div className='profile-section'>
          <p className='profile-head'>Account Settings</p>
          <div className='d-flex'>
            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
            <p className='profile-pera'>Account Settings</p>
          </div>
        </div>
      </div>
      {/* <div className='d-flex profile-section'>
        <p className='profile-head'>Account Settings</p>
        <p className='profile-home'><img src="../../images/purple-home.svg" />Page</p>
        <p className='profile-pera'>Account Settings</p>
      </div> */}
      <div className='pages-tabs'>
        <Link to="/app/account"><button className='account-btn'>Account Setting</button></Link>
        <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
        <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
        <Link to="/app/email-api"><button>Email</button></Link>
        <Link to="/app/sms-api"><button>SMS</button></Link>
        <Link to="/app/socialmedia"><button>Social Media</button></Link>
        <Link to="/app/headerandfooter"><button>Header & Footer</button></Link>
        <Link to="/app/storage-api"><button>Storage</button></Link>
        <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
        <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
        <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>
      </div>
      <div className='pages-tabs'>
        <div className='row'>

          <p style={{ color: 'green', }}>{resp}</p>

          <div >
            <p style={{ color: "red" }}>{errors}</p>
          </div>

          <div className='col-md-6 change-password-eye'>
            <div class="form-group">
              <label for="exampleInputEmail1">New Password</label> <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                aria-hidden="true"></i>
              <input type={password} class="form-control" name="newPwd" placeholder="Enter new password" onChange={(e) => setNewpwd(e.target.value)} value={newPwd} />

            </div>
          </div>
          <div className='col-md-6 change-password-eye'>
            <div class="form-group">
              <label for="exampleInputEmail1">Confirm New Password</label> <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                aria-hidden="true"></i>
              <input type={password} class="form-control" name="newCPwd" placeholder="Confirm your password" onChange={(e) => setNewcppwd(e.target.value)} value={newCPwd} />
            </div>

          </div>
        </div>
        <div className="update-btn" >
          <button onClick={handlesubmitchangepassword}>Update</button>
        </div>
      </div>
      <div className="pages-tabs admin-table">
        <h6>Password History</h6>
        <div className='mobile-table-scroll'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date & Time</th>
                <th scope="col">Browser Name</th>
                <th scope="col">Device</th>
                <th scope="col">IP Address</th>
                <th scope="col">Location</th>
                <th scope="col">Password Mode</th>
              </tr>
            </thead>
            <tbody>
              {pwd && pwd.map((item, i) => (
                <tr key={i}>
                  <td>{item.createdAt}</td>
                  <td>{item.BrowserName}</td>
                  <td>{item.Device}</td>
                  <td>{item.IpAddress}</td>
                  <td>{item.Location}</td>
                  <td>{item.PwdMode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Succesful Login table */}

      <div className="pages-tabs admin-table">
        <h6>Successful Login</h6>
        <div className='mobile-table-scroll'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date & Time</th>
                <th scope="col">Browser Name</th>
                <th scope="col">Device</th>
                <th scope="col">IP Address</th>
                <th scope="col">Location</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>

              {login && login.map((item, i) => (
                <tr key={i}>
                  <td>{item.createdAt}</td>
                  <td>{item.BrowserName}</td>
                  <td>{item.Device}</td>
                  <td>{item.IpAddress}</td>
                  <td>{item.Location}</td>
                  <td>{item.loginStatus == true ? "Login Succesfull" : "Wrong Detail"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Failed Login Table  */}

      <div className="pages-tabs admin-table">
        <h6>Failed Login</h6>
        <div className='mobile-table-scroll'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date & Time</th>
                <th scope="col">Browser Name</th>
                <th scope="col">Device</th>
                <th scope="col">IP Address</th>
                <th scope="col">Location</th>
                <th scope="col">Status</th>
                <th scope="col">Failure Reason</th>
              </tr>
            </thead>
            <tbody>
              {field && field.map((item, i) => (
                <tr key={i}>
                  <td>{item.createdAt}</td>
                  <td>{item.BrowserName}</td>
                  <td>{item.Device}</td>
                  <td>{item.IpAddress}</td>
                  <td>{item.Location}</td>
                  <td>Login Failed</td>
                  <td>{item.FailerReason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </div >
  )
}
