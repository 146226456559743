import { createSlice } from "@reduxjs/toolkit"
const initialState = {
   profile:null,
    loading:false,
    error:false,
  }
const profileSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
       profileStart:(state)=>{
            state.loading=true
        },
     profileSuccess:(state,action)=>{
            state.loading=false
            state.user=action.payload;
        },
      profileFail:(state)=>{
            state.loading=false
            state.error=true
        },
       
    
    }
});

export const {profileStart,profileSuccess,profileFai}=profileSlice.actions;

export default profileSlice.reducer