import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./headerFooter.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { devInstance as axios } from "../../Plugin/axios";
import { useEffect } from 'react';
import { useSelector } from "react-redux";
export const HeaderAndFooter = () => {
    const [header_title, setaheader_title] = useState('');
    const [logo2, setlogo2] = useState('');
    const [fevi, setfevi] = useState('');
    const [logo, setlogo] = useState('');
    const [logoweb2, setlogoweb2] = useState('');
    const [feviweb, setfeviweb] = useState('');
    const [logoweb, setlogoweb] = useState('');
    const [toll_no, settoll_no] = useState('');
    const [address, setaddress] = useState('');
    const [whatsapp, setwhatsapp] = useState('');
    const [emailId, setemailId] = useState('');
    const [data, setdata] = useState('');
    const [adminlogo, setadminlogo] = useState('');
    const [adminlogo2, setadminlogo2] = useState('');
    const [adminfav, setadminfav] = useState('');
    const [weblogo, setweblogo] = useState('');
    const [weblogo2, setweblogo2] = useState('');
    const [webfav, setwebfav] = useState('');

    const [des, setdes] = useState('');
    const { user } = useSelector((state) => state.user);
    const token = user.token
    const handletoll_no = (e) => {
        settoll_no(e.target.value);
    };
    const handleemailId = (e) => {
        setemailId(e.target.value);
    };
    const handledes = (e) => {
        setdes(e.target.value);
    };
    const handlewhatsapp = (e) => {
        setwhatsapp(e.target.value);
    };
    const handleaddress = (e) => {
        setaddress(e.target.value);
    };
    const handleaheader_title = (e) => {
        setaheader_title(e.target.value);
    };
    const handlefevi = (e) => {
        setfevi(e.target.files[0]);
        setadminfav(URL.createObjectURL(e.target.files[0]));
    };
    const handlelogo2 = (e) => {
        setlogo2(e.target.files[0]);
        setadminlogo2(URL.createObjectURL(e.target.files[0]));
    };
    const handlelogo = (e) => {
        setlogo(e.target.files[0]);
        setadminlogo(URL.createObjectURL(e.target.files[0]));
    };
    const handlefevweb = (e) => {
        setfeviweb(e.target.files[0]);
        setwebfav(URL.createObjectURL(e.target.files[0]));
    };
    const handlelogoweb2 = (e) => {
        setlogoweb2(e.target.files[0]);
        setweblogo2(URL.createObjectURL(e.target.files[0]));
    };
    const handlelogoweb = (e) => {
        setlogoweb(e.target.files[0]);
        setweblogo(URL.createObjectURL(e.target.files[0]));
    };
    const baseurl = axios.defaults.baseURL;
    const handelsubmit2 = async (e) => {
        e.preventDefault();
        const data = { 'logo': logoweb, 'img': logoweb2, 'fevi': feviweb, toll_no, des, address, whatsapp, emailId }
        axios.put('/updatewebiste', data, {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }

        }).then((res) => {
            if (res.data.status === 400) {

                toast.error('error')
            } else {
                toast.success('Header setting successfully updated')
            }

        })
    }

    const handelsubmit = async (e) => {
        e.preventDefault();
        const data = { fevi, header_title, logo2, logo }
        axios.put('/updateheader', data, {
            headers: {

                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }

        }).then((res) => {
            if (res.data.code === 400) {

                toast.error('error')
            } else {
                toast.success('Header setting successfully updated')
            }
            setTimeout(function () {
                // window.location.replace('/app/headerandfooter');
            }, 2000);

        })
    }

    useEffect(() => {

        axios.get('/get').then((res) => {
            setaheader_title(res.data.data.header_title);
            setadminlogo(baseurl + res.data.data.logo)
            setadminlogo2(baseurl + res.data.data.logo2)
            setadminfav(baseurl + res.data.data.fevi)
        })
        axios.get('/getwebsitedetails').then((res) => {
            settoll_no(res.data.data.toll_no);
            setwhatsapp(res.data.data.whatsapp);
            setaddress(res.data.data.address);
            setemailId(res.data.data.emailId);
            setdes(res.data.data.des);
            setweblogo2(baseurl + res.data.data.img)
            setweblogo(baseurl + res.data.data.logo)
            setwebfav(baseurl + res.data.data.fevi)
        })
    }, [])

    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Header and Footer Settings</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Header and Footer Settings</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Header and Footer Settings</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Header and Footer Settings</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button >Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
                <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
                <Link to="/app/email-api"><button>Email</button></Link>
                <Link to="/app/sms-api"><button>SMS</button></Link>
                <Link to="/app/socialmedia"><button>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button className='account-btn'>Header and Footer</button></Link>
                <Link to="/app/storage-api"><button >Storage</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
                <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
                <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

            </div>
            <div className='manage-header-footer' >
                <h6>Admin Header and Footer</h6>
                <hr />
                <div className="header-footer-inputs">
                    <div className="draft-mr">
                        <p>Login Page</p>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={header_title} onChange={handleaheader_title} />
                        </div>
                    </div>
                    <div>
                        <p className='margin-top-3'>Dashboard Page</p>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Select Favicons</label>
                            <input type="file" class="form-control" placeholder="Enter your short description here" onChange={(e) => {
                                handlefevi(e);
                            }} />
                            <img src={adminfav} style={{ "height": 100, "width": 100, marginTop: "10px" }} />

                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Select Logo <span className='text-danger'>Height(40px)</span></label>
                            <input type="file" class="form-control" placeholder="Enter slug here" onChange={(e) => {
                                handlelogo(e);
                            }} />
                            <img src={adminlogo} style={{ "height": 100, "width": 100, marginTop: "10px" }} />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Select Logo <span className='text-danger'>Height(40px)</span></label>
                            <input type="file" class="form-control" placeholder="Enter canonical url here" onChange={(e) => {
                                handlelogo2(e);
                            }} />
                            <img src={adminlogo2} style={{ "height": 100, "width": 100, marginTop: "10px" }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-btn margin-top-3">
                        <button onClick={handelsubmit}>Update</button>
                    </div>
                </div>
            </div>

            <div className='manage-header-footer' style={{ marginBottom: "4rem" }}>
                <h6>Website Header and Footer</h6>
                <hr />
                <p>Header</p>
                <div className="header-footer-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Select Favicon</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e) => {
                                handlefevweb(e);
                            }} />
                            <img src={webfav} style={{ "height": 100, "width": 100, marginTop: "10px" }} />
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Select Logo</label>
                            <input type="file" class="form-control" placeholder="Enter your short description here" onChange={(e) => {
                                handlelogoweb(e);
                            }} />
                            <img src={weblogo} style={{ "height": 100, "width": 100, marginTop: "10px" }} />
                        </div>
                    </div>
                </div>
                <hr />
                <p>Footer</p>
                <div className="header-footer-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Select Logo</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e) => {
                                handlelogoweb2(e);
                            }} />
                            <img src={weblogo2} style={{ "height": 100, "width": 100, marginTop: "10px" }} />
                        </div>
                    </div>
                    <div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">Toll Free Number</label>
                            <input type="text" class="form-control" placeholder="Enter Toll Free Number here" value={toll_no} onChange={handletoll_no} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Whatsapp Number</label>
                            <input type="text" class="form-control" placeholder="Enter Whatsapp Number here" value={whatsapp} onChange={handlewhatsapp} />
                        </div>
                    </div>
                    <div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">Email Id</label>
                            <input type="email" class="form-control" placeholder="Enter Email Id here" value={emailId} onChange={handleemailId} />
                        </div>
                    </div>
                    <div className='draft-mr'>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Address</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={address} onChange={handleaddress}></textarea>
                        </div>
                    </div>
                    <div>
                        <div class="form-group margin-top-3">
                            <label for="exampleFormControlTextarea1">Short Description</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={des} onChange={handledes}></textarea>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-btn margin-top-3">
                        <button onClick={handelsubmit2}>Update</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
