import React,{useState,useEffect} from 'react'
import { BlogEditor } from '../../../BlogPage/MyEditor';
import { devInstance as axios } from "../../../../Plugin/axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';

export const EditWidgets10 = () => {
    const { user } = useSelector((state) => state.user); 
    const token =user.token
    const [input,setInput]=useState({

        heading:"",
        Description:"",
        feature1heading:"",
        feature1content:"",
        feature1buttonurl:"",
        feature2heading:"",
        feature2content:"",
        feature2buttonurl:"",
        feature3heading:"",
        feature3content:"",
        feature3buttonurl:"",
        feature4heading:"",
        feature4content:"",
        feature4buttonurl:"",
    })
    const HandelSubmit =async(e)=>{
        axios.put('/widget10',input, {
            headers: {
                'x-access-token': token,
                'Accept' : 'application/json',
                // "Content-Type": "multipart/form-data"
                }
            }).then((res)=>{
          if(res.data.code ===200){
            toast.success('update widget10 Successfully done')
            // navigate('/app/our-team')

          }else{
            toast.error('Please try again later')
          }
        })
    }
    useEffect(()=>{
        viewe();
    },[])
    const viewe = () => {
       
        axios.get("/getwidgets10").then(res => {
            setInput(res.data.data[0]);
           
           
        });
    };
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Widgets</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Widgets</p>
            </div>
            <div className='home-widgets'>
                <h6>Widgets 8</h6>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={input.heading} onChange={(e)=>setInput({heading:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Description</label>
                            <input type="text" class="form-control" placeholder="Enter discription here" value={input.Description} onChange={(e)=>setInput({Description:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Features 1 Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={input.feature1heading} onChange={(e)=>setInput({feature1heading:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Content</label>
                            <input type="text" class="form-control" placeholder="Enter Content here" value={input.feature1content} onChange={(e)=>setInput({feature1content:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Link</label>
                            <input type="text" class="form-control" placeholder="Enter button link here" value={input.feature1buttonurl} onChange={(e)=>setInput({feature1buttonurl:e.target.value})} />
                        </div>
                    </div>
                </div>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Features 2 Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={input.feature2heading} onChange={(e)=>setInput({feature2heading:e.target.value})} />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Content</label>
                            <input type="text" class="form-control" placeholder="Enter Content here" value={input.feature2content} onChange={(e)=>setInput({feature2content:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Link</label>
                            <input type="text" class="form-control" placeholder="Enter button link here" value={input.feature2buttonurl} onChange={(e)=>setInput({feature2buttonurl:e.target.value})} />
                        </div>
                    </div>
                </div>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Features 3 Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={input.feature3heading} onChange={(e)=>setInput({feature3heading:e.target.value})} />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Content</label>
                            <input type="text" class="form-control" placeholder="Enter Content here" value={input.feature3content} onChange={(e)=>setInput({feature3content:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Link</label>
                            <input type="text" class="form-control" placeholder="Enter button link here" value={input.feature3buttonurl} onChange={(e)=>setInput({feature3buttonurl:e.target.value})} />
                        </div>
                    </div>
                </div>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Features 4 Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={input.feature4heading} onChange={(e)=>setInput({feature4heading:e.target.value})} />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Content</label>
                            <input type="text" class="form-control" placeholder="Enter Content here" value={input.feature4content} onChange={(e)=>setInput({feature4content:e.target.value})} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Link</label>
                            <input type="text" class="form-control" placeholder="Enter button link here" value={input.feature4buttonurl} onChange={(e)=>setInput({feature4buttonurl:e.target.value})} />
                        </div>
                    </div>
                </div>
                <div className="home-update-btn widgets-mr margin-top-mobile1">
                    <button onClick={HandelSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}