import React from 'react';
import './Loader.css';

export default function Loader() {
    return (
        <div className='loader-container'>
            <div align="center" class="fond">
                <div class="contener_general">
                    <div class="contener_mixte"><div class="ballcolor ball_1">&nbsp;</div></div>
                    <div class="contener_mixte"><div class="ballcolor ball_2">&nbsp;</div></div>
                    <div class="contener_mixte"><div class="ballcolor ball_3">&nbsp;</div></div>
                    <div class="contener_mixte"><div class="ballcolor ball_4">&nbsp;</div></div>
                </div>
            </div>
        </div>

    )
}