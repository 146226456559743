import React, { useState } from 'react'
import { BlogEditor } from '../../../BlogPage/MyEditor';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { devInstance as axios } from "../../../../Plugin/axios";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
export const EditWidgets12 = () => {
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
    } = EditorTools;
    const { user } = useSelector((state) => state.user);
    const [heading, setheading] = useState('')
    const [Detail_Description, setDetail_Description] = useState('');
    const body1 = React.createRef();
    const token = user.token
    const handleheading = async (e) => {
        setheading(e.target.value);
    }
    const handleDetail_Description = async (e) => {
        setDetail_Description(e.target.value);
    }



    const handleSubmit = async (e) => {
        e.preventDefault(e)
        const view1 = body1.current.view;
        var Detail_Description = EditorUtils.getHtml(view1.state);
        const data = { Detail_Description, heading }
        axios.put('/widget12', data, {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                // "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            if (res.data.code === 200) {
                toast.success('Widget12 Successfully updated')
            }
        }).catch((error) => {
            toast.error('Please try again later')
        })
    }
    useEffect(() => {
        axios.get('/getwidgets12', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setDetail_Description(res.data.data[0].Detail_Description)
            setheading(res.data.data[0].heading)


        })
    }, [])
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Widgets</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Widgets</p>
            </div>
            <div className='home-widgets'>
                <h6>Widgets 10</h6>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={heading} onChange={handleheading} />
                        </div>
                    </div>
                </div>
                <div className='margin-top-mobile1'>
                    <p>Detail Description</p>
                    <div className='widgets-editor'>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                            height: 300
                        }} value={Detail_Description} ref={body1} onChange={handleDetail_Description} />;
                    </div>
                </div>
                <div className="home-update-btn widgets-mr margin-top-mobile1">
                    <button onClick={handleSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}