import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { devInstance as axios } from "../../Plugin/axios";
import { Country, State, City } from "country-state-city";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const EditUser = () => {
  const {link} = useParams();
  const { user } = useSelector((state) => state.user);
  const token =user.token
  const [mobileOtp, setMobileOtp] = "";
  const [emailOtp, setEmailOtp] = "";
  const [first_name, setfirst_name] = useState("");
  const [user_email, setuser_email] = useState("");
  const [last_name, setlast_name] = useState("");
  const [course_category_id, setcourse_category_id] = useState("");
  const [dob, setdob] = useState("");
  const [mobile_number, setmobile_number] = useState("");
  const [whatsapp_number, setwhatsapp_number] = useState("");
  const [gender, setgender] = useState("");
  const [country_id, setcountry_id] = useState("");
  const [city_id, setcity_id] = useState("");
  const [state_id, setstate_id] = useState("");
  const [parent_email, setparent_email] = useState("");
  const [student_email, setstudent_email] = useState("");
  const [password, setpassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const [img, setimg] = useState("");
  const [address, setaddress] = useState("");
  const [file1, setfile1] = useState("");
  const [cat, setcat] = useState([]);
  const [countryName, setCountryname] = useState([]);
  const [statename, setStateName] = useState([]);
  const [cityname, setcityName] = useState([]);
  const navigate = useNavigate();

  const registertoken = localStorage.getItem("token");
  const state = useLocation();
  console.log("State", state.state);

  useEffect(() => {
    fetchdata();
    setCountryname(Country.getAllCountries());
  }, []);
  const fetchdata = ()=>{
    axios.get("/edituser/"+link,{
        headers: {
            'x-access-token': token
        }})
    .then((res) => {
      setfirst_name(res.data.data.first_name)
      setlast_name(res.data.data.last_name)
    });
  }
  useEffect(() => {
    setStateName(State.getStatesOfCountry(country_id));
  }, [country_id]);

  useEffect(() => {
    setcityName(City.getCitiesOfState(country_id, state_id));
  }, [country_id, state_id]);

  useEffect(() => {
    axios.get("/api/category/get_Category").then((res) => {
      setcat(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    getMachineId();
  }, []);

  let machineId = localStorage.getItem("MachineId");
  function getMachineId() {
    let machineId = localStorage.getItem("MachineId");
    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem("MachineId", machineId);
    }
  }

  const handleImage = (e) => {
    setimg(e.target.files[0]);
    setfile1(URL.createObjectURL(e.target.files[0]));
  };

  const [resErr, setResErr] = useState([]);
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("device_id", machineId);
    formData.append("user_email", user_email);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("parent_email", parent_email);
    formData.append("mobile_number", mobile_number);
    formData.append("whatsapp_number", whatsapp_number);
    formData.append("country_id", country_id);
    formData.append("state_id", state_id);
    formData.append("city_id", city_id);
    formData.append("student_email", student_email);
    formData.append("course_category_id", course_category_id);
    formData.append("dob", dob);
    formData.append("gender", gender);
    formData.append("password", password);
    formData.append("password_confirmation", password_confirmation);
    formData.append("img", img);
    formData.append("address", address);
    await axios
      .post("/api/user/usersignup", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status == "sucess") {
         
        }
        if (res.status_code === 200 && res.status === true) {
          console.log("api success", res.message);
        }
        
      })
      .catch(({ response }) => {
        console.log(response.data.status);
        if (response.data.status == "error") {
          console.log("stats", response.data.errors);
          setResErr(response.data.errors);
        }
        
      });
  };

  const handlesameno = (e) => {
    if (e.target.checked) {
      setwhatsapp_number(mobile_number);
    } else {
      setwhatsapp_number("");
    }
  };
  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <div className="d-flex profile-section">
        <p className="profile-head">Edit User</p>
        <p className="profile-home">
          <img src="./images/purple-home.svg" />
          Page
        </p>
        <p className="profile-pera">Edit User</p>
      </div>
      <div className="draft-input2">
        <div className="draft-mr">
          <div className="form-group">
            <label for="exampleInputEmail1">First Name </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter First Name here"
              name="first_name"
              value={first_name}
              onChange={(e) => setfirst_name(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Last Name </label>
            <input
              type="text"
              className="form-control"
              value={last_name}
              placeholder="Enter Last Name"
              name="last_name"
              onChange={(e) => setlast_name(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Course Category & Course </label>
            <select className="form-control" value={course_category_id} onChange={(e) => setcourse_category_id(e.target.value)}>
                  <option value=""> Select Category</option>
                  {cat?.map((item, i) => (
                    <option key={i} value={item.Cat_Category_Name}>
                      {item?.Cat_Category_Name}
                    </option>
                  ))}
                </select>
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Date of Birth </label>
            <input
              type="date"
              className="form-control"
              value={dob}
              placeholder="Enter Date of Birth"
              name="dob"
              onChange={(e) => setdob(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Mobile No. </label>
            <input
              type="number"
              className="form-control"
              value={mobile_number}
              placeholder="Enter Mobile No."
              name="mobile_number"
              onChange={(e) => setmobile_number(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Whatsapp No. </label>
            <input
                type="checkbox"
                className="WhatsApp-Checkbox"
                onChange={handlesameno}
              />
            <input
              type="number"
              className="form-control"
              value={whatsapp_number}
              placeholder="Enter Whatsapp No."
              name="whatsapp_number"
              onChange={(e) => setwhatsapp_number(e.target.value)}
            />
          </div>
        </div>
        
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Gender </label>
            <input
                  type="radio"
                  value="male"
                  name="gender"
                  className="Male-radio"
                  id="Male"
                  checked={gender=="male" ? true : false}
                  onChange={(e) => setgender(e.target.value)}
                />
            <label htmlFor="Male" className="Male-Label">
                  Male
                </label>
                <input
                  type="radio"
                  value="female"
                  name="gender"
                  className="Female-radio"
                  id="Female"
                  onChange={(e) => setgender(e.target.value)}
                />
                <label htmlFor="Female" className="Female-label">
                  Female
                </label>
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Country</label>
            <select className="form-control" name="country_id"
                  onChange={(e) => setcountry_id(e.target.value)}>
                  <option value=""> Select Country</option>
                  {countryName.map((item, i) => (
                    <option key={i} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">State</label>
            <select className="form-control" name="state_id"
                  onChange={(e) => setstate_id(e.target.value)}>
                  <option value=""> Select State</option>
                  {statename.map((index, i) => (
                    <option key={i} value={index.isoCode}>
                      {index.name}
                    </option>
                  ))}
                </select>
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">City</label>
            <select className="form-control" name="city_id"
                  onChange={(e) => setcity_id(e.target.value)}>
                  <option value=""> Select City</option>
                  {cityname.map((item, i) => (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Parent's Email </label>
            <input
              type="email"
              className="form-control"
              value={mobile_number}
              placeholder="Enter Parent's Email"
              name="parent_email"
              onChange={(e) => setparent_email(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">User Email </label>
            <input
              type="email"
              className="form-control"
              value={mobile_number}
              placeholder="Enter User Email"
              name="user_email"
              onChange={(e) => setuser_email(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Student Email </label>
            <input
              type="email"
              className="form-control"
              value={mobile_number}
              placeholder="Enter Student Email"
              name="student_email"
              onChange={(e) => setstudent_email(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Address</label>
            <input
              type="email"
              className="form-control"
              value={address}
              placeholder="Enter Address"
              name="address"
              onChange={(e) => setaddress(e.target.value)}
            />
          </div>
        </div>
        <div className="draft-mr">
          <div class="form-group">
            <label for="exampleInputEmail1">Picture </label>
            <input
              type="file"
              className="form-control"
              name="student_email"
              onChange={handleImage}
            />
            {file1 && <img src={file1} height={100} width={100} />}
          </div>
        </div>
      </div>
      <div className="draft-update-btn mt-3">
        <button
          type="button"
          id="next"
          // onClick={handlesubmit}
        >
          Update
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditUser;
