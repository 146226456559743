import React, { useState,useEffect } from 'react';
import "./video.css"
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { NavItem } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { height } from '@mui/system';
import BlogDownload from "../../../src/images/download-icon.svg";
export const VideoModal = (props) => {
    const [data,setData]=useState("")
    const { user } = useSelector((state) => state.user);
    const token =user.token;
    const baseurl = axios.defaults.baseURL;
    useEffect(()=>{
        axios.get('/video/'+props.data,{
            headers: {
                'x-access-token': token,
                'Accept' : 'application/json',}}).then((res)=>{
                    setData(res.data.data)
                })
               
    },[])
    return (
        <div>
            <div className='video-modal'>
                <p>Category:<span>{data.Category}</span></p>
                <p>Courses:<span>{data.Course}</span></p>
                <p>Video Title:<span>{data.Video_title}</span></p>
            </div>
            <div className='video-modal1'>
                <p>Video Duration:<span>{data.Video_Duration}</span></p>
                <p className='video-mr'>Short Description: <span>{data.Short_description}</span></p>
                <p>URL Slug:<span>{data.URL_Slug}</span></p>
            </div>
            <div className=''>
                <div>
                    <p>Assignment File:<span>  <iframe style={{width:"100%",height:300}} src={baseurl+data.Assisment}></iframe></span></p>
                </div>
                <div>
                    <p>Video Image:</p>
                    <img src={baseurl+data.img} style={{width:100,height:100}} />
                </div>
                
            </div>
            <div className='video-description'>
                <h6>Details Description: </h6>
                <p dangerouslySetInnerHTML={{__html:data.body}}></p>
            </div>
            <div className="row">
            <div className='col-md-6'>
                    <p>Video 1020px:</p>
                    {data.webViewLink1080 ? (
                    <ReactPlayer playing={true} url={data.webViewLink1080.replace("view","preview")} width="400" height="200" allow="autoplay"></ReactPlayer>

                    ):"NA"}
                                <a href={data.webContentLink1080} download data-toggle="tooltip" title="Download Video"><img src={BlogDownload} /></a>

                </div>
                <div className='col-md-6'>
                    <p>Video 720px:</p>
                    {data.webViewLink720 ? (
                    <iframe src={data.webViewLink720.replace("view","preview")} width="400" height="200" allow="autoplay"></iframe>
                    ):"NA"}
                                <a href={data.webContentLink720} download data-toggle="tooltip" title="Download Video"><img src={BlogDownload} /></a>

                </div>
                <div className='col-md-6'>
                    <p>Video 480px:</p>
                 {data.webViewLink480 ?(
                    <iframe src={data.webViewLink480.replace("view","preview")} width="400" height="200" allow="autoplay"></iframe>
                 ):"NA"}   
                                <a href={data.webContentLink480} download data-toggle="tooltip" title="Download Video"><img src={BlogDownload} /></a>

                </div>
            </div>
        </div>
    )
}
