import React, { useState } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { RefundProcessModal } from './RefundProcessModal';

export const RefundCancelRequest = () => {
    const [lgShow, setLgShow] = useState(false);
    return (
        <div>
           <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Refunds & Cancellation Request</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Refunds & Cancellation Request</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Refunds & Cancellation Request</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Refunds & Cancellation Request</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table table-scroll'>
                <div className='d-flex justify-content-between'>
                    <h6>Refunds & Cancellation Request</h6>
                    {/* <Link to="/add-home-widget"><button variant="primary">Add <img src={BlogAdd} /></button></Link> */}
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Order ID</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Billed Amount</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Refund & Cancellation Reason</th>
                            <th scope="col">Status</th>
                            <th scope="col" className='table-head-right'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Date & Time</td>
                            <td>OID#47744</td>
                            <td>John</td>
                            <td>john@gmail.com</td>
                            <td>+91000999992</td>
                            <td>Course Name</td>
                            <td>$55</td>
                            <td>Success</td>
                            <td>#28974347PO</td>
                            <td>Lorem ipsum dolor sit amet, conse c elit. conse ctetur adipiscing elit.</td>
                            <td>Active</td>
                            <td className='blog-icons'>
                                <Link to="#" data-toggle="tooltip" title="View Request"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                <Link to="#" data-toggle="tooltip" title="Delete Request"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Date & Time</td>
                            <td>OID#47744</td>
                            <td>John</td>
                            <td>john@gmail.com</td>
                            <td>+91000999992</td>
                            <td>Course Name</td>
                            <td>$55</td>
                            <td>Success</td>
                            <td>#28974347PO</td>
                            <td>Lorem ipsum dolor sit amet, conse c elit. conse ctetur adipiscing elit.</td>
                            <td>Active</td>
                            <td className='blog-icons'>
                                <Link to="#" data-toggle="tooltip" title="View Request"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                <Link to="#" data-toggle="tooltip" title="Delete Request"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Date & Time</td>
                            <td>OID#47744</td>
                            <td>John</td>
                            <td>john@gmail.com</td>
                            <td>+91000999992</td>
                            <td>Course Name</td>
                            <td>$55</td>
                            <td>Success</td>
                            <td>#28974347PO</td>
                            <td>Lorem ipsum dolor sit amet, conse c elit. conse ctetur adipiscing elit.</td>
                            <td>Active</td>
                            <td className='blog-icons'>
                                <Link to="#" data-toggle="tooltip" title="View Request"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                <Link to="#" data-toggle="tooltip" title="Delete Request"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <RefundProcessModal />
                </Modal.Body>
            </Modal>

        </div>
    )
}
