import "./App.css";
import Routers from "./Routers.js"
import { Footer } from "./Components/Footer/Footer";

import { useEffect } from "react";
import { useState } from "react";
import { devInstance as axios } from "./Plugin/axios";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@progress/kendo-theme-default/dist/all.css';
function App() {
  
  const [fetchData, setFetchdata] = useState(true)
  const fetchDatacall = ()=>{setFetchdata((t)=>!t)}
  const[logo,setlogo]=useState('')
  const baseurl = axios.defaults.baseURL

  useEffect(()=>{
    axios.get('/get').then((res)=>{
      console.log(res.data.data.fevi)
    setlogo(res.data.data.fevi)
    })

  }, [fetchData])
  
  useEffect(() => {
 
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    fetchDatacall()
    link.href = baseurl+logo

  },[logo] );

  return (
    <>
    <Routers/>
    <Footer/>

 

    </>
  );
}

export default App;
