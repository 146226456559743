import React from 'react'
import { BlogEditor } from '../../../BlogPage/MyEditor';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { useSelector } from "react-redux";
import { useEffect,useState } from 'react';
import { devInstance as axios } from "../../../../Plugin/axios";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
export const EditWidgets6 = () => {
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
      } = EditorTools;
      const { user } = useSelector((state) => state.user); 
         const[heading,setheading]=useState('')
        const[Detail_Description,setDetail_Description]=useState('');
        const[ButtonLink,setButtonLink]=useState('');
        const[img,setimg]=useState('');
        const token =user.token
const handleheading =async(e)=>{
    setheading(e.target.value);
}
const handleDetail_Description =async(e)=>{
    setDetail_Description(e.target.value);
}

const handleButtonLink =async(e)=>{
    setButtonLink(e.target.value);
}

const handleimg =async(e)=>{
    setimg(e.target.files[0]);
}
 const handleSubmit =async(e)=>{
    e.preventDefault(e)
    const data ={Detail_Description,heading,img,ButtonLink}
 axios.put('/widget6' ,data, {
    headers: {
        'x-access-token': token,
        'Accept' : 'application/json',
        "Content-Type": "multipart/form-data"}}).then((res)=>{
         if(res.data.code===200)
         {
            toast.success('Widget5 Successfully updated')
         }
        }).catch((error)=>{
            toast.error('Please try again later')
        })
 }
  useEffect(()=>{
    axios.get('/getwidget6', {
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"}}).then((res)=>{
           setButtonLink(res.data.data[0].ButtonLink)
         setDetail_Description(res.data.data[0].Detail_Description)
        setheading(res.data.data[0].heading)
            })
  },[])
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Widgets</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Widgets</p>
            </div>
            <div className='home-widgets'>
                <h6>Widgets 6</h6>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={heading} onChange={handleheading}/>
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Images</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here"  onChange={(e)=>{
                                handleimg(e)}}/>
                        </div>
                    </div>
                </div>
                <div>
                    <p>Detail Description</p>
                    <div className='widgets-editor'>
                    <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
    height: 300
  }}  value={Detail_Description} onChange={handleDetail_Description} />
                    </div>
                </div>
                <div className='manage-widgets widgets-mr'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Links</label>
                            <input type="text" class="form-control" placeholder="Enter Button Links here" value={ButtonLink} onChange={handleButtonLink}/>
                        </div>
                    </div>
                </div>
                <div className="home-update-btn margin-top-mobile1">
                    <button onClick={handleSubmit}>Update</button>
                </div>
            
            </div>
            <ToastContainer/>
        </div>
    )
}