import React from 'react';
import { Link } from 'react-router-dom';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import Download from "../../../src/images/download-icon.svg";

export const BackUp = () => {
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Back and Restore</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Back and Restore</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Back and Restore</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Back and Restore</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button>Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button>GST & Invoice</button></Link>
                <Link to="/app/payment-gateway"><button>Payment Gateway</button></Link>
                <Link to="/app/email-api"><button>Email</button></Link>
                <Link to="/app/sms-api"><button>SMS</button></Link>
                <Link to="/app/socialmedia"><button>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button>Header & Footer</button></Link>
                <Link to="/app/storage-api"><button>Storage</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
                <Link to="/app/backup-restore"><button className='account-btn'>Backup & Re-Store</button></Link>
                <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>BackUp and Restore</h6>
                    <Link to="/app/backup-restore-form">
                        <button variant="primary">Create New Backup<img src={BlogAdd} /></button></Link>
                </div>
                
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Backup Name</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Date & Time</td>
                                <td>Data</td>
                                <td className='blog-icons'>
                                    <Link to="/edit-course" data-toggle="tooltip" title="Edit Course Details"><img src={Download} /></Link>
                                    <Link to="#" data-toggle="tooltip" title="View Course Details"><img src={BlogEye} /></Link>
                                    {/* <Link to="#" data-toggle="tooltip" title="Delete Course"><img src={BlogDelete} /></Link> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <ToastContainer /> */}
            </div>
        </div>
    )
}
