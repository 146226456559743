import React, { useState, useEffect } from 'react';
import BlogImg from "../../../../src/images/blog.png"
import BlogEye from "../../../../src/images/purple-eye.svg"
import BlogDelete from "../../../../src/images/purple-delete.svg"
import BlogEdit from "../../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { CategoryModal } from '../../CategoryPage/CategoryModal';
import { devInstance as axios } from "../../../Plugin/axios";
import { useSelector } from "react-redux";
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
export const QuerySubject = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    const [data, setdata] = useState([])
    const [subject, setsubject] = useState('')
    const [id, setid] = useState('')
    const [fetchData, setFetchdata] = useState(true)
    const baseurl = axios.defaults.baseURL
    const [lgShow, setLgShow] = useState(false);
    const [lgShowe, setLgShowe] = useState(false);
    const [error, setErrors] = useState('');
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    useEffect(() => {
      getallsubject();
    }, [])

    const getallsubject = ()=>{
      axios.get('/getallsubject', {
        headers: {
            'x-access-token': token,
            'Accept': 'application/json',

        }
    }).then((res) => {
        setdata(res.data.data)
    })
    }
    const handleSubmit=()=>{
      setErrors(" ");
      const data ={"name":subject}
      JSON.stringify(data)
      axios.post('/addsubject',data,{
          headers: {
              'x-access-token': token,
              'Accept' : 'application/json',
          }}).then((res)=>{
              toast.success('Subject succesfully added!');
              setLgShow(false)
              getallsubject();
              }).catch(({ response }) => {
                
                if (response.data.code == 422) {
                  setErrors(response.data.errors)
                  // toast.error(response.data.errors.user_email.msg)
                }
              })
    }

    const handleSubmitupdate=()=>{
      setErrors(" ");
      const data ={"_id":id,"name":subject}
      JSON.stringify(data)
      axios.put('/updatesubject',data,{
          headers: {
              'x-access-token': token,
              'Accept' : 'application/json',
          }}).then((res)=>{
              toast.success('Subject succesfully updated!');
              setLgShowe(false)
              getallsubject();

              }).catch(({ response }) => {
                
                if (response.data.code == 422) {
                  setErrors(response.data.errors)
                  // toast.error(response.data.errors.user_email.msg)
                }
              })
    }
    const deleteCourse = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete("/deletesubject/"+id, {
                    headers: {
                        'x-access-token': token,
                        'Accept': 'application/json',
                    }
                })
                    .then(res => {
                        toast.success('subject succesfully deleted!');
                        getallsubject()
                    })
                    .catch(err => toast.error('Error in Category deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    


    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Query Subjects</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Query Subjects</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Query Subjects</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Query Subjects</p>
                    </div>
                </div>
            </div>

            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Query Subjects</h6>
                    <Link to="#"><button onClick={() => {setid("");setLgShow(true);setsubject(""); setErrors(" ");}}>Add Query <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll' >
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td>{item.name} </td>
                                    <td className='blog-icons'>
                                        <Link to="#" onClick={() => {setid(item._id);setLgShowe(true);setsubject(item.name);setLgShow(false); setErrors(" ");}} data-toggle="tooltip" title="Edit Subject"><img src={BlogEdit} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => deleteCourse(item._id)} data-toggle="tooltip" title="Delete Subject"><img src={BlogDelete} /></Link>
                                    </td>
                                </tr>))}

                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                    <Modal show={lgShow} onHide={() => setLgShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Query Subject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                        type="Text"
                                        placeholder="Enter Subject"
                                        autoFocus
                                        value={subject}
                                        onChange={(e)=>setsubject(e.target.value)}
                                    />
                                    {error && error.name && (
                                      <div className="text-danger">{error.name.msg}</div>
                                    )}
                                </Form.Group>
                                
                                
                                <div className='submit-btn'>
                                    <Button onClick={handleSubmit}>Submit</Button>
                                </div>
                            </Form>
                        </Modal.Body>

                    </Modal>
                </div>
                <div>
                    <Modal show={lgShowe} onHide={() => setLgShowe(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Query Subject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control
                                        type="Text"
                                        placeholder="Enter Subject"
                                        autoFocus
                                        value={subject}
                                        onChange={(e)=>setsubject(e.target.value)}
                                        
                                    />
                                    {error?.name?.msg && error?.name?.msg ? <div className="text-danger">{error?.name?.msg && error?.name?.msg}</div> : ''}
                                     
                                </Form.Group>
                                <div className='submit-btn'>
                                    <Button onClick={handleSubmitupdate}>Submit</Button>
                                </div>
                            </Form>
                        </Modal.Body>

                    </Modal>
                </div>
            <ToastContainer />
        </div>
    )
}
