import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./storageApi.css"
import { ToastContainer, toast } from 'react-toastify';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";

export const StorageApi= () => {
    const { user } = useSelector((state) => state.user);
    const [CLIENT_ID,setCLIENT_ID]=useState('');
    const [CLIENT_SECRET,setCLIENT_SECRET]=useState('');
    const [ REDIRECT_URL,setREDIRECT_URL]=useState('');
    const[err,seterr]=useState('');
    const[input,setInput]=useState({
        type:"",
        project_id:"",
        private_key_id:"",
        private_key:"",
        client_email:"",
        client_id:"",
        auth_uri:"",
        token_uri:"",
        auth_provider_x509_cert_url:"",
        client_x509_cert_url:"",
    });
    const[success,setsuccess]=useState('');
const token =user.token
    const handleCLIENT_ID = (e) => {
        setCLIENT_ID(e.target.value);
      };
      const handleCLIENT_SECRET = (e) => {
        setCLIENT_SECRET(e.target.value);
      };
      const handleREDIRECT_URL = (e) => {
        setREDIRECT_URL(e.target.value);
      };
    const handlesubmit =async(e)=>{
        axios.put('/updatestorage',input, {
            headers: {
                'x-access-token': token,
               
            }
    }).then((res)=>{
        if(res.data.code===400){
         toast.error('error found !please try again latter')
         seterr(res.data.message)
        }else{
            toast.success('successfully Updated')
            setsuccess(res.data.message)
        }
    })
    }
    useEffect(()=>{
        viewe();
    },[])
    const viewe = () => {
       
        axios.get("/getstorage",{
            headers: {
                'x-access-token': token,
            }
    }).then(res => {
            setInput(res.data.data);
           
        });
    };
    return (
        <div>
        <div className='d-flex profile-section'>
            <p className='profile-head'>Storage API</p>
            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
            <p className='profile-pera'>Storage</p>
        </div>
        <div className='pages-tabs'>
            <Link to="/app/account"><button >Account Setting</button></Link>
            <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
            <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
            <Link to="/app/email-api"><button>Email</button></Link>
            <Link to="/app/sms-api"><button>SMS</button></Link>
            <Link to="/app/socialmedia"><button>Social Media</button></Link>
            <Link to="/app/headerandfooter"><button>Header & Footer</button></Link>
            <Link to="/app/storage-api"><button className='account-btn'>Storage</button></Link>
            <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
            <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
            <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

        </div>
        <div className='pages-tabs'>
        <div style={{color:'green'}}> {success }</div> 
            <div style={{color:'red'}}> {err}</div> 
            <h3>Manage Google Drive Credentials</h3>
            <div class="alert alert-success" role="alert">
                <b>Note:</b> If you change any setting of google drive, after changing, clear the cache on single click of Cache button.
            </div>

            <div className='storage-api'>
                <div class="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Type</label>
                    <input type="text" class="form-control" placeholder=""value={input.type}  onChange={(e)=>setInput({type:e.target.value})} />
                </div>
                <div class="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Project Id</label>
                    <input type="text" class="form-control" placeholder=""value={input.project_id}  onChange={(e)=>setInput({project_id:e.target.value})} />
                </div>
                <div class="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Private Key Id</label>
                    
                    <input type="text" class="form-control" placeholder=""value={input.private_key_id}  onChange={(e)=>setInput({private_key_id:e.target.value})} />
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Private Key</label>
                    <textarea rows="10" onChange={(e)=>setInput({private_key:e.target.value})} className='form-control' value={input.private_key}></textarea>
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Client Email</label>
                    <input type="text" className="form-control" placeholder=""value={input.client_email}  onChange={(e)=>setInput({client_email:e.target.value})} />
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Client Id</label>
                    <input type="text" className="form-control" placeholder=""value={input.client_id}  onChange={(e)=>setInput({client_id:e.target.value})} />
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Auth URL</label>
                    <input type="text" className="form-control" placeholder=""value={input.auth_uri}  onChange={(e)=>setInput({auth_uri:e.target.value})} />
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Token URL</label>
                    <input type="text" className="form-control" placeholder=""value={input.token_uri}  onChange={(e)=>setInput({token_uri:e.target.value})} />
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Auth Provider URL</label>
                    <input type="text" className="form-control" placeholder=""value={input.auth_provider_x509_cert_url}  onChange={(e)=>setInput({auth_provider_x509_cert_url:e.target.value})} />
                </div>
                <div className="form-group storage-mr margin-top-3">
                    <label for="exampleInputEmail1">Client URL</label>
                    <input type="text" className="form-control" placeholder=""value={input.client_x509_cert_url}  onChange={(e)=>setInput({client_x509_cert_url:e.target.value})} />
                </div>
               
                <div>
                    {/* <button className='cache-btn'>Clear Cache</button> */}
                </div>
                <div className='margin-top-3'>
                    <button className='update-setting'onClick={handlesubmit}>Update Settings</button>
                </div>
            </div>
        </div>
        <ToastContainer/>
    </div>
    )
}
