import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import "./gstInvoice.css"
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import { Country, State, City } from "country-state-city";
import { useEffect } from 'react';
export const GstInvoicePage = () => {
  const [countryName, setCountryname] = useState([]);
  const [country, setCountry] = useState([]);
  const [statename, setStateName] = useState([]);
  const [cityname, setcityName] = useState([]);
  const [city, setcity] = useState('');
  const [cgst, setcgst] = useState('');
  const [sgst, setsgst] = useState('');
  const [igst, setigst] = useState('');
  const [state, setstate] = useState('');
  const [prefix, setprefix] = useState('');
  const [searialno, setsearialno] = useState('');
  const [status, setstatus] = useState('');
  useEffect(() => {
    setCountryname(Country.getAllCountries());
  }, []);
  const handlecountry = (e) => {
    setCountry(e.target.value);
  };

  useEffect(() => {
    setStateName(State.getStatesOfCountry(country));
  }, [country]);

  const handelstate = (e) => {
    setstate(e.target.value);
  };

  useEffect(() => {
    setcityName(City.getCitiesOfState(country, state));
  }, [country, state]);
  const handelCity = (e) => {
    setcity(e.target.value);
  };
  const { user } = useSelector((state) => state.user);
  const BasicSchema = Yup.object().shape({
    state: Yup.string().required('state is require'),
    ads_pincode: Yup.string().min(6, 'Invalid pincode').required('pin is require'),
    ads_pancardno: Yup.string().min(6, 'Please enter your pancads_pancardno').required('pancardNO is require'),
    ads_gstnumber: Yup.string().min(6, 'Enter valid Gst No').required('gst number is require'),
    country: Yup.string().required('Select Country Name'),
    ads_address: Yup.string().min(6, 'Enter valid Address').required('Address is require, Please enter Full address'),
    city: Yup.string().required("city is require"),
    ads_companyname: Yup.string().min(6, 'Please enter your Companyname').required('Companyname is require,please enter CompanyName'),
  });

  const InvSchema = Yup.object().shape({
    prifix: Yup.string().required('Prifix is require'),
    serialno: Yup.string().min(4, 'Invalid Serial No').required('Serial No  is require'),

  });

  const [ads_companyname, setads_companyname] = useState('')
  const [ads_address, setads_address] = useState('')
  const [ads_pincode, setads_pincode] = useState('');
  const [ads_gstnumber, setads_gstnumber] = useState('');
  const [ads_pancardno, setads_pancardno] = useState('')
  const [_id, set_id] = useState('')
  useEffect(() => {
    axios.get('/get_AdminGstUT', {
      headers: {
        'x-access-token': token,
        'Accept': 'application/json',

      }
    }).then((res) => {
      setads_companyname(res.data.Data[0].ads_companyname)
      setads_gstnumber(res.data.Data[0].ads_gstnumber)
      setads_address(res.data.Data[0].ads_address)
      setads_pancardno(res.data.Data[0].ads_pancardno)
      setads_pincode(res.data.Data[0].ads_pincode)
      set_id(res.data.Data[0]._id)
      setCountry(res.data.Data[0].country);
      setstate(res.data.Data[0].state)
      setcity(res.data.Data[0].city)
      setcgst(res.data.Data[0].cgst)
      setigst(res.data.Data[0].igst)
      setsgst(res.data.Data[0].sgst)
    });
    axios.get('/get_AdminInvoice', {
      headers: {
        'x-access-token': token,
        'Accept': 'application/json',

      }
    }).then((res) => {
      setprefix(res.data.Data[0].inv_prefix)
      setsearialno(res.data.Data[0].inv_serialKey)
      setstatus(res.data.Data[0].inv_status)
    });
  }, [])
  const handelads_companyname = (e) => {
    setads_companyname(e.target.value);
  };

  const handelads_address = (e) => {
    setads_address(e.target.value);
  };
  const handelads_pincode = (e) => {
    setads_pincode(e.target.value);
  };
  const handelads_gstnumber = (e) => {
    setads_gstnumber(e.target.value);
  };
  const handelads_pancardno = (e) => {
    setads_pancardno(e.target.value);
  };
  function handlesubmit1() {
    axios.post('/inv', { inv_serialKey: searialno, inv_prefix: prefix, inv_status: status }, {
      headers: {
        'x-access-token': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      if (res.data.code === 400) {
        toast.error('unable to update invoice setting ')
      } else {
        toast.success('invoice setting updated')
      }

    })
  }
  const token = user.token;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: country,
      state: state,
      city: city,
      sgst: sgst,
      cgst: cgst,
      igst: igst,
      _id: _id,
      ads_companyname: ads_companyname,
      ads_address: ads_address,
      ads_gstnumber: ads_gstnumber,
      ads_pancardno: ads_pancardno,
      ads_pincode: ads_pincode
    },
    validationSchema: BasicSchema,
    onSubmit: values => {
      axios.put('/AdminGstUT', values, {
        headers: {
          'x-access-token': token,
          'Accept': 'application/json',

        }
      }).then((res) => {
        if (res.data.code === 200) {
          toast.success('Gst successfully updated')
        } else {
          toast.error('please try again')
        }
      })
    },
  });
  const {
    errors,
    touched,
    values,
  } = formik;



  return (
    <div>
      <div className='hide-in-desktop'>
        <div className='d-flex profile-section'>
          <p className='profile-head'>GST & Invoice Setting</p>
          <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
          <p className='profile-pera'>GST & Invoice Setting</p>
        </div>
      </div>
      <div className='hide-in-mobile'>
        <div className='profile-section'>
          <p className='profile-head'>GST & Invoice Setting</p>
          <div className='d-flex'>
            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
            <p className='profile-pera'>GST & Invoice Setting</p>
          </div>
        </div>
      </div>
      {/* <div className='d-flex profile-section'>
        <p className='profile-head'>GST & Invoice Setting</p>
        <p className='profile-home'><img src="../../images/purple-home.svg" />Page</p>
        <p className='profile-pera'>GST & Invoice Setting</p>
      </div> */}
      <div className='pages-tabs'>
        <Link to="/app/account"><button >Account Setting</button></Link>
        <Link to="/app/gst-invoice"><button className='account-btn'>GST & Invoice Setting</button></Link>
        <Link to="/app/payment-gateway"><button>Payment Gateway</button></Link>
        <Link to="/app/email-api"><button>Email API</button></Link>
        <Link to="/app/sms-api"><button>SMS API</button></Link>
        <Link to="/app/socialmedia"><button>Social Media</button></Link>
        <Link to="/app/headerandfooter"><button>Header and Footer</button></Link>
        <Link to="/app/storage-api"><button >Storage API</button></Link>
        <Link to="/app/firebase-api"><button>Firebase Token API</button></Link>
        <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>
      </div>

      <div className='gst-mr'>
        <div className='gst-inputs gst-col-mr'>
          <div className=''>
            <h6>GST Setting</h6>
            <div class="form-group">
              <label for="exampleInputEmail1">Company Name</label>
              <input type="text" class="form-control" name="ads_companyname" placeholder="" value={values.ads_companyname} onChange={(e) => {
                handelads_companyname(e);
                formik.handleChange(e);
              }} />
              {errors.ads_gstnumber && touched.ads_gstnumber && (
                <p style={{ color: "red" }}>{errors.ads_gstnumber}</p>
              )}
            </div>
            <div class="form-group margin-top-3">
              <label for="exampleFormControlTextarea1">Full Address</label>
              <textarea class="form-control" name="ads_address" rows="3" value={values.ads_address} onChange={(e) => {
                handelads_address(e);
                formik.handleChange(e);
              }} ></textarea>
              {errors.ads_address && touched.ads_address && (
                <p style={{ color: "red" }}>{errors.ads_address}</p>
              )}
            </div>
          </div>
          <div className='row'>
            <div className="draft-mr">
              <div class="form-group status-dropdown">
                <label for="exampleInputEmail1">Country </label>
                <select class="form-select" name='country' onChange={(e) => { handlecountry(e); formik.handleChange(e) }} value={values.country} aria-label="Default select example">
                  <option selected >Select your country</option>
                  {countryName.map((item, i) => (
                    <option key={i} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.country && touched.country && (
                  <p style={{ color: "red" }}>{errors.country}</p>
                )}
              </div>
            </div>
            <div className="draft-mr">
              <div class="form-group status-dropdown">
                <label for="exampleInputEmail1">State</label>
                <select class="form-select" name='state' onChange={(e) => { handelstate(e); formik.handleChange(e) }} value={values.state} aria-label="Default select example">
                  <option selected >Select your state</option>
                  {statename.map((index, i) => (
                    <option key={i} value={index.isoCode}>
                      {index.name}
                    </option>
                  ))}
                </select>
                {errors.state && touched.state && (
                  <p style={{ color: "red" }}>{errors.state}</p>
                )}
              </div>
            </div>
            <div className="draft-mr">
              <div class="form-group status-dropdown">
                <label for="exampleInputEmail1">City</label>
                <select class="form-select" name='city' onChange={(e) => { handelCity(e); formik.handleChange(e) }} value={values.city} aria-label="Default select example">
                  <option selected>Select your City</option>
                  {cityname.map((item, i) => (
                    <option key={i} value={item.name}>{item.name}</option>
                  ))}
                </select>
                {errors.city && touched.city && (
                  <p style={{ color: "red" }}>{errors.city}</p>
                )}
              </div>
            </div>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div class="form-group">

                <label for="exampleInputEmail1">CGST</label>
                <input type="text" class="form-control" name="cgst" value={values.cgst} onChange={(e) => {
                  setcgst(e.target.value);
                  formik.handleChange(e);
                }} />
              </div>
              {errors.cgst && touched.cgst && (
                <p style={{ color: "red" }}>{errors.cgst}</p>
              )}
            </div>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div class="form-group margin-top-3">
                <label for="exampleInputEmail1">SGST</label>
                <input type="text" class="form-control" name="sgst" value={values.sgst} onChange={(e) => {
                  setsgst(e.target.value);
                  formik.handleChange(e);
                }} />
              </div>
              {errors.sgst && touched.sgst && (
                <p style={{ color: "red" }}>{errors.sgst}</p>
              )}
            </div>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div class="form-group margin-top-3">
                <label for="exampleInputEmail1">IGST</label>
                <input type="text" class="form-control" name="igst" value={values.igst} onChange={(e) => {
                  setigst(e.target.value);
                  formik.handleChange(e);
                }} />
              </div>
              {errors.igst && touched.igst && (
                <p style={{ color: "red" }}>{errors.igst}</p>
              )}
            </div>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div class="form-group margin-top-3">
                <label for="exampleInputEmail1">Pincode</label>
                <input type="text" class="form-control" name="ads_pincode" value={values.ads_pincode} onChange={(e) => {
                  handelads_pincode(e);
                  formik.handleChange(e);
                }} />
              </div>
              {errors.ads_pincode && touched.ads_pincode && (
                <p style={{ color: "red" }}>{errors.ads_pincode}</p>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div class="form-group margin-top-3">
                <label for="exampleInputEmail1">GST Number</label>
                <input type="text" class="form-control" name="ads_gstnumber" value={values.ads_gstnumber} onChange={(e) => {
                  handelads_gstnumber(e);
                  formik.handleChange(e);
                }} />
              </div>
              {errors.ads_gstnumber && touched.ads_gstnumber && (
                <p style={{ color: "red" }}>{errors.ads_gstnumber}</p>
              )}
            </div>
            <div className='col-md-6 col-sm-12 col-xs-12'>
              <div class="form-group margin-top-3">
                <label for="exampleInputEmail1">PAN Card Number</label>
                <input type="text" class="form-control" name="ads_pancardno" placeholder="" value={values.ads_pancardno} onChange={(e) => {
                  handelads_pancardno(e);
                  formik.handleChange(e);
                }} />
              </div>
              {errors.ads_pancardno && touched.ads_pancardno && (
                <p style={{ color: "red" }}>{errors.ads_pancardno}</p>
              )}
            </div>
          </div>
          <div className='update-btn' ><button onClick={(e) => {
            formik.handleSubmit(e);
          }}>Update</button></div>
        </div>


        <div className='gst-inputs gst-col-height'>
          <Formik
            initialValues={{ inv_serialKey: searialno, inv_prefix: prefix, inv_status: status }}
            validationSchema={InvSchema}
            onSubmit={(values) => {

              axios.post('/inv', values, {
                headers: {
                  'x-access-token': token,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
              }).then((res) => {
                if (res.data.code === 400) {
                  toast.error('unable to update invoice setting ')
                } else {
                  toast.success('invoice setting updated')
                }

              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <h6>Invoice Setting</h6>
                <div className='row'>
                  <div className='col-md-6 col-sm-12 col-xs-12'>
                    <div class="form-group margin-top-3">
                      {errors.inv_prefix && touched.inv_prefix && <p className='err2' style={{ color: 'red', marginBottom: '-1.5rem', marginLeft: '5.5rem' }}>{errors.prifix}</p>}
                      <label for="exampleInputEmail1">Enter Prefix</label>
                      <input type="text" name='inv_prefix' class="form-control" placeholder="" id={errors.prifix && touched.prifix ? "error" : ""} value={prefix} onChange={(e) => setprefix(e.target.value)} onBlur={handleBlur} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12 col-xs-12'>
                    <div class="form-group margin-top-3">
                      {errors.inv_serialKey && touched.inv_serialKey && <p className='err2' style={{ color: 'red', marginBottom: '-1.5rem', marginLeft: '9rem' }}>{errors.inv_serialKey}</p>}
                      <label for="exampleInputEmail1">Enter Serial Number</label>
                      <input name='serialno' type="text" class="form-control" placeholder="" id={errors.serialno && touched.inv_serialKey ? "error" : ""} value={searialno} onChange={(e) => setsearialno(e.target.value)} onBlur={handleBlur} />
                    </div>
                  </div>
                  <div className='checkbox checkbox-mr'>
                    <input type="checkbox" id="vehicle1" checked={status == true ? "true" : ""} onChange={(e) => setstatus(e.target.checked ? true : false)} name="vehicle1" value="true" />
                    <label for="vehicle1">Create Invoice Number using Current Financial Year & Month</label>
                  </div>
                </div>
                <div className='update-btn'><button onClick={() => handlesubmit1()}>Update</button></div></form>
            )}
          </Formik>
        </div>

      </div>
      <ToastContainer />
    </div>
  )
}
