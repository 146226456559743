import React, { useState } from 'react';
import "./faqPage.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DeleteIcon from '../../../src/images/purple-delete.svg';
import Pencil from '../../../src/images/purple-pencil.svg';
import {
    Box,
    Container,
    AppBar,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Item,
    Toolbar,
    Typography,
    Breadcrumbs,
    Paper,
    InputBase,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from '@mui/icons-material/Edit';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
export const FaqPage = (props) => {
    const[Question,setQuestion]=useState('');
    const[Answer,setAnswer]=useState('');
    const[type,settype]=useState('');
    const[id,setid]=useState('');
    const { user } = useSelector((state) => state.user); 
    const token = user.token
    const [show, setShow] = useState(false);
    const [showe, setShowe] = useState(false);
   const [data,setdata]=useState([])
   const [datae,setdatae]=useState()
    const handleClose = () => setShow(false);
    const handleClosee = () => setShowe(false);
    const handleShow = () => setShow(true);
    const handleShowe = () => setShowe(true);
const handleQuestion =(e)=>{
    setQuestion(e.target.value)
}
const handleAnswer =(e)=>{
    setAnswer(e.target.value)
}
 const handleSubmit =async(e)=>{
    e.preventDefault()
    const data ={Question,Answer,type}
    JSON.stringify(data)
    axios.post('/faq',data,{
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
        }}).then((res)=>{
                console.log(res.data)
                setTimeout(function() {
                    window.location.replace('/app/faq');
                  }, 500);
            })
 }
 const handleSubmitupdate =async(e)=>{
    e.preventDefault()
    const data ={"_id":id,Question,Answer,type}
    JSON.stringify(data)
    axios.put('/updatefaq',data,{
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
        }}).then((res)=>{
                console.log(res.data)
                setTimeout(function() {
                    window.location.replace('/app/faq');
                  }, 500);
            })
 }
useEffect(()=>{
    fetchDatacall();

},[])
const fetchDatacall=()=>{
    axios.get('/getfaq',{
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
        }}).then((res)=>{
          setdata(res.data.data)
        })
}
const delete_faq = (id) => {
    Swal.fire({
        title: 'Are you sure, you want to delete it?',
        icon: 'info',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'yes',
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            const data = {
                "_id":id,
    
            };
           
            // JSON.stringify(data)
            axios.delete('/deletefaq/'+id)
                .then(res => {
                    console.log(res);
                    fetchDatacall()
                    toast.success('Faq Details succesfully deleted');
                })
                .catch(err => toast.error('Error in Faq  deletion'))
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    
};
    
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>FAQ</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>FAQ</p>
            </div>
            <div className='faq-banner'>
                <h6>Let's answer some questions </h6>
                <p>or choose a category to quickly find the help you need </p>
                <div class="form-group faq-input">
                    <input type="text" class="form-control" placeholder="" />
                </div>
            </div>
            <div className='add-faq-btn'>
                <Button variant="primary" onClick={()=>{handleShow();setQuestion('');setAnswer('');settype("")}}>
                    Add FAQ
                </Button>
            </div>
            <div className=''>
                <div className='mobile-faq'>
                    {data.map((item,i) => (
                        <div className='faq-actions' key={i}>
                            <Accordion style={{ marginTop: "27px", width: "86%" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {item.Question}
                                </AccordionSummary>
                                <AccordionDetails >
                                    {item.Answer}
                                </AccordionDetails>
                            </Accordion>
                            <div className='faq-icons'>
                                <img src={DeleteIcon} onClick={() => delete_faq(item._id)} />
                                <img src={Pencil} onClick={()=>{handleShowe();setQuestion(item.Question);setAnswer(item.Answer);setid(item._id);settype(item.type)}} />
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className=''>
                    {data.map((acco) => (
                        <div className='faq-actions'>
                            <Accordion style={{ marginTop: "27px", width: "86%" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {acco.Question}
                                </AccordionSummary>
                                <AccordionDetails >
                                    {acco.Answer}
                                </AccordionDetails>
                            </Accordion>
                            <div className='faq-icons'>
                                <img src={DeleteIcon} />
                                <img src={Pencil} />
                            </div>
                        </div>
                    ))}
                </div> */}

                {/* Modal  */}
                <div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add FAQ</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control
                                        type="Text"
                                        placeholder="Enter Question here"
                                        autoFocus
                                        value={Question}
                                        onChange={handleQuestion}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Answer</Form.Label>
                                    <Form.Control as="textarea" value={Answer} onChange={handleAnswer} rows={3} placeholder="Enter Your answer here" />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Type</Form.Label>
                                    <select className='form-control' value={type} onChange={(e)=>settype(e.target.value)}>
                                        <option value="users">For All Users</option>
                                        <option value="students">For Students</option>
                                    </select>
                                </Form.Group>
                                <div className='submit-btn'>
                                    <button onClick={handleSubmit}>Submit</button>
                                </div>
                            </Form>
                        </Modal.Body>

                    </Modal>
                </div>
                <div>
                    <Modal show={showe} onHide={handleClosee}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit FAQ</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control
                                        type="Text"
                                        placeholder="Enter Question here"
                                        autoFocus
                                        value={Question}
                                        onChange={handleQuestion}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Answer</Form.Label>
                                    <Form.Control as="textarea" value={Answer} onChange={handleAnswer} rows={3} placeholder="Enter Your answer here" />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Type</Form.Label>
                                    <select className='form-control' value={type} onChange={(e)=>settype(e.target.value)}>
                                        <option value="users">For All Users</option>
                                        <option value="students">For Students</option>
                                    </select>
                                </Form.Group>
                                <div className='submit-btn'>
                                    <button onClick={handleSubmitupdate}>Submit</button>
                                </div>
                            </Form>
                        </Modal.Body>

                    </Modal>
                </div>
            </div>
        </div>
    )
}
