import React, { useState } from 'react';
import { devInstance as axios } from "../../Plugin/axios";
import "./homeWidgets.css";
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ViewHomeModal } from './components/ViewHomeModal';
import { WedgetModal1 } from './components/WidgetModal1';
import { WedgetModal2 } from './components/WidgetModal2';
import { WedgetModal3 } from './components/WidgetModal3';
import { WedgetModal4 } from './components/WidgetModal4';
import { WedgetModal5 } from './components/WidgetModal5';
import { WedgetModal6 } from './components/WidgetModal6';
import { WedgetModal7 } from './components/WidgetModal7';
import { WedgetModal8 } from './components/WidgetModal8';
import { WedgetModal9 } from './components/WidgetModal9';
import { WedgetModal10 } from './components/WidgetModal10';
import { WedgetModal11 } from './components/WidgetModal11';
import { ToastContainer, toast } from 'react-toastify';
import { profileStart, profileSuccess, profileFail } from "../../redux/ProfileUpdateSlice";
import 'react-toastify/dist/ReactToastify.css';;
export const ManageHome = () => {
    const { user } = useSelector((state) => state.user);
    const [Data, setData] = useState([])
    const [lgShow, setLgShow] = useState(false);
    const [fetchData, setFetchdata] = useState(true)
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    const [widgetShow1, setWidgetShow1] = useState(false);
    const [widgetShow2, setWidgetShow2] = useState(false);
    const [widgetShow3, setWidgetShow3] = useState(false);
    const [widgetShow4, setWidgetShow4] = useState(false);
    const [widgetShow5, setWidgetShow5] = useState(false);
    const [widgetShow6, setWidgetShow6] = useState(false);
    const [widgetShow7, setWidgetShow7] = useState(false);
    const [widgetShow8, setWidgetShow8] = useState(false);
    const [widgetShow9, setWidgetShow9] = useState(false);
    const [widgetShow10, setWidgetShow10] = useState(false);
    const [widgetShow11, setWidgetShow11] = useState(false);
    const [view,setview]=useState();
    const baseurl=axios.defaults.baseURL;
    const token = user.token
    useEffect(() => {
        axios.get('/getwidget', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setData(res.data.data)
        })
    }, [fetchData])
    const viewe = (_id) => {
        const data = { _id };
        axios.post("/get_widget", data).then(res => {

            console.log(res)
            localStorage.setItem(
                "Detail2",
                JSON.stringify(res.data.data)
            );
            fetchDatacall()

        });
    };

    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Widgets</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Widgets</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Widgets</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Widgets</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
            <Link to="/app/home-widget" ><button className='account-btn'>Widgets 1</button></Link>
                {/* <Link to="/app/home-widget2"><button className='account-btn'>Widgets 2</button></Link> */}
                <Link to="/app/home-widget3"><button >Widgets 2</button></Link>
                <Link to="/app/home-widget4"><button>Widgets 3</button></Link>
                <Link to="/app/home-widget5"><button>Widgets 4</button></Link>
                {/* <Link to="/app/home-widget6"><button>Widgets 5</button></Link> */}
                <Link to="/app/home-widget7"><button>Widgets 5</button></Link>
                <Link to="/app/home-widget8"><button>Widgets 6</button></Link>
                <Link to="/app/home-widget9"><button>Widgets 7</button></Link>
                <Link to="/app/home-widget10"><button>Widgets 8</button></Link>
                <Link to="/app/home-widget11"><button>Widgets 9</button></Link>
                <Link to="/app/home-widget12"><button>Widgets 10</button></Link>
            </div>

            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Widgets 1</h6>
                    {/* <Link to="/app/add-home-widget"><button variant="primary">Add <img src={BlogAdd} /></button></Link> */}
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Image</th>
                                <th scope="col">Heading</th>
                                <th scope="col">Short Description</th>
                                <th scope="col">Features Description</th>
                                <th scope="col">Button Link</th>
                                <th scope="col" className='table-head-right'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Data?.map((item, i) => (


                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td><img src={`${baseurl+item.img1}`} style={{ width: "100px" }} /></td>
                                    <td>{item.heading}</td>
                                    <td>{item.Short_Description}</td>
                                    <td>{item.Features_Description1}</td>
                                    <td>{item.ButtonLink1}</td>
                                    <td className='blog-icons table-td-right'>
                                        <Link to="/app/edit-home-widget" data-toggle="tooltip" title="Edit Home Widget"> <img src={BlogEdit} /></Link>
                                        <Link onClick={() => setview(item._id)} to="#" data-toggle="tooltip" title="View Home Widgets"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>

                                    </td>
                                </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewHomeModal data={view} />
                </Modal.Body>
            </Modal>
            {/* Widget modal 2 */}
            <Modal
                show={widgetShow1}
                onHide={() => setWidgetShow1(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal1 />
                </Modal.Body>
            </Modal>
            {/* Widget modal 3 */}
            <Modal
                show={widgetShow2}
                onHide={() => setWidgetShow2(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal2 />
                </Modal.Body>
            </Modal>
            {/* Widget modal 4 */}
            <Modal
                show={widgetShow3}
                onHide={() => setWidgetShow3(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal3 />
                </Modal.Body>
            </Modal>

            {/* Widget modal 5 */}
            <Modal
                show={widgetShow4}
                onHide={() => setWidgetShow4(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal4 />
                </Modal.Body>
            </Modal>
            {/* Widget modal 6 */}
            <Modal
                show={widgetShow5}
                onHide={() => setWidgetShow5(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal5 />
                </Modal.Body>
            </Modal>

            {/* Widget modal 7 */}
            <Modal
                show={widgetShow6}
                onHide={() => setWidgetShow6(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal6 />
                </Modal.Body>
            </Modal>

            {/* Widget modal 8 */}
            <Modal
                show={widgetShow7}
                onHide={() => setWidgetShow7(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal7 />
                </Modal.Body>
            </Modal>

            {/* Widget modal 9 */}
            <Modal
                show={widgetShow8}
                onHide={() => setWidgetShow8(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal8 />
                </Modal.Body>
            </Modal>

            {/* Widget modal 10 */}
            <Modal
                show={widgetShow9}
                onHide={() => setWidgetShow9(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal9 />
                </Modal.Body>
            </Modal>

            {/* Widget modal 11 */}
            <Modal
                show={widgetShow10}
                onHide={() => setWidgetShow10(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal10 />
                </Modal.Body>
            </Modal>
            {/* Widget modal 12 */}
            <Modal
                show={widgetShow11}
                onHide={() => setWidgetShow11(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WedgetModal11 />
                </Modal.Body>
            </Modal>

        </div >
    )
}
