import React, { useEffect, useState } from 'react';
import "./profilePage.css"
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { profileStart,profileSuccess,profileFail} from "../../redux/ProfileUpdateSlice";
import 'react-toastify/dist/ReactToastify.css';;
export const ProfilePage = () => {


  const { user } = useSelector((state) => state.user);
  const[ad_name,setAd_name]=useState('');
  const[ad_email,setAd_email]=useState('');
  const[ad_Alt_email,setad_Alt_email]=useState('');
  const[ad_mno,setAd_mno]=useState('')
  const[ad_whtapno,setAdwhatapno]= useState('');
  const[img,setImg]=useState('');
  const[banner,setBanner]=useState('')
  const[file,setfile]=useState('');
  const[file1,setfile1]=useState('');
  const[ ad_desgination,setad_desgination]=useState('')
  const[ ad_address,setad_address]=useState('')
  const[ad_website,setad_website]=useState('')
  const[value,setvalue]=useState('')
  const [msg,setmsg]=useState('');
  const [emailErr,setemailErr]=useState('');
  const [alemailErr,setalemailErr]=useState('');
  const [mo,setmo]=useState('');
  const [wmo,setwmo]=useState('');
  const dispatch = useDispatch();


  const token=user.token
  const baseurl=axios.defaults.baseURL;
  useEffect(()=>{
    axios.get('/getadmin',{
      headers: {
          'x-access-token': token,
          'Accept' : 'application/json',
         
      }}).then((res)=>{
    setvalue(res.data)
    setfile(baseurl+res.data.img)
    setfile1(baseurl+res.data.banner)
    setAd_email(res.data.ad_email)
    setAd_mno(res.data.ad_mno)
    setAd_name(res.data.ad_name)
    setAdwhatapno(res.data.ad_whtapno)
    setad_Alt_email(res.data.ad_Alt_email)
    setad_desgination(res.data.ad_address)
    setad_address(res.data.ad_address)
    setad_website(res.data.ad_website)
    })
  },[])
  const data=JSON.parse(localStorage.getItem('user'))
  
  const handleAd_name = (e) => {
    setAd_name(e.target.value);
  };
  const handleAd_email = (e) => {
    setAd_email(e.target.value);
  };
  const handlead_Alt_email = (e) => {
    setad_Alt_email(e.target.value);
  };
  const handleAd_mno = (e) => {
    setAd_mno(e.target.value);
  };
  const handleAdwhatapno = (e) => {
    setAdwhatapno(e.target.value);
  };
  const handleImg = (e) => {
    setfile(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
  };
  const handlebanner = (e) => {
    setBanner(e.target.files[0]);
    setfile1(URL.createObjectURL(e.target.files[0]));
  };
  const handleaddress = (e) => {
    setad_address(e.target.value);
  };
  const handledesgination = (e) => {
    setad_desgination(e.target.value);
  };
  
  const handelad_website = (e) => {
    setad_website(e.target.value);
  };
useEffect(()=>{
  const regex =/^\d{10}$/;
  if(regex.test(ad_mno)){
      setmo('')
  }else{
    setmo('Invalid Mobile No,please enter 10 digit NO')
  }
  if(regex.test(ad_whtapno)){
    setwmo('')
}else{
  setwmo('Invalid Mobile No,please enter 10 digit NO')
}
  const emailRegex =/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if(emailRegex.test(ad_email))
  {
   
  setemailErr('')
    
  }else{
    setemailErr('invalid email')
  }
  if(emailRegex.test(ad_Alt_email))
  {
   
  setalemailErr('')
    
  }else{
    setalemailErr('invalid email')
  } 
})
  const handleSubmit=async(e)=>{
  try {

e.preventDefault();
  dispatch(profileStart);
  const data ={ad_name,
 ad_email,
 ad_Alt_email,
 ad_mno,
 ad_whtapno,
 ad_address,
 ad_desgination,
 ad_website,
 img,
 banner
}

  axios.put('/update_profile',data, {
    headers: {
        'x-access-token': token,
        'Accept' : 'application/json',
        "Content-Type": "multipart/form-data"
    }

  }).then((res)=>{
    dispatch(profileSuccess(res.data));
 if(res.data.code ===400){
  setmsg(res.data.message)
  toast.error('error')
 }else{
  setImg("");
  setBanner("");
  toast.success('profile successfully updated')
  window.location.replace('/app/profile');
  setTimeout(function() {
    // window.location.replace('/app/profile');
  }, 2000);
 }
      
    
  })
  
  } catch (error) {
   
   console.log(error) 
  }
  
  } 



  return (
    <div>  
      <div className='d-flex profile-section'>
        <p className='profile-head'>Profile</p>
        <p className='profile-home'><img src="../../images/purple-home.svg" />Page</p>
        <p className='profile-pera'>Profile</p>
      </div>
      <div className='row address-mr'>
        <div className='col-md-3 user-address'>
          <div>
            <h6>Lives</h6>
            <p>{value.ad_address}</p>
          </div>
          <div>
            <h6>Email</h6>
            <p>{value.ad_email} </p>
          </div>
          <div>
            <h6>Website</h6>
            <p>{value.ad_website} </p>
          </div>
        </div>
        <div className='col-md-9 '>
          <div className='profile-banner'>
            <div className='user-information'>
              <div>
                <img  style={{width: "50px",merginTop:"2rem", height:"3.5rem",borderRadius: "50%"}}src={`${file}`}  />
              </div>
              <div className='user-designation'>
                <h6>{value.ad_name}</h6>
                <p>{value.ad_desgination}</p>
              </div>
            </div>
          </div>

          <div className='profile-btn'>
            <button>Timeline</button>
          </div>
          <div style={{color:'red'}}> {msg }</div> 
          <div className='profile-inputs '>
            <div className='row'>
              <div className='col-md-4'>
                <div class="form-group">
                  <label for="exampleInputEmail1" >Full Name</label>
                  <input type="text" class="form-control" placeholder="" value={ad_name}onChange={handleAd_name}/>
                </div>
              </div>
              <div className='col-md-4'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1" >Designation</label>
                  <input type="text" class="form-control" placeholder="" value={ad_desgination} onChange={handledesgination}/>
                </div>
              </div>
              <div className='col-md-4'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1" >Email Id  <b>(used for login)</b></label>
                  <input type="email" class="form-control" placeholder="" value={ad_email}onChange={handleAd_email}/>
                  {emailErr && (
          <label style={{ color: "red" }} htmlFor="message">
            {emailErr}
          </label>
        )}
                </div>
              </div>
              <div className='col-md-4'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">Email Id  <b>(for receiving alerts)</b></label>
                  <input type="email" class="form-control"value={ad_Alt_email}onChange={handlead_Alt_email} placeholder="" />
                  {alemailErr && (
          <label style={{ color: "red" }} htmlFor="message">
            {alemailErr}
          </label>
        )}
                </div>
              </div>
              <div className='col-md-4'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">Mobile Number</label>
                  <input type="text" class="form-control" placeholder="" value={ad_mno}onChange={handleAd_mno}/>
                  {mo && (
          <label style={{ color: "red" }} htmlFor="message">
            {mo}
          </label>
        )}
          
                </div>
              </div>
              <div className='col-md-4'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">WhatsApp Number</label>
                  <input type="text" class="form-control" placeholder="" value={ad_whtapno}onChange={handleAdwhatapno}/>
                  {wmo && (
          <label style={{ color: "red" }} htmlFor="message">
            {wmo}
          </label>
        )}
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">Address & Location</label>
                  <input type="text" class="form-control" placeholder="" value={ad_address} onChange={handleaddress} />
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">Website URL</label>
                  <input type="text" class="form-control" placeholder="" value={ad_website} onChange={handelad_website}/>
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">Browse Profile Image  <span className='image-size'>image size (100 * 100)</span></label>
                  <input type="file" class="form-control" placeholder=""  onChange={(e) => {
                  handleImg(e);}}/>
                   <img src={file} style={{"height":100,"width":100}} />
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-group margin-top-mobile2">
                  <label for="exampleInputEmail1">Browse Timeline Banner Image  <span className='image-size'>image size (1000 * 250)</span></label>
                  <input type="file" class="form-control" placeholder="" onChange={(e) => {
                  handlebanner(e);}} />
                   <img src={file1} style={{"height":100,"width":100}} />
                </div>
              </div>
              <div className='update-btn' onClick={handleSubmit}><button>Update</button></div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}
