import * as Yup from 'yup';


 export const LoginSchema = Yup.object().shape({
    ad_password: Yup.string()
        .min(4, 'Password Too Short!')
        .max(50, 'Password Too Long!')
       
        .required('password is required, please enter password'),
      ad_email: Yup.string().email("Please enter a valid email").required('Email is require,please enter email'),
    });

