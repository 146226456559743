import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { devInstance as axios } from "../../Plugin/axios";
import { useNavigate } from 'react-router-dom';
export const AddSlider = () => {
    const { user } = useSelector((state) => state.user);
    const token =user.token
const navigate = useNavigate();
    const[Heading,setHeading]=useState('')
    const[ButtonLink,setButtonLink]=useState('');
    const[img,setimg]=useState('');
    const[status,setStatus]=useState('');
    const[file,setfile]=useState('');
    const[Descriptions,setDescriptions]=useState('');
    const handleDescriptions = (e) => {
        setDescriptions(e.target.value);
      };
      const handleButtonLink = (e) => {
        setButtonLink(e.target.value);
      };
      const handleHeading = (e) => {
        setHeading(e.target.value);
      };
      const handleStatus = (e) => {
        setStatus(e.target.value);
      };
      const handleimg = (e) => {
        setimg(e.target.files[0]);
        setfile(URL.createObjectURL(e.target.files[0]))
      };
const handleSubmit =async(e)=>{
    const data ={img,Heading,status,ButtonLink,Descriptions}
    axios.post('/slider',data, {
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"
            }
        }).then((res)=>{
            if(res.data.code ===200){
              toast.success('ADD Slider Successfully done')
              navigate('/app/manage-slider')
            }else{
              toast.error('Please try again later')
            }
        })
}

    return (
        <div>
           <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Add Slider</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Add Slider</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Add Slider</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Add Slider</p>
                    </div>
                </div>
            </div>
            <div className='draft-input2'>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Heading</label>
                        <input type="text" class="form-control" placeholder="Enter Heading here" value={Heading} onChange={handleHeading}/>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Button Link</label>
                        <input type="text" class="form-control" placeholder="Enter Button Link here"value={ButtonLink} onChange={handleButtonLink}/>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Banner Image</label>
                        <input type="file" class="form-control" placeholder="Enter you heading / title here"onChange={(e) => {
                  handleimg(e);}} />
                   {file &&(
                <img src={file} style={{"height":100,"width":100}} />
               )}   
                    </div>
                </div>
                <div className="">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">Status</label>
                        <select class="form-select" aria-label="Default select example" onChange={handleStatus}>
                        <option value=""selected>Status</option>
                                <option value="Active">Active</option>
                                <option value="Away">Away</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='draft-mr2'>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Descriptions</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" value={Descriptions} onChange={handleDescriptions}placeholder='Enter Descriptions here'></textarea>
                </div>

            </div>
            <div>
                <div className="draft-update-btn">
                    <button onClick={handleSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
