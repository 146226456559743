import React, { useEffect, useState } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import { ViewContactModal, ViewCoursesModal } from './ViewCoursesModal';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { ViewCourseVideoModal } from './ViewCourseVideoModal';
import Swal from 'sweetalert2';
export const ManageCourses = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    const [Course, setCourse] = useState([])
    const [lgShow, setLgShow] = useState(false);
    const [data, setdata] = useState([])
    const [fetchData, setFetchdata] = useState(true)
    const [view, setview] = useState('')
    const [viewvideo, setviewvideo] = useState('')
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    const baseurl = axios.defaults.baseURL;
    useEffect(() => {
        axios.get('/api/course/getCourse', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
            }
        }).then((res) => {
            setCourse(res.data.Data)
            console.log(res.data.Data)
        })
    }, [fetchData])
    const deleteCourse = (_id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const data = {
                    _id
        
                };
                axios.post("/api/course/deletecourse", data, {
                    headers: {
                        'x-access-token': token,
                        'Accept': 'application/json',
        
                    }
                })
                    .then(res => {
                        console.log(res);
                        toast.success('Course succesfully deleted');
                        fetchDatacall()
        
                    })
                    .catch(err => toast.error('Error in course deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };


    const viewe = (_id) => {
        const data = { _id };
        axios.post("/api/course/get_ID_Course", data).then(res => {


            localStorage.setItem(
                "course",
                JSON.stringify(res.data.data)
            );
            fetchDatacall()

        });
    };
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Course</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Course</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Course</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Course</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Cources</h6>
                    <Link to="/app/manage-course-form"><button variant="primary">Add New Course<img src={BlogAdd} /></button></Link>
                </div>
                
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Image</th>
                                <th scope="col">Course Name</th>
                                <th scope="col">Course Category</th>
                                <th scope="col">Course Fees</th>
                                {/* <th scope="col">Total Subjects</th> */}
                                <th scope="col">Time Duration</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Course.map((item, i) => (

                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td><img src={baseurl+item.img} style={{"height":100,"width":100}} /></td>
                                    <td>{item.Course_Name} </td>
                                    <td>{item.CategoryId} </td>
                                    <td>{item.Course_Fees}</td>
                                    {/* <td>3 </td> */}
                                    <td>{item.Course_Duration}</td>
                                    <td>Active</td>
                                    <td className='blog-icons'>
                                        <Link to={"/app/edit-course/"+item._id} data-toggle="tooltip" title="Edit Course Details"><img src={BlogEdit} /></Link>
                                        <Link to="#" onClick={() => {setview(item._id);setviewvideo("")}} data-toggle="tooltip" title="View Course Details"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => {setviewvideo(item.Course_Name);setview("")}} data-toggle="tooltip" title="View Course Video"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => deleteCourse(item._id)} data-toggle="tooltip" title="Delete Course"><img src={BlogDelete} /></Link>
                                    </td>

                                </tr>))}

                        </tbody>
                    </table>
                </div>
                <ToastContainer />
            </div>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {view && (
                    <ViewCoursesModal data={view} />
                )}
                   {viewvideo && (
                    <ViewCourseVideoModal data={viewvideo} />
                )}  
                </Modal.Body>
            </Modal>
        </div>
    )
}
