import { React, useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import RazorPay from "../../../src/images/razorpay.png"
import { useSelector } from "react-redux";
import { devInstance as axios } from "../../Plugin/axios";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
export const BankAccount = () => {
    const { user } = useSelector((state) => state.user);
    const[errors,seterr]=useState('')
    const token = user.token;
    const [bank_name,setbank_name]=useState("");
    const [accnt_name,setaccnt_name]=useState("");
    const [accnt_number,setaccnt_number]=useState("");
    const [ifsc,setifsc]=useState("");
    const [branch,setbranch]=useState("");
    const [swiftcode,setswiftcode]=useState("");
    const [upid,setupid]=useState("");
    const [img,setimg]=useState("");
    const [file,setfile]=useState("");
    const baseurl = axios.defaults.baseURL;
    const handleimage = (e)=>{
        setimg(e.target.files[0])
        setfile(URL.createObjectURL(e.target.files[0]))
    }
    useEffect(() => {
        axios
          .get("/getbankdetail", {
            headers: {
              "x-access-token": token,
            },
          })
          .then((res) => {
            setbank_name(res.data.data.bank_name)
            setaccnt_name(res.data.data.accnt_name)
            setaccnt_number(res.data.data.accnt_number)
            setifsc(res.data.data.ifsc)
            setbranch(res.data.data.branch)
            setswiftcode(res.data.data.swiftcode)
            setupid(res.data.data.upid)
            setfile(baseurl+res.data.data.qr_image)
          });
    
      }, []);
    const handlebankdetail=()=>{
        axios.put('/storebankdetail', {bank_name,accnt_name,accnt_number,ifsc,branch,swiftcode,upid,img  }, {
          headers: {
            'x-access-token': token,
            'Accept': 'application/json',
            "Content-Type": "multipart/form-data"

          }
        }).then((res) => {
          if(res.data.code == 400){
            seterr(res.data.message)
          }else{
            toast.success('Bank Account details successfully Updated')
          }
          
        }).catch((response)=>{
            toast.error('Please try again later')
          seterr(response.data.message)
        })
      }
    return (
        <div>
              <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Account Details</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Account Details</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Account Details</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Account Details</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button >Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
                <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
                <Link to="/app/email-api"><button>Email</button></Link>
                <Link to="/app/sms-api"><button>SMS</button></Link>
                <Link to="/app/socialmedia"><button>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button>Header & Footer</button></Link>
                <Link to="/app/storage-api"><button>Storage</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
                <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
                <Link to="/app/bank-acount"><button className='account-btn'>Bank Account Detail</button></Link>

            </div>
            <div className='payments-inputs'>  
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter Bank Name </label>
                        <input type="text" value={bank_name} class="form-control margin-top-4" placeholder="" onChange={(e)=>setbank_name(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter A/C Name</label>
                        <input type="text" class="form-control margin-top-4" placeholder="" value={accnt_name} onChange={(e)=>setaccnt_name(e.target.value)} />
                    </div>
                </div>
                

                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter Account Number</label>
                        <input type="text" class="form-control margin-top-4" placeholder="" value={accnt_number} onChange={(e)=>setaccnt_number(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter IFSC Code</label>
                        <input type="text" class="form-control margin-top-4" placeholder="" value={ifsc} onChange={(e)=>setifsc(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter Bank Branch</label>
                        <input type="text" class="form-control margin-top-4" placeholder="" value={branch} onChange={(e)=>setbranch(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter SWIFT Code</label>
                        <input type="text" class="form-control margin-top-4" placeholder="" value={swiftcode} onChange={(e)=>setswiftcode(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter UPI ID</label>
                        <input type="text" class="form-control margin-top-4" placeholder="" value={upid} onChange={(e)=>setupid(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Browse QR Image</label>
                        <input type="file" class="form-control  margin-top-4" placeholder="" onChange={handleimage} />
                        <img src={file} style={{marginTop:10,height:150,width:150}} />
                    </div>
                </div>
                <div className='update-btn' onClick={handlebankdetail} ><button>Update</button></div>
            </div>
           
            
            <ToastContainer />
        </div>
    )
}
