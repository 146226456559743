import React from 'react'

export const BackForm = () => {
    return (
        <div>
             <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Back and Restore</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Back and Restore</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Back and Restore</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Back and Restore</p>
                    </div>
                </div>
            </div>
           
            <div className='pages-tabs'>
                <div class="form-group">
                    <label for="exampleInputEmail1">Enter Backup Name</label>
                    <input type="email" class="form-control margin-top-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Backup Name" />
                </div>
                <div className='margin-top-3'>
                    <button variant="primary">Backup Now</button>
                </div>
            </div>
        </div>
    )
}
