import React,{useEffect,useState} from 'react';
export const CallbackModal = (props) => {
const [data,setData] = useState(props.data);
    const [lgShow, setLgShow] = useState(false);
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Request Id:</h6>
                    <p>#{data.callback_id}</p>
                </div>
                
                <div>
                    <h6>Student Name:</h6>
                    <p>{data.user_detail ? data.user_detail[0].first_name+" "+data.user_detail[0].last_name : "NA"}</p>
                </div>
                
                <div>
                    <h6>Mobile Number:</h6>
                    <p>{data.user_detail ? data.user_detail[0].mobile_number : "NA" }</p>
                </div>
                <div>
                    <h6>Email id:</h6>
                    <p>{data.user_detail ? data.user_detail[0].user_email : "NA"}</p>
                </div>
            </div>
            <div className='video-modal'>
                <div>
                    <h6>Subject</h6>
                    <p>{data.subject_detail ? data.subject_detail[0].name : "NA"}</p>
                </div>
                <div>
                    <h6>Detail</h6>
                    <p>{data.query}</p>
                </div>
                <div>
                    <h6>Status</h6>
                    <p>{data.status}</p>
                </div>
                
            </div>

        </div>
    )
}
