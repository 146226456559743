import React from 'react'
import { useState } from 'react'
import { devInstance as axios } from "../../Plugin/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { json,useNavigate } from 'react-router-dom';
export const AddNewTeam = () => {

    const { user } = useSelector((state) => state.user);
 const token =user.token
    const[FullName,setFullName]=useState('');
    const[status,setstatus]=useState('');
    const[Designation,setDesgination]=useState('');
    const[img,setimg]=useState('');
    const[file,setfile]=useState('');
    const[ShortDetail,setShortDetail]=useState('');
    const navigate = useNavigate();
    const handleFullName = (e) => {
        setFullName(e.target.value);
      };
      const handlestatus = (e) => {
        setstatus(e.target.value);
      };
      const handleDesgination = (e) => {
        setDesgination(e.target.value);
      };
      const handleShortDetail = (e) => {
        setShortDetail(e.target.value);
      };
      const handleimg = (e) => {
        setimg(e.target.files[0]);
        setfile(URL.createObjectURL(e.target.files[0]))
      };

    const HandelSubmit =async(e)=>{
        const data= {FullName,Designation,img,ShortDetail,status}
        axios.post('/update_ourteams',data, {
            headers: {
                'x-access-token': token,
                'Accept' : 'application/json',
                "Content-Type": "multipart/form-data"
                }
            }).then((res)=>{
          if(res.data.code ===200){
            toast.success('ADD Team Successfully done')
            navigate('/app/our-team')
          }else{
            toast.error('Please try again later')
          }
        })
    }

    return (
        <div>
             <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Add New Team</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Add New Team</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Add New Team</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Add New Team</p>
                    </div>
                </div>
            </div>
            <div className='about-section'>
                <div className="about-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Full Name</label>
                            <input type="text" class="form-control" placeholder="Enter you full name"  value={FullName} onChange={handleFullName}/>
                        </div>
                    </div>
                    <div>
                        <div class="form-group mobile-mt">
                            <label for="exampleInputEmail1">Designation </label>
                            <input type="text" class="form-control" placeholder="Enter your Designation"value={Designation} onChange={handleDesgination} />
                        </div>
                    </div>
                </div>
                <div className="about-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Image</label>
                            <input type="file" class="form-control" placeholder="Enter you heading / title here" onChange={(e) => {
                  handleimg(e);}}/>
               {file &&(
                <img src={file} style={{"height":100,"width":100}} />
               )}   
                        </div>
                    </div>
                    <div>
                        <div class="form-group status-dropdown mobile-mt">
                            <label for="exampleInputEmail1">Status </label>
                            <select class="form-select" aria-label="Default select example" onChange={handlestatus}>
                                <option value="STATUS"selected>Status</option>
                                <option value="Active">active</option>
                                <option value="Away">Away</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="about-inputs">
                    <div className="draft-mr bottom-mr">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Short Detail</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder='Enter short details here' value={ShortDetail} onChange={handleShortDetail}></textarea>
                        </div>
                    </div>
                </div>
                <div className="draft-update-btn">
                    <button onClick={HandelSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
