import {  redirect, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import React from 'react'



export default function Protected(props) {
   
  const { user } = useSelector((state) => state.user);
    const {Component} =props
    const navigate =useNavigate();

   useEffect(()=>{
  
    const userLoggedIN= user
    if( !(userLoggedIN)){
        
  
        navigate('/')
    }
    
},[])



  return (
    <div>
      
        <Component/>
    </div>
  )
}
