import { useRoutes } from "react-router-dom";

import { LoginPage } from "./Components/LoginPage/LoginPage";
import { Header } from "./Components/Header/Header";
import { ProfilePage } from "./Components/ProfilePage/ProfilePage";
import { AccountPage } from "./Components/AccountPage/AccountPage";
import { GstInvoicePage } from "./Components/GstInvoicePage/GstInvoicePage";
import { PaymentPage } from "./Components/PaymentPage/PaymentPage";
import { EmailApiPage } from "./Components/EmailApiPage/EmailApiPage";
import { SmsApiPage } from "./Components/SmsApiPage/SmsApiPage";
import { BlogPage } from "./Components/BlogPage/BlogPage";
import { FaqPage } from "./Components/FaqPage/FaqPage";
import { AddNewBlog } from "./Components/BlogPage/AddNewBlog";
import { AboutUsPage } from "./Components/AboutUsPage/AboutUsPage";
import { OurTeamPage } from "./Components/OurTeamPage/OurTeamPage";
import { AddNewTeam } from "./Components/OurTeamPage/AddNewTeam";
import { TermsCondition } from "./Components/PolicyPages/TermsCondition";
import { PrivacyPolicy } from "./Components/PolicyPages/PrivacyPolicy";
import { CookiesPolicy } from "./Components/PolicyPages/CookiesPolicy";
import { RefundPolicy } from "./Components/PolicyPages/RefundPolicy";
import { ContactUs } from "./Components/ContactUs/ContactUs";
import { ManageCourse } from "./Components/CategoryPage/ManageCourse";
import { ManageVideo } from "./Components/VideoPage/ManageVideo";

import { AddCategory } from "./Components/CategoryPage/AddCategory";
import { VideoForm } from "./Components/VideoPage/VideoForm";
import { EditVideo } from "./Components/VideoPage/EditVideo";
import { ManageCourses } from "./Components/Courses/ManageCourses";
import { ManageCoursesForm } from "./Components/Courses/ManageCoursesForm";
import { Sledding } from "@mui/icons-material";
import { SliderPage } from "./Components/SliderPage/SliderPage";
import { AddSlider } from "./Components/SliderPage/AddSlider";
import { SocialMedia } from "./Components/SocialMedia/SocialMedia";
import { HeaderAndFooter } from "./Components/HeaderAndFooter/HeaderAndFooter";
import { ManageHome } from "./Components/HomePage/ManageHome";
import { ManageHomeForm } from "./Components/HomePage/ManageHomeForm";
import { ErrorPage } from "./Components/ErrorPage/ErrorPage";
import Protected from './Proteceted';
import { ForgetPassword } from "./Components/LoginPage/ForgetPassword";
import { ChangePassword } from "./Components/LoginPage/ChangePassword";
import { StorageApi } from "./Components/StorageApi/StorageApi";
import { FirebaseApi } from "./Components/StorageApi/FirebaseApi";
import { ManageUser } from "./Components/ManageUser/ManageUser";
import { ViewUser } from "./Components/ManageUser/ViewUser";
import { ViewOrder } from "./Components/ManageUser/ViewOrder";
import { Invoice } from "./Components/ManageUser/Invoice";
import { UserAnalytics } from "./Components/ManageUser/UserAnalytics";
import { UserChangePassword } from "./Components/ManageUser/UserChangePassword";
import { ManageOrder } from "./Components/ManageUser/ManageOrder";
import { ManageTransaction } from "./Components/ManageUser/ManageTransaction";
import { ManageStudentReview } from "./Components/ManageUser/ManageStudentReview";
import { RefundCancelRequest } from "./Components/ManageUser/RefundCancelRequest";
import { Dashboard } from "./Components/ManageUser/Dashboard";
import { ContactUsForm } from "./Components/ContactUs/ContactUsForm";
import { Widgets2 } from "./Components/HomePage/components/widgets/Widgets2";
import { Widgets3 } from "./Components/HomePage/components/widgets/Widgets3";
import { Widgets4 } from "./Components/HomePage/components/widgets/Widgets4";
import { Widgets5 } from "./Components/HomePage/components/widgets/Widgets5";
import { Widgets6 } from "./Components/HomePage/components/widgets/Widgets6";
import { Widgets7 } from "./Components/HomePage/components/widgets/Widgets7";
import { Widgets8 } from "./Components/HomePage/components/widgets/Widgets8";
import { Widgets9 } from "./Components/HomePage/components/widgets/Widgets9";
import { Widgets10 } from "./Components/HomePage/components/widgets/Widgets10";
import { Widgets11 } from "./Components/HomePage/components/widgets/Widgets11";
import { Widgets12 } from "./Components/HomePage/components/widgets/Widgets12";
import { EditWidgets2 } from "./Components/HomePage/components/EditWidgets/EditWidgets2";
import { EditWidgets3 } from "./Components/HomePage/components/EditWidgets/EditWidgets3";
import { EditWidgets4 } from "./Components/HomePage/components/EditWidgets/EditWidgets4";
import { EditWidgets5 } from "./Components/HomePage/components/EditWidgets/EditWidgets5";
import { EditWidgets6 } from "./Components/HomePage/components/EditWidgets/EditWidgets6";
import { EditWidgets7 } from "./Components/HomePage/components/EditWidgets/EditWidgets7";
import { EditWidgets8 } from "./Components/HomePage/components/EditWidgets/EditWidgets8";
import { EditWidgets9 } from "./Components/HomePage/components/EditWidgets/EditWidgets9";
import { EditWidgets10 } from "./Components/HomePage/components/EditWidgets/EditWidgets10";
import { EditWidgets11 } from "./Components/HomePage/components/EditWidgets/EditWidgets11";
import { EditWidgets12 } from "./Components/HomePage/components/EditWidgets/EditWidgets12";
import { EditCatagory } from "./Components/CategoryPage/EditCatagory"
import { BackUp } from "./Components/Backup/BackUp";
import { BackForm } from "./Components/Backup/BackForm";
import { EditTeam } from "./Components/OurTeamPage/EditTeam";
import { EditSlider } from "./Components/SliderPage/EditSlider";
import { EditContactUs } from "./Components/ContactUs/EditContactUs";
import { EditBlog } from "./Components/BlogPage/EditBlog";
import { EditCourse } from "./Components/Courses/EditCourse";
import { BankAccount } from "./Components/BankAccount/BankAccount";
import { Ticket } from "./Components/Support/Ticket/Ticket";
import { Callback } from "./Components/Support/Callback/Callback";
import { QuerySubject } from "./Components/Support/QuerySubject/QuerySubject";
import ContactUsEnquiry from "./Components/Enquiry/ContactUsEnquiry";
import AdmissionEnquiry from "./Components/Enquiry/AdmissionEnquiry";
import NewsLetterEnquiry from "./Components/Enquiry/NewsLetterEnquiry";
import EditUser from "./Components/ManageUser/EditUser";



function Routers()

{
    return useRoutes([



        {
            path: '/',
            element: < LoginPage / >
        },
        {
            path: '/forget-password',
            element: < ForgetPassword / >
        },
        {
            path: '*',
            element: < ErrorPage / >
        },
        {
            path: "/change-password/:token",
            element: < ChangePassword / >
        },

        {
            path: '/app/',
            element: < Protected Component = { Header }
            />,

            children: [

                {
                    path: 'account',
                    element: < AccountPage / > ,
                },
                {
                    path: 'blogPage',
                    element: < BlogPage / > ,
                },
                {
                    path: 'callback-page',
                    element: < Callback / > ,
                },
                {
                    path: 'querysubject-page',
                    element: < QuerySubject / > ,
                },

                {
                    path: 'ticket-page',
                    element: < Ticket / > ,
                },
                {
                    path: 'contactus-enquiry',
                    element: < ContactUsEnquiry / > ,
                },
                {
                    path: 'admission-enquiry',
                    element: < AdmissionEnquiry / > ,
                },
                {
                    path: 'newsletter-enquiry',
                    element: < NewsLetterEnquiry / > ,
                },
                {
                    path: 'edit-blog/:slug',
                    element: < EditBlog / > ,
                },
                {
                    path: 'profile',
                    element: < ProfilePage / > ,
                },
                {
                    path: 'gst-invoice',
                    element: < GstInvoicePage / > ,
                },
                {
                    path: 'invoice/:link',
                    element: < Invoice / > ,
                },
                {
                    path: 'payment-gateway',
                    element: < PaymentPage / > ,
                },
                {
                    path: 'contact-us',
                    element: < ContactUs / > ,
                }, {
                    path: 'edit-contact-us/:slug',
                    element: < EditContactUs / > ,
                }, {
                    path: 'edit-user/:link',
                    element: < EditUser / > ,
                },
                {
                    path: 'about',
                    element: < AboutUsPage / > ,
                },
                {
                    path: 'refund-policy',
                    element: < RefundPolicy / > ,
                },
                {
                    path: 'cookies-policy',
                    element: < CookiesPolicy / > ,
                },
                {
                    path: 'contact-us-form',
                    element: < ContactUsForm / > ,
                },
                {
                    path: 'course-category',
                    element: < ManageCourse / >
                },
                {
                    path: 'add-category',
                    element: < AddCategory / >
                },
                {
                    path: 'our-team',
                    element: < OurTeamPage / >
                },
                {
                    path: 'add-team',
                    element: < AddNewTeam / >
                },
                {
                    path: "edit-team/:slug",
                    element: < EditTeam / >
                },
                {
                    path: 'terms-condition',
                    element: < TermsCondition / >
                },
                {
                    path: 'privacy-policy',
                    element: < PrivacyPolicy / >
                },
                {
                    path: 'sms-api',
                    element: < SmsApiPage / >
                },
                {
                    path: 'email-api',
                    element: < EmailApiPage / >
                },
                {
                    path: 'dashboard',
                    element: < Dashboard / >
                },
                {
                    path: 'faq',
                    element: < FaqPage / >
                },
                {
                    path: 'manage-video',
                    element: < ManageVideo / >
                },
                {
                    path: 'edit-video/:link',
                    element: < EditVideo / >
                },
                {
                    path: 'blog',
                    element: < AddNewBlog / >
                },
                {
                    path: 'video-form',
                    element: < VideoForm / >
                },
                {
                    path: 'headerandfooter',
                    element: < HeaderAndFooter / >
                },
                {
                    path: 'socialmedia',
                    element: < SocialMedia / >
                },
                {
                    path: 'storage-api',
                    element: < StorageApi / >
                },
                {
                    path: 'firebase-api',
                    element: < FirebaseApi / >
                },
                {
                    path: 'manage-course',
                    element: < ManageCourses / >
                },
                {
                    path: 'manage-course-form',
                    element: < ManageCoursesForm / >
                },
                {
                    path: 'edit-course/:slug',
                    element: < EditCourse / >
                },
                {
                    path: 'manage-user',
                    element: < ManageUser / >
                },
                {
                    path: 'view-user/:link',
                    element: < ViewUser / >
                },
                {
                    path: 'user-analytics/:link',
                    element: < UserAnalytics / >
                },
                {
                    path: 'user-change-password/:link',
                    element: < UserChangePassword / >
                },
                {
                    path: 'bank-acount',
                    element: < BankAccount / >
                },
                {
                    path: 'manage-order',
                    element: < ManageOrder / >
                },
                {
                    path: 'view-order/:link',
                    element: < ViewOrder / >
                },
                {
                    path: 'manage-transaction',
                    element: < ManageTransaction / >
                }, {
                    path: 'student-review',
                    element: < ManageStudentReview / >
                },

                {
                    path: 'refund-cancel-request',
                    element: < RefundCancelRequest / >
                },
                {
                    path: 'manage-slider',
                    element: < SliderPage / >
                },
                {
                    path: 'contact-us-form',
                    element: < ContactUsForm / >
                },
                {
                    path: 'add-slider',
                    element: < AddSlider / >
                }, {
                    path: 'edit-slider/:slug',
                    element: < EditSlider / >
                }, {
                    path: 'home-widget',
                    element: < ManageHome / >
                },
                {
                    path: 'backup-restore',
                    element: < BackUp / >
                },
                {
                    path: 'backup-restore-form',
                    element: < BackForm / >
                },
                {
                    path: 'edit-home-widget',
                    element: < ManageHomeForm / >
                },
                {
                    path: 'add-team',
                    element: < AddNewTeam / >
                },
                {
                    path: 'home-widget2',
                    element: < Widgets2 / >
                },
                {
                    path: 'home-widget3',
                    element: < Widgets3 / >
                },
                {
                    path: 'home-widget4',
                    element: < Widgets4 / >
                },
                {
                    path: 'home-widget5',
                    element: < Widgets5 / >
                },
                {
                    path: 'home-widget6',
                    element: < Widgets6 / >
                },
                {
                    path: 'home-widget7',
                    element: < Widgets7 / >
                },

                {
                    path: 'home-widget8',
                    element: < Widgets8 / >
                },
                {
                    path: 'home-widget9',
                    element: < Widgets9 / >
                },
                {
                    path: 'home-widget10',
                    element: < Widgets10 / >
                },
                {
                    path: 'home-widget11',
                    element: < Widgets11 / >
                },
                {
                    path: 'home-widget12',
                    element: < Widgets12 / >
                },
                {
                    path: 'edit-home-widget2',
                    element: < EditWidgets2 / >
                },
                {
                    path: 'edit-home-widget3',
                    element: < EditWidgets3 / >
                },
                {
                    path: 'edit-home-widget4',
                    element: < EditWidgets4 / >
                },
                {
                    path: 'edit-home-widget5',
                    element: < EditWidgets5 / >
                },
                {
                    path: 'edit-home-widget6',
                    element: < EditWidgets6 / >
                }, {
                    path: 'edit-home-widget6',
                    element: < EditWidgets6 / >
                }, {
                    path: 'edit-home-widget7',
                    element: < EditWidgets7 / >
                }, {
                    path: 'edit-home-widget8',
                    element: < EditWidgets8 / >
                }, {
                    path: 'edit-home-widget9',
                    element: < EditWidgets9 / >
                }, {
                    path: 'edit-home-widget10',
                    element: < EditWidgets10 / >
                }, {
                    path: 'edit-home-widget11',
                    element: < EditWidgets11 / >
                }, {
                    path: 'edit-home-widget12',
                    element: < EditWidgets12 / >
                },
                {
                    path: 'video-form',
                    element: < VideoForm / >
                },
                {
                    path: 'edit-category/:slug',
                    element: < EditCatagory / >
                },
            ]
        }




    ])
}

export default Routers;