import React from 'react'
import "./errorPage.css";

export const ErrorPage = () => {
  return (
    <div className='text-center error-page'>
       <img src="../icons/404-error.png" width="25%"/>
        <h2>Page Not Found</h2>
        <p>The Page you are looking for doesn't exist or an other error occured.</p>
        <div className='text-center'>
        <button>Go to Home Page</button>
        </div>

    </div>
  )
}
