import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { BottomHeader } from './BottomHeader'
import "./header.css";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logout } from "../../redux/UserSlice";
import { devInstance as axios } from "../../Plugin/axios";
import Nav from 'react-bootstrap/Nav';
import ToggleMenu from "../../../src/images/mobile-toggle.png"
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Row, Col, Container } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "react-bootstrap-submenu/dist/index.css";
import Accordion from 'react-bootstrap/Accordion';

export const Header = (name, ...props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { user } = useSelector((state) => state.user);
    console.log(user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setvalue] = useState('');
    const [logo, setlogo] = useState([])
    const [fetchData, setFetchdata] = useState(true)
    const token = user.token;
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    const baseurl = axios.defaults.baseURL;
    const handellogout = async (e) => {
        try {
            e.preventDefault(e);
            dispatch(logout());
            navigate("/");

        } catch (error) {
            console.log(error)
        }
    }
    const location = useLocation();
    useEffect(() => {
        axios.get('/get'
        ).then((res) => {
            setlogo(res.data.data.logo)
        })

    }, [])

    useEffect(() => {
        axios.get('/getadmin', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',

            }
        }).then((res) => {
            if (res.data.code == "400") {
                dispatch(logout());
                navigate('/')
            }
            setvalue(res.data)
        })
    }, [location])
    return (
        <>
            <div className='container-fluid header-bg'>
                <div className='header hide-in-desktop'>
                    <div>
                        <div className='header-icons'>
                            <img src="../images/email.svg" />
                            <img src="../images/msg.svg" />
                            <img src="../images/question.svg" />
                        </div>
                    </div>
                    <div className='header-img'>
                        <img src={baseurl + logo} />
                    </div>
                    <div className='user-details d-flex justify-content-end'>
                        {/* <img src="../images/bell.svg" width="4%" /> */}
                        <img onClick={()=>navigate("/app/account")} src="../images/setting.svg" width="4%" />
                        <div className='user-mt'>
                            <h6>{value.ad_name}</h6>
                            <p>{value.roles}</p>
                        </div>
                        <li>
                            <a href="#"><img src={`${baseurl + value.img}`} style={{ borderRadius: "50%", width: "30px", merginTop: "2rem", height: "3rem" }} className='user-image' /></a>
                            <ul class="dropdown">
                                <li><Link to="/app/profile"><img src="../images/user-icon.svg" />Profile</Link></li>
                                <li><Link to="/app/account"><img src="../images/setting.svg" width="13%" />Settings</Link></li>
                                <li><Link to="/app/faq"><img src="../images/question.svg" width="16%" />FAQs</Link></li>
                                <li><a onClick={handellogout} href="#"><img src="../images/logout.svg" />Logout</a></li>
                            </ul>
                        </li>
                    </div>
                </div>
                {/* Mobile Header */}

                <div className='hide-in-mobile'>
                    <div className='mobile-header' style={{ padding: "10px" }}>
                        <div>
                            <Button variant="primary" onClick={handleShow} className="me-2 mobile-hamburger-btn">
                                <img src="../images/burger-menu.png" width="35px" />
                            </Button>
                            <Offcanvas show={show} onHide={handleClose} {...props}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Menu</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Dashboard</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/dashboard"><img src="../icons/lms-dashboard.svg" />LMS Dashboard</Link></li>
                                                    <li><Link to="/#"><img src="../icons/analatics-dashboard.svg" />Analytics Dashboard</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Courses</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/course-category"><img src="../images/user-icon.svg" />Course Category</Link></li>
                                                    <li><Link to="/app/manage-course"><img src="../images/study-icon.svg" width="7%" />Courses</Link></li>
                                                    <li><Link to="/app/manage-video"><img src="../icons/video-learning.svg" width="8%" />Video Tutorials</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Users & Orders</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/manage-user"><img src="../images/user-icon.svg" />Manage Users</Link></li>
                                                    <li><Link to="/app/manage-order"><img src="../icons/order.svg" width="7%" />Manage Orders</Link></li>
                                                    <li><Link to="/app/manage-transaction"><img src="../icons/manage-refund.svg" width="7%" />Manage Transactions</Link></li>
                                                    {/* <li><Link to="/app/student-review"><img src="../icons/review.svg" width="7%" />Manage Reviews</Link></li> */}
                                                    <li><Link to="/app/refund-cancel-request"><img src="../icons/manage-refund.svg" width="7%" />Refunds & Cancellations Request</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Company Profile</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/home-widget"><img src="../images/home-icon.svg" />Home Page Widgets</Link></li>
                                                    <li><Link to="/app/about"><img src="../icons/about-us.png" width="7%" />About Us</Link></li>
                                                    <li><Link to="/app/our-team"><img src="../icons/admissionenq.svg" width="7%" />Our Team</Link></li>
                                                    <li><Link to="/app/manage-slider"><img src="../icons/manage-slider.png" width="7%" />Manage Slider</Link></li>
                                                    <li><Link to="/app/contact-us"><img src="../icons/support.svg" width="7%" />Contact Us</Link></li>
                                                    <li><Link to="/app/terms-condition"><img src="../icons/manage-user.svg" width="7%" />Terms & Conditions</Link></li>
                                                    <li><Link to="/app/privacy-policy"><img src="../icons/policy.svg" width="7%" />Privacy Policy</Link></li>
                                                    <li><Link to="/app/refund-policy"><img src="../icons/manage-refund.svg" width="7%" />Refunds & Cancellation Policy</Link></li>
                                                    <li><Link to="/app/cookies-policy"><img src="../icons/cookies.svg" width="7%" />Cookies Policy</Link></li>
                                                    <li><Link to="/app/#"><img src="../icons/tr.svg" width="7%" />Career</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Blog</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/blogPage"><img src="../icons/manage-blog.svg" width="7%" />Manage Blogs</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Help & Support</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/querysubject-page"><img src="../icons/admissionenq.svg" width="7%" />Manage Query Subjects</Link></li>
                                                    <li><Link to="/app/callback-page"><img src="../icons/admissionenq.svg" width="7%" />Manage Call Backs</Link></li>
                                                    <li><Link to="/app/ticket-page"><img src="../icons/admissionenq.svg" width="7%" />Manage Tickets</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>News Center</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/course-category"><img src="../images/user-icon.svg" />Course Category</Link></li>
                                                    <li><Link to="/app/manage-course"><img src="../images/study-icon.svg" width="7%" />Courses</Link></li>
                                                    <li><Link to="/app/manage-video"><img src="../icons/video-learning.svg" width="8%" />Video Tutorials</Link></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>Enquiries</Accordion.Header>
                                            <Accordion.Body>
                                                <ul class="dropdown1">
                                                    <li><Link to="/app/#"><img src="../icons/admissionenq.svg" width="7%" />Manage Admission Inquiries</Link></li>
                                                    <li><Link to="/app/#"><img src="../icons/manage-user.svg" width="7%" />Contact Us Inquiries</Link></li>
                                                    <li><a href="#"><img src="../icons/subscribe.svg" width="7%" />Newsletter Subscriptions</a></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>SEO Settings</Accordion.Header>
                                            <Accordion.Body>
                                                {/* <ul class="dropdown1">
                                                    <li><Link to="/app/course-category"><img src="../images/user-icon.svg" />Course Category</Link></li>
                                                    <li><Link to="/app/manage-course"><img src="../images/study-icon.svg" width="7%" />Courses</Link></li>
                                                    <li><Link to="/app/manage-video"><img src="../icons/video-learning.svg" width="8%" />Video Tutorials</Link></li>
                                                </ul> */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                        <div className='user-details d-flex justify-content-end'>
                            {/* <img src="../images/bell.svg" width="4%" />
                            <img src="../images/setting.svg" width="4%" /> */}
                            <div className='user-mt'>
                                <h6>{value.ad_name}</h6>
                                <p>{value.roles}</p>
                            </div>
                            <li>
                                <img src={`${baseurl + value.img}`} style={{ borderRadius: "50%", width: "30px", merginTop: "2rem", height: "3rem" }} className='user-image' />
                                <ul class="dropdown">
                                    <li><Link to="/app/profile"><img src="../images/user-icon.svg" />Profile</Link></li>
                                    <li><Link to="/app/account"><img src="../images/setting.svg" width="13%" />Settings</Link></li>
                                    <li><Link to="/app/faq"><img src="../images/question.svg" width="16%" />FAQs</Link></li>
                                    <li><a onClick={handellogout} href="#"><img src="../images/logout.svg" />Logout</a></li>
                                </ul>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
            <BottomHeader />
            <Outlet />
        </>
    )
}
