import React,{useEffect,useState} from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import Download from "../../../src/images/sm-download.svg";
import { Link } from 'react-router-dom';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
export const ManageOrder = () => {
    const [data,setData]=useState([]);
    const { user } = useSelector((state) => state.user);
    const [page, setpage] = useState();
    const baseurl = axios.defaults.baseURL;
    const token = user.token
    useEffect(()=>{
        fetchdata();
    },[])
    const deleteorder = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.get('/deleteorder/'+id,{
                    headers: {
                        'x-access-token': token
                    }}).then(res => {
                        fetchdata()
                        toast.success('Order succesfully deleted');
                    })
                    .catch(err => toast.error('Error in Blog  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    const pageclick = (data)=>{
        let currentPage = data.selected;
        axios.get('/getallorder?page='+currentPage+'&size=15',{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            setData(res.data.data.docs);
            setpage(res.data.data.totalPages);
        });
    }

    const fetchdata = ()=>{
        
        axios.get('/getallorder?page=0&size=15',{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            setData(res.data.data.docs);
            setpage(res.data.data.totalPages);
        });
    }
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Order</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Order</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Order</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Order</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table table-scroll'>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Order ID</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Course Category</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Billed Amount</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Order Status </th>
                            <th scope="col" className='table-head-right'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item,i)=>(
                            <tr key={i}>
                            <td>{item.createdAt}</td>
                            <td>#{item.order_id}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.category_name}</td>
                            <td>{item.course_name}</td>
                            <td>${item.order_amount_with_gst}</td>
                            <td>{item.payment_status}</td>
                            <td>#{item.trns_id}</td>
                            <td>{item.order_status}</td>
                            <td className='blog-icons'>
                                <Link to={"/app/view-order/"+item._id} data-toggle="tooltip" title="View Order"><img src={BlogEye} /></Link>
                                <a href={baseurl+item.invoice_url} data-toggle="tooltip" title="Download Invoice"><img src={Download} /></a>
                                <Link to="#" onClick={()=>deleteorder(item._id)} data-toggle="tooltip" title="Delete Order"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                        ))}
                        
                       
                    </tbody>
                    <div className='mt-3'>
                    <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={pageclick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
                    </div>
                    
                </table>
                
            </div>
        </div>
    )
}
