import React,{useEffect,useState} from 'react';
import { Form,Button } from 'react-bootstrap';
import { devInstance as axios } from "../../../Plugin/axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
export const TicketModal = (props) => {
const { user } = useSelector((state) => state.user);
const token = user.token;
const [data,setData] = useState(props.data);
const baseurl = axios.defaults.baseURL

const [lgShow, setLgShow] = useState(false);

    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Ticket Id:</h6>
                    <p>#{data.ticket_id}</p>
                </div>
                
                <div>
                    <h6>Student Name:</h6>
                    <p>{data.user_detail ? data.user_detail[0].first_name+" "+data.user_detail[0].last_name : "NA"}</p>
                </div>
                
                <div>
                    <h6>Mobile Number:</h6>
                    <p>{data.user_detail ? data.user_detail[0].mobile_number : "NA" }</p>
                </div>
                <div>
                    <h6>Email id:</h6>
                    <p>{data.user_detail ? data.user_detail[0].user_email : "NA"}</p>
                </div>
            </div>
            <div className='video-modal'>
                <div>
                    <h6>Subject</h6>
                    <p>{data.subject_detail ? data.subject_detail[0].name : "NA"}</p>
                </div>
                <div>
                    <h6>Detail</h6>
                    <p>{data.query}</p>
                </div>
                <div>
                    <h6>Status</h6>
                    <p>{data.status}</p>
                </div>
                <div>
                    <img height={200} src={baseurl+data.img} />
                </div>
                
            </div>
            {data.status == "open" && (
                <div className="video-modal">
            <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Remark</Form.Label>
                <Form.Control
                    type="Text"
                    placeholder="Enter Remark"
                    autoFocus
                    value={props.remark}
                    onChange={props.handleremark}
                />
                {props.error && props.error.remark && (
                    <div className="text-danger">{props.error.remark.msg}</div>
                )}
            </Form.Group>
                                
                                
            <div className='submit-btn'>
                <Button onClick={()=>props.handleSubmit()}>Submit</Button>
            </div>
        </Form>
            </div>
            )}
            

        </div>
    )
}
