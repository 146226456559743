import React,{useEffect,useState} from 'react';
import { devInstance as axios } from '../../Plugin/axios';
export const CategoryModal = (props) => {
const [data,setData] = useState('');
const baseurl=axios.defaults.baseURL;
useEffect(()=>{
    const data = { '_id':props.data }
    axios.post("/api/category/get_ID_Category", data).then(res => {
        setData(res.data.data)

    });
  },[])
    
   

    const [lgShow, setLgShow] = useState(false);
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Category:</h6>
                    <p>{data.Cat_Category_Name}</p>
                </div>
                <div>
                    <h6>Category Icon:</h6>
                    <p><img src={baseurl+data.Cat_Img} /></p>
                </div>
                <div>
                    <h6>Status:</h6>
                    <p>{data.Cat_Status}</p>
                </div>
                <div>
                    <h6>SLUG:</h6>
                    <p>{data.Cat_URL_Slug}</p>
                </div>
            </div>
            <div className='video-modal'>
                <div>
                    <h6>Canonical URL</h6>
                    <p>{data.Cat_Canonical}</p>
                </div>
                <div>
                    <h6>Twitter Crads</h6>
                    <p>{data.Cat_Twitter_Cards}</p>
                </div>
                <div>
                    <h6>OG Tags</h6>
                    <p>{data.Cat_OG_Tag}</p>
                </div>
                <div>
                    <h6>premium</h6>
                    <p>{data.is_Premium=="1" ? "Yes" : "No"}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Short Description: </h6>
                <p>{data.Cat_Short_Description}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Title : </h6>
                <p>{data.
Cat_Meta_Title
}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Keywords: </h6>
                <p>{data.
Cat_Meta_Keyword
}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Description: </h6>
                <p>{data.
Cat_Meta_Description
}</p>
            </div>

        </div>
    )
}
