import React from 'react';
import SuccessFull from "../../../src/images/purple-tick.svg";
import Expired from "../../../src/images/grey-tick.svg";
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import Star from "../../../src/images/star.svg";
import { Link } from 'react-router-dom';

export const ManageStudentReview = () => {
    return (
        <div>
           <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Student Review</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Student Review</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Student Review</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Student Review</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table table-scroll'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Student Review</h6>
                    {/* <Link to="/add-home-widget"><button variant="primary">Add <img src={BlogAdd} /></button></Link> */}
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Order ID</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Star Rating</th>
                            <th scope="col">Feedback</th>
                            <th scope="col">Status</th>
                            <th scope="col" className='table-head-right'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Date & Time</td>
                            <td>OID#47744</td>
                            <td>John</td>
                            <td>john@gmail.com</td>
                            <td>+91000999992</td>
                            <td>Course Name</td>
                            <td><img src={Star} className="rating-star"/>4.4</td>
                            <td>Lorem ipsum dolor sit amet, conse c elit. conse ctetur adipiscing elit.</td>
                            <td>Success</td>
                            <td className='blog-icons'>
                                <Link to="#" data-toggle="tooltip" title="Success"> <img src={SuccessFull} className="success-trans"/></Link>
                                <Link to="#" data-toggle="tooltip" title="Delete Review"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Date & Time</td>
                            <td>OID#47744</td>
                            <td>John</td>
                            <td>john@gmail.com</td>
                            <td>+91000999992</td>
                            <td>Course Name</td>
                            <td><img src={Star} className="rating-star"/>4.4</td>
                            <td>Lorem ipsum dolor sit amet, conse c elit. conse ctetur adipiscing elit.</td>
                            <td>Success</td>
                            <td className='blog-icons '>
                                <Link to="#" data-toggle="tooltip" title="Expired"> <img src={Expired} className="success-trans"/></Link>
                                <Link to="#" data-toggle="tooltip" title="Delete Review"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Date & Time</td>
                            <td>OID#47744</td>
                            <td>John</td>
                            <td>john@gmail.com</td>
                            <td>+91000999992</td>
                            <td>Course Name</td>
                            <td><img src={Star} className="rating-star"/>4.4</td>
                            <td>Lorem ipsum dolor sit amet, conse c elit. conse ctetur adipiscing elit.</td>
                            <td>Success</td>
                            <td className='blog-icons '>
                                <Link to="#" data-toggle="tooltip" title="Success"> <img src={SuccessFull} className="success-trans"/></Link>
                                <Link to="#" data-toggle="tooltip" title="Delete Review"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}
