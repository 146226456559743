import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import PublishReview from "../../../src/images/pub-review.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import Comment from "../../../src/images/comment.png"
import Rating from "../../../src/images/rating.png";
import Download from "../../../src/images/download-icon.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { devInstance as axios } from '../../Plugin/axios';
import 'react-tooltip/dist/react-tooltip.css'
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
export const Dashboard = () => {
    const [userdata, setuserdata] = useState([]);
    const [transcdata, settranscdata] = useState([]);
    const [feedback, setfeedback] = useState([]);
    const [data, setdata] = useState("");
    const [userbtn, setuserbtn] = useState(0);
    const [transcbtn, settranscbtn] = useState(0);
    const [feedbackbtn, setfeedbackbtn] = useState(0);
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    useEffect(()=>{
        fetchdetail(0);
        fetchtranscdetail(0);
        fetchgetrecentfeedback(0);
        fetchdashboard();
    },[]);
    
    const fetchdashboard=()=>{
        axios.get('/getdashboard',{
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                // "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setdata(res.data)
            // setuserdata(res.data.data)
        })
    }
    const fetchdetail=(day)=>{
        axios.post('/getrecentuser',{day:day},{
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                // "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setuserdata(res.data.data)
        })
    }
    const fetchgetrecentfeedback=(day)=>{
        axios.post('/getrecentfeedback',{day:day},{
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                // "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setfeedback(res.data.data)
        })
    }
    const fetchtranscdetail=(day)=>{
        axios.post('/getalltransaction',{day:day},{
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                // "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            settranscdata(res.data.data)
        })
    }
    return (
        <>
        <div className='dashboard-section'>
            {/* <ReactTooltip
                anchorId="app-title"
                place="bottom"
                content="Hello world! I'm a"
            />
             <ReactTooltip
                anchorId="app-title1"
                place="bottom"
                content="Hello Anas"
            /> */}
            <h1>Welcome Admin</h1>
            {/* <p>All System are running smoothly! You have <span>3 unread alerts!</span></p> */}
            <div className='dashboard-card'>
                <div className='dashboard-bg-img'>
                    <p>Lucknow</p>
                </div>
                <div className='dashboard-cards'>
                    <div>
                        <div className='students-card'>
                            <h1>Total Students</h1>
                            <p>{data.user}</p>
                            <p>(Active)</p>
                        </div>
                        <div className='courses-card'>
                            <h1>Total Order</h1>
                            <p>{data.order}</p>
                            <p>(Last 30 days)</p>
                        </div>
                    </div>
                    <div>
                        <div className='order-card'>
                            <h1>Total Courses</h1>
                            <p>{data.course}</p>
                            <p>(Active)</p>
                        </div>
                        <div className="transaction-card">
                            <h1>Total Transaction</h1>
                            <p>{data.transaction}</p>
                            <p>(Last 30 days)</p>
                        </div>
                    </div>
                </div>
            </div>
            <h2>Users and orders</h2>
            <div className='dashboard-card'>
                <div className='dasboard-table dashboard-mr'>
                    <div className='recent-user'>
                        <h4>Recent User</h4>
                        <div>
                            <button  onClick={()=>{fetchdetail(-1);setuserbtn(0)}} className={userbtn == 0 ? 'purple-btn' : ""}>Today</button>
                            <button onClick={()=>{fetchdetail(7);setuserbtn(7)}} className={userbtn == 7 ? 'purple-btn' : ""}>7 Days</button>
                            <button onClick={()=>{fetchdetail(15);setuserbtn(15)}} className={userbtn == 15 ? 'purple-btn' : ""}>15 Days</button>
                            <button onClick={()=>{fetchdetail(30);setuserbtn(30)}} className={userbtn == 30 ? 'purple-btn' : ""}>30 Days</button>
                        </div>
                    </div>
                    <div className='admin-table table-scroll'>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Email ID</th>
                                    <th scope="col">Mobile Number</th>
                                    <th scope="col">Course Opted</th>
                                    <th scope="col" className='table-head-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userdata && userdata.map((item,i)=>(
                                    <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td>{item.first_name+" "+item.last_name}</td>
                                    <td>{item.gender}</td>
                                    <td>{item.user_email}</td>
                                    <td>{item.mobile_number}</td>
                                    <td>{item.course_category_id}</td>
                                    <td className='blog-icons'>
                                        <Link to={"/app/view-user/"+item._id} data-toggle="tooltip" title="View User Detail"> <img src={BlogEye} /></Link>
                                        {/* <Link to="#" data-toggle="tooltip" title="View order Detail"> <img src={BlogEye} /></Link> */}
                                    </td>
                                </tr>
                                ))}
                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='dasboard-table dashboard-mr'>
                    <div className='recent-user'>
                        <h4>Recent Transactions</h4>
                        <div>
                            <button onClick={()=>{fetchtranscdetail(-1);settranscbtn(0)}} className={transcbtn == 0 ? 'purple-btn' : ""}>Today</button>
                            <button onClick={()=>{fetchtranscdetail(7);settranscbtn(7)}} className={transcbtn == 7 ? 'purple-btn' : ""}>7 Days</button>
                            <button onClick={()=>{fetchtranscdetail(15);settranscbtn(15)}} className={transcbtn == 15 ? 'purple-btn' : ""}>15 Days</button>
                            <button onClick={()=>{fetchtranscdetail(30);settranscbtn(30)}} className={transcbtn == 30 ? 'purple-btn' : ""}>30 Days</button>
                        </div>
                    </div>
                    <div className='admin-table table-scroll'>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Email ID</th>
                                    <th scope="col">Mobile Number</th>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col" className='table-head-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transcdata && transcdata.map((item,i)=>(
                                    <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile_number}</td>
                                    <td>#{item.order_id}</td>
                                    <td>{item.payment_status}</td>
                                    <td>#{item.trns_id}</td>
                                    <td className='blog-icons'>
                                        <Link to={"/app/view-user/"+item.user_id} data-toggle="tooltip" title="View User Detail"> <img src={BlogEye} /></Link>
                                        <Link to={"/app/invoice/"+item._id} data-toggle="tooltip" title="Download Invoice"><img src={Download} width="15px"/></Link>
                                        <Link to={"/app/view-order/"+item._id} data-toggle="tooltip" title="View Order Detail"><img src={BlogEye} /></Link>
                                        
                                    </td>
                                </tr>
                                ))}
                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <h2>Feedback & Suggestions</h2>
            <div className='dashboard-card'>
                {/* <div className='dasboard-table dashboard-mr'>
                    <div className='recent-user'>
                        <h4>Recent Rating & Reviews</h4>
                        <div>
                            <button className='purple-btn'>Today</button>
                            <button>7 Days</button>
                            <button>15 Days</button>
                            <button>30 Days</button>
                        </div>
                    </div>
                    <div className='admin-table table-scroll'>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Course Name</th>
                                    <th scope="col">Rating</th>
                                    <th scope="col">Feedback</th>
                                    <th scope="col" className='table-head-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Date & Time</td>
                                    <td>OID#47744</td>
                                    <td>John</td>
                                    <td>PCB</td>
                                    <td>4.4</td>
                                    <td>Positive</td>
                                    <td className='blog-icons'>
                                        <Link to="#" data-toggle="tooltip" title="View Reviews"> <img src={BlogEye} /></Link>
                                        <Link to="#" data-toggle="tooltip" title="Publish Review"><img src={PublishReview} /></Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date & Time</td>
                                    <td>OID#47744</td>
                                    <td>John</td>
                                    <td>PCB</td>
                                    <td>4.4</td>
                                    <td>Positive</td>
                                    <td className='blog-icons'>
                                        <Link to="#" data-toggle="tooltip" title="View Reviews"> <img src={BlogEye} /></Link>
                                        <Link to="#" data-toggle="tooltip" title="Publish Review"><img src={PublishReview} /></Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
                <div className='dasboard-table dashboard-mr'>
                    <div className='recent-user'>
                        <h4>Recent Feedbacks</h4>
                        <div>
                            <button onClick={()=>{fetchgetrecentfeedback(-1);setfeedbackbtn(0)}} className={feedbackbtn == 0 ? 'purple-btn' : ""}>Today</button>
                            <button onClick={()=>{fetchgetrecentfeedback(7);setfeedbackbtn(7)}} className={feedbackbtn == 7 ? 'purple-btn' : ""}>7 Days</button>
                            <button onClick={()=>{fetchgetrecentfeedback(15);setfeedbackbtn(15)}} className={feedbackbtn == 15 ? 'purple-btn' : ""}>15 Days</button>
                            <button onClick={()=>{fetchgetrecentfeedback(30);setfeedbackbtn(30)}} className={feedbackbtn == 30 ? 'purple-btn' : ""}>30 Days</button>
                        </div>
                    </div>
                    <div className='admin-table table-scroll'>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Student Name</th>
                                    <th scope="col">Email ID</th>
                                    <th scope="col">Mobile Number</th>
                                    <th scope="col">Feedback Detail</th>
                                    <th scope="col" className='table-head-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedback && feedback.map((item,i)=>(
                                    <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile_number}</td>
                                    <td>{item.message}</td>
                                    <td className='blog-icons'>
                                        <Link to="#" data-toggle="tooltip" title="View Feedback"> <img src={BlogEye} /></Link>
                                        <Link to="#" data-toggle="tooltip" title="Publish Feedback"><img src={Comment} /></Link>
                                    </td>
                                </tr>
                                ))}
                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div>
                <h4>Recent Login Activity</h4>
                <p>Admin Logged on {new Date(user.admin.updatedAt).toDateString()}</p>
                <h4>Recent Password Change</h4>
                <p>Last password change was on {data.pwdhistory && new Date(data.pwdhistory[0].createdAt).toDateString()}</p>
            </div>
        </div>
        </>
    )
}
