
import React,{useState,useEffect} from 'react';
import BlogImg from "../../../src/images/blog.png"
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";

export const BlogModal = (props) => {
    const [data,setData]=useState('')
    const baseurl=axios.defaults.baseURL;
    const { user } = useSelector((state) => state.user); 
    const token =user.token
    useEffect(()=>{
        viewe()
        
    },[])
    const viewe = () => {
        const data = { "_id":props.data };
        axios.get('/blogedit/'+props.data,{
            headers: {
                'x-access-token': token,
                'Accept' : 'application/json'
                }
            }).then(res => {
            setData(res.data.data)

        });
    };
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Blog Title:</h6>
                    <p>{data.Blog_title}</p>
                </div>
                <div>
                    <h6>Url Slug:</h6>
                    <p>{data.Url_Slug}</p>
                </div>
                <div>
                    <h6>Status:</h6>
                    <p>{data.Status}</p>
                </div>
                <div>
                    <h6>Canonical URL</h6>
                    <p>{data.Canonical_URL}</p>
                </div>
                <div>
                    <h6>Twitter Crads</h6>
                    <p>{data.Twitter_card}</p>
                </div>
                <div>
                    <h6>OG Tags</h6>
                    <p>{data.OG_Tags}</p>
                </div>
                <div>
                    <h6>Banner Image</h6>
                    <img src={baseurl+data.Banner} style={{"height":100,"width":100}}/>
                </div>
                <div>
                    <h6>Thumb Image </h6>
                    <img src={baseurl+data.img} style={{"height":100,"width":100}} />
                </div>
            </div>
            <div className='video-description'>
                <h6>Meta Title : </h6>
                <p>{data.Meta_title}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Keywords: </h6>
                <p>{data.Meta_keyword}</p>
            </div>
            <div className='video-description'>
                <h6>Body: </h6>
                <p dangerouslySetInnerHTML={{__html:data.body}}></p>
            </div>

        </div>
    )
}
