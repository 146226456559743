import React from 'react';
import { Link } from 'react-router-dom';
import "./paymentsPage.css"
import RazorPay from "../../../src/images/razorpay.png"

export const PaymentPage = () => {
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Payment Gateway</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Payment Gateway</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Payment Gateway</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Payment Gateway</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button >Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
                <Link to="/app/payment-gateway"><button className='account-btn'>Payment Gateway</button></Link>
                <Link to="/app/email-api"><button>Email</button></Link>
                <Link to="/app/sms-api"><button>SMS</button></Link>
                <Link to="/app/socialmedia"><button>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button>Header & Footer</button></Link>
                <Link to="/app/storage-api"><button>Storage</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
                <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
                <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

            </div>
            <div className='payments-inputs'>
                <div className='company-logo'>
                    <img src={RazorPay} />
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter Auth Key</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Enter Merchant ID</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Hash Key</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                </div>
                <div className='update-btn' ><button>Update</button></div>
            </div>
        </div>
    )
}
