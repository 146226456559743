import React, { useState } from 'react'
import { devInstance as axios } from "../../Plugin/axios";
import "./homeWidgets.css";
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css'; import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export const ManageHomeForm = () => {
    const [rows, setRows] = useState([{}]);
    const [file1,setfile1]=useState('');
    const [file2,setfile2]=useState('');
    const [file3,setfile3]=useState('');
    const [file4,setfile4]=useState('');
    const [heading,setheading]=useState('');
    const [Short_Description,setShort_Description]=useState('');
    const [Features_Description1,setFeatures_Description1]=useState('');
    const [Features_heading1,setFeatures_heading1]=useState('');
    const [img1,setimg1]=useState('');
    const [ButtonLink1,setButtonLink1]=useState('');
    const [button1_txt,setbutton1_txt]=useState('');
    const [Features_Description2,setFeatures_Description2]=useState('');
    const [Features_heading2,setFeatures_heading2]=useState('');
    const [img2,setimg2]=useState('');
    const [ButtonLink2,setButtonLink2]=useState('');
    const [button2_txt,setbutton2_txt]=useState('');
    const [Features_heading3,setFeatures_heading3]=useState('');
    const [img3,setimg3]=useState('');
    const [ButtonLink3,setButtonLink3]=useState('');
    const [button3_txt,setbutton3_txt]=useState('');
    const [Features_heading4,setFeatures_heading4]=useState('');
    const [Features_Description3,setFeatures_Description3]=useState('');

    const [img4,setimg4]=useState('');
    const [ButtonLink4,setButtonLink4]=useState('');
    const [button4_txt,setbutton4_txt]=useState('');
    const [Features_Description4,setFeatures_Description4]=useState('');

    const [_id,setid]=useState('');
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const token =user.token
    const baseurl=axios.defaults.baseURL;
    const columnsArray = ["name"];
const [input,setInput]=useState({
    _id:"",
    heading:"",
    Short_Description:"",
    Features_Description1:"",
    Features_heading1:"",
    img1:"",
    ButtonLink1:"",
    button1_txt:"",
    Features_Description2:"",
    Features_heading2:"",
    img2:"",
    ButtonLink2:"",
    button2_txt:"",
    Features_Description3:"",
    Features_heading3:"",
    img3:"",
    ButtonLink3:"",
    button3_txt:"",
    Features_Description4:"",
    Features_heading4:"",
    img4:"",
    ButtonLink4:"",
    button4_txt:"",
});

useEffect(()=>{
    viewe();
},[])
const viewe = async() => {
   
    await axios.get("/getwidget1").then(res => {
        setInput(res.data.data[0]);
        setheading(res.data.data[0].heading);
        setShort_Description(res.data.data[0].Short_Description);
        setFeatures_heading1(res.data.data[0].Features_heading1);
        setFeatures_Description1(res.data.data[0].Features_Description1);
        setButtonLink1(res.data.data[0].ButtonLink1);
        setbutton1_txt(res.data.data[0].button1_txt);
        setFeatures_heading2(res.data.data[0].Features_heading2);
        setFeatures_Description2(res.data.data[0].Features_Description2);
        setButtonLink2(res.data.data[0].ButtonLink2);
        setbutton2_txt(res.data.data[0].button2_txt);
        setFeatures_heading3(res.data.data[0].Features_heading3);
        setFeatures_Description3(res.data.data[0].Features_Description3);
        setButtonLink3(res.data.data[0].ButtonLink3);
        setbutton3_txt(res.data.data[0].button3_txt);
        setFeatures_heading4(res.data.data[0].Features_heading4);
        setFeatures_Description4(res.data.data[0].Features_Description4);
        setButtonLink4(res.data.data[0].ButtonLink4);
        setbutton4_txt(res.data.data[0].button4_txt);

        setfile1(baseurl+res.data.data[0].img1)
        setfile2(baseurl+res.data.data[0].img2)
        setfile3(baseurl+res.data.data[0].img3)
        setfile4(baseurl+res.data.data[0].img4)
       
    });
};
    const handleAddRow = () => {
        const item = {};
        setRows([...rows, item]);
    };

    const handleRemoveSpecificRow = (idx) => {
        const tempRows = [...rows];
        tempRows.splice(idx, 1);
        setRows(tempRows);
    };
    const haldleimage1 =async(e)=>{
        setimg1(e.target.files[0]);
        setfile1(URL.createObjectURL(e.target.files[0]));
    }
    const haldleimage2 =async(e)=>{
        setimg2(e.target.files[0]);
        setfile2(URL.createObjectURL(e.target.files[0]));
    }
    const haldleimage3 =async(e)=>{
        setimg3(e.target.files[0]);
        setfile3(URL.createObjectURL(e.target.files[0]));
    }
    const haldleimage4 =async(e)=>{
        setimg4(e.target.files[0]);
        setfile4(URL.createObjectURL(e.target.files[0]));
    }
    const updateState = (e) => {
        let prope = e.target.attributes.column.value;
        let index = e.target.attributes.index.value;
        let fieldValue = e.target.value;
        const tempRows = [...rows];
        const tempObj = rows[index];
        tempObj[prope] = fieldValue;
        tempRows[index] = tempObj;
        setRows(tempRows);
    };
    const HandelSubmit =async(e)=>{
        axios.put('/widget1',{_id,
            heading,
            Short_Description,
            Features_Description1,
            Features_heading1,
            img1,
            ButtonLink1,
            button1_txt,
            Features_Description2,
            Features_heading2,
            img2,
            ButtonLink2,
            button2_txt,
            Features_Description3,
            Features_heading3,
            img3,
            ButtonLink3,
            button3_txt,
            Features_Description4,
            Features_heading4,
            img4,
            ButtonLink4,
            button4_txt,}, {
            headers: {
                'x-access-token': token,
                'Accept' : 'application/json',
                "Content-Type": "multipart/form-data"
                }
            }).then((res)=>{
          if(res.data.code ===200){
            toast.success('update widget1 Successfully done')
            navigate('/app/home-widget')

          }else{
            toast.error('Please try again later')
          }
        })
    }
    // const { user } = useSelector((state) => state.user);    
    // const[heading,setheading]=useState('')
    // const[Short_Description,setShort_Description]=useState('')
    // const[Features_Description,setFeatures_Description]=useState('')
    // const[ButtonLink,setButtonLink]=useState('')
    // const[img,setimg]=useState('')
    // const token=user.token
    // const [fetchData, setFetchdata] = useState(true)
    // const fetchDatacall = ()=>{setFetchdata((t)=>!t)}
    // const handelheading =(e)=>{
    //     setheading(e.target.value)
    // }
    // const handelShort_Description =(e)=>{
    //     setShort_Description(e.target.value)
    // }
    // const handelFeatures_Description =(e)=>{
    //     setFeatures_Description(e.target.value)
    // }
    // const handelButtonLink =(e)=>{
    //     setButtonLink(e.target.value)
    // }
    // const handelimg =(e)=>{
    //     setimg(e.target.files[0])
    // }
    // const handlesubmit =async (e)=>{
    //     const data={Short_Description,Features_Description,heading,img,ButtonLink}
    //     axios.post('/widget1',data, {
    //         headers: {
    //             'x-access-token': token,
    //             'Accept' : 'application/json',
    //             "Content-Type": "multipart/form-data"}}).then((res)=>{
    //                if(res.data.code===200){
    //                 toast.success('Widget1 update successfully')}}).catch((err)=>{toast.error('Please Try Again Later')})}
    //     useEffect(()=>{
    //         axios.get('/getwidget', {
    //             headers: {
    //                 'x-access-token': token,
    //                 'Accept' : 'application/json',
    //                 "Content-Type": "multipart/form-data"}}).then((res)=>{
    //                     setButtonLink(res.data.data[0].ButtonLink)
    //                     setShort_Description(res.data.data[0].Short_Description)
    //                     setimg(res.data.data[0].img)
    //                     setheading(res.data.data[0].heading)
    //                     setFeatures_Description(res.data.data[0].Features_Description)
    //                 })
    //             },[])


    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Widgets</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Widgets</p>
            </div>
            <div className='home-widgets'>
                <h6>Widgets 1</h6>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={heading} onChange={(e)=>setheading(e.target.value)}  />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Description</label>
                            <input type="text" class="form-control" placeholder="Enter Description here" value={Short_Description} onChange={(e)=>setShort_Description(e.target.value)}  />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Heading1</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={Features_heading1} onChange={(e)=>setFeatures_heading1(e.target.value)}  />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Description1</label>
                            <input type="text" class="form-control" placeholder="Enter Description here" value={Features_Description1} onChange={(e)=>setFeatures_Description1(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Text1</label>
                            <input type="text" class="form-control" placeholder="Enter Button Text here" value={button1_txt} onChange={(e)=>setbutton1_txt(e.target.value)}  />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Link1</label>
                            <input type="text" class="form-control" placeholder="Enter Button Link here" value={ButtonLink1} onChange={(e)=>setButtonLink1(e.target.value)}   />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Image1</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e)=>haldleimage1(e)}  />
                            <img src={file1} style={{"height":100,"width":100}} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Heading2</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={Features_heading2} onChange={(e)=>setFeatures_heading2(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Description2</label>
                            <input type="text" class="form-control" placeholder="Enter Description here" value={Features_Description2} onChange={(e)=>setFeatures_Description2(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Text2</label>
                            <input type="text" class="form-control" placeholder="Enter Button Text here" value={button2_txt} onChange={(e)=>setbutton2_txt(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Link2</label>
                            <input type="text" class="form-control" placeholder="Enter Button Link here" value={ButtonLink2} onChange={(e)=>setButtonLink2(e.target.value)}   />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Image2</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e)=>haldleimage2(e)}  />
                            <img src={file2} style={{"height":100,"width":100}} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Heading3</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={Features_heading3} onChange={(e)=>setFeatures_heading3(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Description3</label>
                            <input type="text" class="form-control" placeholder="Enter Description here" value={Features_Description3} onChange={(e)=>setFeatures_Description3(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Text3</label>
                            <input type="text" class="form-control" placeholder="Enter Button Text here" value={button3_txt} onChange={(e)=>setbutton3_txt(e.target.value)}  />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Link3</label>
                            <input type="text" class="form-control" placeholder="Enter Button Link here" value={ButtonLink3} onChange={(e)=>setButtonLink3(e.target.value)}   />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Image3</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e)=>haldleimage3(e)} />
                            <img src={file3} style={{"height":100,"width":100}} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Heading4</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={Features_heading4} onChange={(e)=>setFeatures_heading4(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Description4</label>
                            <input type="text" class="form-control" placeholder="Enter Description here" value={Features_Description4} onChange={(e)=>setFeatures_Description4(e.target.value)}   />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Text4</label>
                            <input type="text" class="form-control" placeholder="Enter Button Text here"  value={button4_txt} onChange={(e)=>setbutton4_txt(e.target.value)}  />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Button Link4</label>
                            <input type="text" class="form-control" placeholder="Enter Button Link here" value={ButtonLink4} onChange={(e)=>setButtonLink4(e.target.value)}   />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Feature Image4</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e)=>haldleimage4(e)}  />
                            <img src={file4} style={{"height":100,"width":100}} />
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div className="home-update-btn  margin-top-mobile1">
                    <button onClick={HandelSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
