import React, { useEffect, useState } from 'react';
import "./loginPage.css";
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { LoginSchema } from '../../Validations/validation';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginStart, loginSuccess } from "../../redux/UserSlice";
import { useSelector } from "react-redux";
import { devInstance as axios } from "../../Plugin/axios";
export const LoginPage = () => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [eye, seteye] = useState(true);
    const [ip, setIp] = useState();
    const [city, setCity] = useState();
    const [Heading, setHeading] = useState('');
    const [ad_email, setad_email] = useState('');
    const [ad_password, setad_password] = useState('');
    const [tag, settag] = useState('');
    const [logo2, setlogo2] = useState(true);
    const [password, setpassword] = useState("password");
    const [type, settype] = useState(false);
    const [errors, seterr] = useState('')
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else {
        browserName = "No browser detection";
    }
    useEffect(() => {
        axios.get('/get').then((res) => {
            setlogo2(res.data.data.logo2)
            setHeading(res.data.data.header_title)
        })

        getData();


    }, [])
    const handlelogin = () => {
        axios.post('/login', { ad_email, ad_password, ip: ip, city: city, "BrowserName": browserName }).then((res) => {
            if (res.data.status === 400) {
                seterr(res.data.message)
                settag(res.data.tag)
            } else {
                dispatch(loginSuccess(res.data));
                navigate('/app/dashboard')
            }

        })
    }
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIp(res.data.IPv4)
        setCity(res.data.city)
    }
    const Eye = () => {

        if (password == "password") {
            setpassword("text");
            seteye(false);
            settype(true);
        } else {
            setpassword("password");
            seteye(true);
            settype(false);
        }
    }
    var ipnew;
    var citynew;
    useEffect(() => {
        const userLoggedIN = user
        if (userLoggedIN) {
            navigate('/app/dashboard')
        } else {
            navigate('/')
        }

    }, [])

    return (



        <div className='container-fluid admin-login'>
            <div className='row admin-login'>
                <div className='col-md-6'>
                    <img src="../login-banner.png" />
                </div>
                <div className='col-md-6 admin-inputs'>

                    <div className='admin-content'>
                        <h4>ABACUSLY - LMS</h4>
                        <h5>{Heading}</h5>

                        <div className="input-icons eye-icon">


                            <i className="fa fa-user icon"></i>


                            <input id={errors.ad_email ? "error" : ""}
                                className="input-field" placeholder='Enter your email' type="email" name="ad_email" onChange={(e) => setad_email(e.target.value)} value={ad_email} />
                            {errors && tag == 0 && <p className='err2' style={{ color: 'red', marginBottom: '-1.5rem' }}>Email is Required</p>}

                            {errors && tag == 1 && <p className='err2' style={{ color: 'red', marginBottom: '-1.5rem' }}>{errors}</p>}
                            <div>
                                <i className="fa fa-lock icon"></i>
                                <input className="input-field" type={password} id={errors.ad_password ? "error" : ""} name="ad_password" placeholder='Enter your password' value={ad_password} onChange={(e) => setad_password(e.target.value)} />
                                <span><i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                    aria-hidden="true"></i></span>
                            </div>
                            {errors && tag == 0 && <p className='err2' style={{ color: 'red', marginBottom: '-1.5rem' }}>Password is Required</p>}
                            {errors && tag == 2 && <p style={{ color: "red" }}>{errors}</p>} </div>

                        <div className='d-flex justify-content-between'>
                            <div className='checkbox'>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                <label for="vehicle1">Remember me</label>
                            </div>
                            <div>
                                <Link to="/forget-password"> <p className='forget-password'>Forgot Password?</p></Link>
                            </div>
                        </div>
                        <div>
                            <button className='sign-in' onClick={handlelogin} >Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
