import React, { useEffect, useState } from 'react'
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import "./blogPage.css";
import { Link } from 'react-router-dom';
import { BlogModal } from './BlogModal';
import Modal from 'react-bootstrap/Modal';
import { devInstance as axios } from "../../Plugin/axios";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
export const BlogPage = () => {
    const [lgShow, setLgShow] = useState(false);
    const { user } = useSelector((state) => state.user); 
    const [data, setdata] = useState([])
    const token =user.token
    const [view,setView] = useState();
    const baseurl=axios.defaults.baseURL;
    useEffect(() => {
        fetchDatacall();
    }, [])
    const fetchDatacall =()=>{
        axios.get('/blog').then((res) => {
            setdata(res.data.Data)

        })
    }
    const deleteblog = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete('/blogdelete/'+id,{
                    headers: {
                        'x-access-token': token,
                        'Accept' : 'application/json',
                        "Content-Type": "multipart/form-data"}}).then(res => {
                        fetchDatacall()
                        toast.success('Blog succesfully deleted');
                    })
                    .catch(err => toast.error('Error in Blog  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Blog</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Blog</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Blog</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Blog</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Blogs</h6>
                    <Link to="/app/blog"><button>Add <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Tittle</th>
                                <th scope="col">URL</th>
                                <th scope="col">Image</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data && data.map((item, i) => (
                            <tr>
                                <td>{item.updatedAt}</td>
                                <td>{item.Blog_title}</td>
                                <td>{item.Url_Slug}</td>
                                <td><img src={baseurl+item.img} style={{ width: "100px" }} /></td>
                                <td>{item.Status}</td>
                                <td className='blog-icons'>
                                    <Link to={"/app/edit-blog/"+item._id} data-toggle="tooltip" title="Edit Blog Details"> <img src={BlogEdit} /></Link>
                                    <Link to="#" onClick={()=>setView(item._id)} data-toggle="tooltip" title="View Blog Details"> <img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                    <Link to="#" onClick={() => deleteblog(item._id)} data-toggle="tooltip" title="Delete Blog"> <img src={BlogDelete} /></Link>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BlogModal data={view} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}
