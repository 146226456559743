import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import React, { useEffect, useState } from 'react';
import { BlogEditor } from '../BlogPage/MyEditor';
import "./about.css";
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import content from '../BlogPage/Content.js';
import 'react-toastify/dist/ReactToastify.css';;

export const AboutUsPage = () => {
    const [rows, setRows] = useState([{}]);
    const columnsArray = ["name"]; // pass columns here dynamically
    const baseurl1 = axios.defaults.baseURL;

    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
    } = EditorTools;
    const { user } = useSelector((state) => state.user);

    const token = user.token
    const [header_title, setheader_title] = useState('');
    const [file, setFile] = useState();
    const [fileicon, setFileicon] = useState();
    const [data, setData] = useState('');
    const [ButtonLink, setButtonLink] = useState('');
    const [button_txt, setButton] = useState('')
    const [heading2, setheading2] = useState('');
    const [heading3, setheading3] = useState('');
    const [baseurl, setBaseUrl] = useState('');
    const [body, setbody] = useState('');
    const [body2, setbody2] = useState('');
    const [body3, setbody3] = useState('');
    const [features, setfeatures] = useState('');
    const [img, setimg] = useState('');
    const [featuresicon, setfeaturesicon] = useState('');
    const [userErr, setUserErr] = useState('')
    const [Err, setErr] = useState('')
    const [inputFields, setInputFields] = useState([{ feature: "", featureicon: "", imagepath: "" }]);
    const bodynew1 = React.createRef();
    const bodynew2 = React.createRef();
    const bodynew3 = React.createRef();
    const handleheading2 = (e) => {
        setheading2(e.target.value);
    };
    const handleheading3 = (e) => {
        setheading3(e.target.value);
    };
    const handlebody2 = (e) => {
        setbody2(e.target.value);
    };
    const handlebody3 = (e) => {
        setbody3(e.target.value);
    };
    const handlefeatures = (e) => {
        setfeatures(e.target.value);
    };
    const handlebody = (e) => {
        console.log("--", e.target);
        setbody(e.target.value);
    };
    const handleheader_title = (e) => {
        setheader_title(e.target.value);
    };

    const handleimg = (e) => {
        console.log(e.target.files[0])
        setFile(URL.createObjectURL(e.target.files[0]));
        setimg(e.target.files[0]);
    };
    const handlefeaturesicon = (e) => {
        setFileicon(URL.createObjectURL(e.target.files[0]));

        setfeaturesicon(e.target.files[0]);
    };
    const handleAddRow = () => {
        const item = {};
        setInputFields([...inputFields, { feature: "", featureicon: "", imagepath: "" }])
        setRows([...rows, item]);
    };

    const handleRemoveSpecificRow = (idx) => {
        const rows = [...inputFields];
        rows.splice(idx, 1);
        setInputFields(rows);
        const tempRows = [...rows];
        tempRows.splice(idx, 1);
        setRows(tempRows);
    };

    const updateState = (index, event) => {
        const { name, value, files } = event.target;
        const list = [...inputFields];
        // console.log(list)
        if (event.target.files) {
            list[index]["featureicon"] = files[0];
            list[index]['imagepath'] = URL.createObjectURL(event.target.files[0]);
        } else {
            list[index][name] = value;
        }

        setInputFields(list);
        // setRows(list);
        console.log(inputFields)
        // let prope = event.target.attributes.column.value;
        // let index = event.target.attributes.index.value;
        // let fieldValue = event.target.value;
        // const tempRows = [...rows];
        // const tempObj = rows[index];
        // tempObj[prope] = fieldValue;
        // tempRows[index] = tempObj;
        // console.log(tempRows)
        // setRows(tempRows);
    };
    useEffect(() => {


        axios.get('/get_aboutus', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            // console.log(JSON.parse(res.data.Data[0].features)[0])
            let d = []
            const featureback = JSON.parse(res.data.Data[0].features);
            featureback.map((item, index) => {
                console.log(item)
                var image = baseurl1 + JSON.parse(res.data.Data[0].featuresicon)[index]
                d.push({ feature: item, featureicon: JSON.parse(res.data.Data[0].featuresicon)[index], imagepath: image })
            })
            setInputFields(d)
            console.log("000000", inputFields, d)
            setFile(baseurl1 + res.data.Data[0].img)
            setFileicon(baseurl1 + res.data.Data[0].featuresicon)
            setBaseUrl(baseurl1);
            setData(res.data.Data);
            setheader_title(res.data.Data[0].header_title)
            setButtonLink(res.data.Data[0].ButtonLink)
            setButton(res.data.Data[0].button_txt)
            setheading2(res.data.Data[0].heading2)
            setheading3(res.data.Data[0].heading3)
            setbody(res.data.Data[0].body)
            setbody2(res.data.Data[0].body2)
            setbody3(res.data.Data[0].body3)
            setfeatures(res.data.Data[0].features)
        })


    }, [])
    const handleSubmit = async (e) => {
        if (header_title.length < 6) {
            setUserErr("please provide Header Title min length 6")
        }
        else {
            setErr('')
        }



        const view1 = bodynew1.current.view;
        const view2 = bodynew2.current.view;
        const view3 = bodynew3.current.view;
        var body = EditorUtils.getHtml(view1.state);
        var body2 = EditorUtils.getHtml(view2.state);
        var body3 = EditorUtils.getHtml(view3.state);


        var featurenewicon = [];
        var featurenew = [];
        var featureimage = [];
        var formData = new FormData();
        inputFields.map((item) => {
            formData.append('featuresicon', item.featureicon);
            featurenewicon.push(item.featureicon);
            featurenew.push(item.feature);
            featureimage.push(item.imagepath);
        })

        formData.append('header_title', header_title);
        formData.append('features', JSON.stringify(featurenew));
        formData.append('img', img);
        formData.append('body2', body2);
        formData.append('body', body);
        formData.append('body3', body3);
        formData.append('heading2', heading2);
        formData.append('heading3', heading3);
        formData.append('ButtonLink', ButtonLink);
        formData.append('button_txt', button_txt);
        // const data = { header_title, body, "features":JSON.stringify(featurenew), img, "featuresicon":featurenewicon.toString(), body2, heading2, heading3, body3 }
        axios.put('/update_Aboutus', formData, {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            if (res.data.code === 200) {
                toast.success('Hey Your about us page details successfully Updated')
            } else {
                toast.error('Please try again later')
            }
        })
    }



    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>About</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>About</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>About</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>About</p>
                    </div>
                </div>
            </div>
            <div className='about-section'>
                <div className="about-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading / Title</label>
                            <input type="text" class="form-control" placeholder="Enter you heading / title here" value={header_title} onChange={handleheader_title} />
                        </div>
                        {userErr ? <span style={{ color: 'red' }}>please enter Hader-Title min 6 chrareter</span> : ""}
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Image </label>
                            <input type="file" class="form-control" placeholder="" onChange={(e) => {
                                handleimg(e);
                            }} />
                            <img src={file} style={{ "height": 100, "width": 100 }} />
                        </div>
                    </div>
                    <div className="widgets-inputs draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button</label>
                            <input type="text" class="form-control" placeholder="Enter buttons text here" value={button_txt} onChange={(e) => setButton(e.target.value)} />
                        </div>
                    </div>
                    <div className="widgets-inputs">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Links</label>
                            <input type="text" class="form-control" placeholder="Enter buttons url here" value={ButtonLink} onChange={(e) => setButtonLink(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='about-editor'>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                            height: 300
                        }} value={body} ref={bodynew1} defaultContent={content} onChange={handlebody} />
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-md-12 column">
                        <table className="table table-hover view-order-table" id="tab_logic">
                            <tbody>
                                {inputFields && inputFields.map((item, idx) => {
                                    const { feature, featureicon, imagepath } = item;

                                    return (
                                        <tr key={idx}>

                                            {columnsArray.map((column, index) => (
                                                <td key={index}>
                                                    <label>Features Heading</label>
                                                    <input
                                                        type="text"
                                                        name="feature"
                                                        column={column}
                                                        value={feature}
                                                        index={idx}
                                                        className="form-control"
                                                        onChange={(event) => updateState(idx, event)}
                                                        placeholder="hello"
                                                    />

                                                </td>
                                            ))}
                                            {columnsArray.map((column, index) => (
                                                <td key={index}>
                                                    <label>Features Icon/image</label>
                                                    <input
                                                        type="file"
                                                        name="featureicon"
                                                        column={column}
                                                        // value={inputFields.feature}
                                                        index={idx}
                                                        className="form-control"
                                                        onChange={(event) => updateState(idx, event)}
                                                    // placeholder="hello"
                                                    />
                                                    {imagepath && (
                                                        <img src={imagepath} style={{ "height": 100, "width": 100 }} />
                                                    )}

                                                </td>
                                            ))}
                                            <td>
                                                <div>

                                                    {(inputFields.length !== 1) && (
                                                        <button
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={() => handleRemoveSpecificRow(idx)}
                                                            style={{ marginTop: "35px" }}
                                                        >Remove</button>

                                                    )
                                                    }
                                                    <button
                                                        className="btn btn-outline-primary btn-sm"
                                                        style={{ marginTop: "35px", marginLeft: "10px" }}
                                                        onClick={(e) => { handleAddRow() }}>
                                                        Add More Feauture
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="draft-update-btn" onClick={handleSubmit}>
                    <button >Update</button>
                </div>
            </div>

            {/* Second section */}

            <div className='about-section'>
                <div className="about-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading / Title</label>
                            <input type="text" class="form-control" placeholder="" value={heading2} onChange={handleheading2} />
                        </div>

                    </div>
                </div>
                <div>
                    <div className='about-editor'>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                            height: 300
                        }} value={body2} ref={bodynew2} defaultContent={content} onChange={handlebody2} />
                    </div>
                    <div className="draft-update-btn">
                        <button onClick={handleSubmit}>Update</button>
                    </div>
                </div>
            </div>

            {/* Third Section */}

            <div className='about-section'>
                <div className="about-inputs">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading / Title</label>
                            <input type="text" class="form-control" placeholder="" value={heading3} onChange={handleheading3} />
                        </div>
                        {userErr ? <span style={{ color: 'red' }}>please enter Hader-Title min 6 chrareter</span> : ""}
                    </div>
                </div>
                <div>
                    <div className='about-editor'>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                            height: 300
                        }} value={body3} ref={bodynew3} defaultContent={content} onChange={handlebody3} />
                    </div>
                    <div className="draft-update-btn">
                        <button onClick={handleSubmit}>Update</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
