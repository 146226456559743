import React,{useState,useEffect} from 'react';
import BlogImg from "../../../src/images/blog.png"
import { devInstance as axios } from "../../Plugin/axios";
export const ViewSlider = (props) => {
    const [data,setData]=useState('')
    const baseurl=axios.defaults.baseURL;
    useEffect(()=>{
        viewe()
        
    },[])
    const viewe = () => {
        const data = { "_id":props.data };
        axios.post('/sliderbyid',data).then(res => {
            setData(res.data.data)

        });
    };
  
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Heading:</h6>
                    <p>{data.Heading}</p>
                </div>
                <div>
                    <h6>Button Link:</h6>
                    <p>{data.ButtonLink}</p>
                </div>
                <div>
                    <h6>Browse Category Icon:</h6>
                    <img src={`${baseurl+data.img}`} style={{width:'5rem'}}/>
                </div>
                <div>
                    <h6>Status</h6>
                    <p>{data.status}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Description : </h6>
                <p>{data.Descriptions}</p>
            </div>
        </div>
    )
}
