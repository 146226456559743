import React from 'react'
import { useState } from 'react';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { devInstance as axios } from "../../../../Plugin/axios";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';;
export const EditWidgets2 = () => {
    const [data, setdata] = useState([])
    const [rows, setRows] = useState([{}]);
    const columnsArray = ["name"]; // pass columns here dynamically

    const handleAddRow = () => {
        const item = {};
        setRows([...rows, item]);
    };

    const handleRemoveSpecificRow = (idx) => {
        const tempRows = [...rows];
        tempRows.splice(idx, 1);
        setRows(tempRows);
    };

    const updateState = (e) => {
        let prope = e.target.attributes.column.value;
        let index = e.target.attributes.index.value;
        let fieldValue = e.target.value;
        const tempRows = [...rows];
        const tempObj = rows[index];
        tempObj[prope] = fieldValue;
        tempRows[index] = tempObj;
        setRows(tempRows);
    };
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
    } = EditorTools;
    const { user } = useSelector((state) => state.user);
    const [heading, setheading] = useState('')
    const [Short_Description, setShort_Description] = useState('')
    const [Detail_Description, setDetail_Description] = useState('');
    const [Features_Heading, setFeatures_Heading] = useState('');
    const [ButtonLink, setButtonLink] = useState('');
    const [edit, setEdit] = useState(false);
    const [removeRow, setRemoveRow] = useState(true);
    const [img, setimg] = useState('');
    const token = user.token
    const handleheading = async (e) => {
        setheading(e.target.value);
    }
    const handleDetail_Description = async (e) => {
        setDetail_Description(e.target.value);
    }
    const handleFeatures_Heading = async (e) => {
        setFeatures_Heading(e.target.value);
    }
    const handleButtonLink = async (e) => {
        setButtonLink(e.target.value);
    }
    const handleShort_Description = async (e) => {
        setShort_Description(e.target.value);
    }
    const handleimg = async (e) => {
        setimg(e.target.files[0]);
    }
    const handleSubmit = async (e) => {
        e.preventDefault(e)
        const data = { Short_Description, Detail_Description, heading, img, ButtonLink, Features_Heading }
        axios.put('/widget2', data, {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            if (res.data.code === 200) {
                toast.success('Widget2 Successfully updated')
            }
        }).catch((error) => {
            toast.error('Please try again later')
        })
    }
    useEffect(() => {
        axios.get('/getwidget2', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setheading(res.data.data[0].heading)
            setShort_Description(res.data.data[0].Short_Description)
            setFeatures_Heading(res.data.data[0].Features_heading)

            setDetail_Description(res.data.data[0].Detail_Description)
            setButtonLink(res.data.data[0].ButtonLink)

        })
    }, [])
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Widgets</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Widgets</p>
            </div>
            <div className='home-widgets'>
                <h6>Widgets 2</h6>
                <div className='manage-widgets'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={heading} onChange={handleheading} />
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Short Description</label>
                            <input type="text" class="form-control" placeholder="Enter Short Description here" value={Short_Description} onChange={handleShort_Description} />
                        </div>
                    </div>
                </div>
                <div>
                    <p>Detail Description</p>
                    <div className='widgets-editor'>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                            height: 300
                        }} value={Detail_Description} onChange={handleDetail_Description} />;
                    </div>
                </div>
                <div className='home-widgets2'>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Features Heading</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={Features_Heading} onChange={handleFeatures_Heading} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Features Icons</label>
                            <input type="file" class="form-control" placeholder="Enter Heading here" onChange={(e) => {
                                handleimg(e)
                            }} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Button Links</label>
                            <input type="text" class="form-control" placeholder="Enter Heading here" value={ButtonLink} onChange={handleButtonLink} />
                        </div>
                    </div>
                    <div className='widgets2'>
                        <button>Add</button>
                    </div>
                </div>
                <div className="">
                    <div className="row clearfix">
                        <div className="col-md-12 column">
                            <table className="table table-hover" id="tab_logic">
                                <tbody>
                                    {rows.map((item, idx) => (
                                        <tr key={idx}>
                                            {columnsArray.map((column, index) => (
                                                <td key={index}>
                                                    <label>Features Heading</label>
                                                    <input
                                                        type="text"
                                                        column={column}
                                                        value={rows[idx][column]}
                                                        index={idx}
                                                        className="form-control"
                                                        onChange={(e) => updateState(e)}
                                                        placeholder="hello"
                                                    />
                                                </td>
                                            ))}
                                            {columnsArray.map((column, index) => (
                                                <td key={index}>
                                                    <label>Features Icons</label>
                                                    <input
                                                        type="file"
                                                        column={column}
                                                        value={rows[idx][column]}
                                                        index={idx}
                                                        className="form-control"
                                                        onChange={(e) => updateState(e)}
                                                        placeholder="hello"
                                                    />
                                                </td>
                                            ))}
                                            {columnsArray.map((column, index) => (
                                                <td key={index}>
                                                    <label>Buttons Links</label>
                                                    <input
                                                        type="text"
                                                        column={column}
                                                        value={rows[idx][column]}
                                                        index={idx}
                                                        className="form-control"
                                                        onChange={(e) => updateState(e)}
                                                        placeholder="hello"
                                                    />
                                                </td>
                                            ))}
                                            <td>
                                                <div>
                                                    <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        onClick={() => handleRemoveSpecificRow(idx)}
                                                        style={{ marginTop: "35px" }}
                                                    >Remove</button>
                                                    <button 
                                                     className="btn btn-outline-primary btn-sm"
                                                     style={{ marginTop: "35px", marginLeft: "10px" }}
                                                    onClick={(e) => {handleAddRow()}}>
                                                        Add Row
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className="home-update-btn margin-top-mobile1">
                        <button onClick={handleSubmit}>Update</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
