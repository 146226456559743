import React,{useEffect,useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import BlogEye from "../../../src/images/purple-eye.svg"
import Download from "../../../src/images/download-icon.svg"
import { useSelector } from "react-redux";
import { devInstance as axios } from "../../Plugin/axios";
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
export const ViewOrder = () => {
    const [data,setData]=useState('');
    const { user } = useSelector((state) => state.user);
    const [page, setpage] = useState();
    const [user1,setuser]=useState('');
    const baseurl = axios.defaults.baseURL;
    const {link} = useParams();
    const token = user.token
    useEffect(()=>{
        fetchdata();
    },[])
    const approve = () => {
        Swal.fire({
            title: 'Are you sure, you want to approve it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              axios.post("/approveorder/"+data._id,{}, {
                    headers: {
                        'x-access-token': token,
                        'Accept': 'application/json',
        
                    }
                })
                    .then(res => {
                        toast.success('Order succesfully approved');
                        fetchdata();
                    })
                    .catch(err => toast.error('Error in Category deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    const fetchdata = ()=>{
        axios.get('/vieworder/'+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            setData(res.data.data);
            setuser(res.data.user);
        });
    }
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>View Order</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>View Order</p>
            </div>
            <div className='order-details'>
                <div className="flex" style={{display:"flex"}}>
                <h3 className='mr-3'>#{data.order_id}</h3>
               {data.payment_status=="pending" && <Button onClick={approve}>Approve</Button> } 
                </div>
                
                <h4>User Details</h4>
                <div className='user-details-cols gray-bg'>
                    <div className='sections'>
                        <h6>Student Name</h6>
                        <p>{data.name}</p>
                    </div>
                    <div className='sections'>
                        <h6>Email Id</h6>
                        <p>{data.email}</p>
                    </div>
                    <div className='sections'>
                        <h6>Mobile Number</h6>
                        <p>{data.mobile_number}</p>
                    </div>
                </div>
                <div className='user-details-cols white-bg-pd'>
                    <div className='sections'>
                        <h6>Full Address</h6>
                        <p>{user1 ? user1.addres : "NA"}</p>
                    </div>
                    {/* <div className='sections'>
                        <h6>Country</h6>
                        <p>{user1.country_id}</p>
                    </div>
                    <div className=''>
                        <h6>State</h6>
                        <p>{user1.state_id}</p>
                    </div> */}
                </div>
                <div className='user-details-cols gray-bg'>
                    {/* <div className='sections'>
                        <h6>City</h6>
                        <p>{user1.city_id}</p>
                    </div> */}
                    {/* <div className='sections'>
                        <h6>Pincode</h6>
                        <p>{user1.pincode}</p>
                    </div> */}
                </div>
                <hr />

                {/* Order Details */}
                <div className=''>
                    <h4>Order Details</h4>
                    <div className='user-details-cols gray-bg'>
                        <div className='sections'>
                            <h6>Order ID</h6>
                            <p>#{data.order_id}</p>
                        </div>
                        <div className='sections'>
                            <h6>Order Date & Time</h6>
                            <p>{data.createdAt}</p>
                        </div>
                        <div className='sections'>
                            <h6>Order Status</h6>
                            <p>{data.order_status}</p>
                        </div>
                    </div>
                    <div className='user-details-cols white-bg-pd'>
                        <div className='sections'>
                            <h6>Course Category</h6>
                            <p> {data.category_name}</p>
                        </div>
                        <div className='sections'>
                            <h6>Course Name</h6>
                            <p>{data.course_name}</p>
                        </div>
                    </div>
                </div>
                <hr />

                {/* Payment Details */}

                <div className=''>
                    <h4>Payment Details</h4>
                    <div className='user-details-cols gray-bg'>
                        <div className='sections'>
                            <h6>Payement Method</h6>
                            <p>{data.paymentmethod}</p>
                        </div>
                        <div className='sections'>
                            <h6>Payement Status</h6>
                            <p>{data.payment_status}</p>
                        </div>
                        {data.img && (
                            <div className='sections'>
                            <h6>Payement Image</h6>
                            <p><a href={baseurl+data.img} download="logo"><img src={baseurl+data.img} height="100" width="100" /></a></p>
                        </div>
                        )}
                        
                        <div className='sections'>
                            <h6>Transaction ID</h6>
                            <p>{data.trns_id}</p>
                        </div>
                    </div>
                    <div className='user-details-cols white-bg-pd'>
                        <div>
                            <h6>Payment Date & Time</h6>
                            <p> {data.createdAt}</p>
                        </div>
                    </div>
                </div>
                <hr />

                {/* Order Complete Details */}
                <div>
                    <h4>Order Details</h4>
                    <table class="table view-order-table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Order Detail</th>
                                <th scope="col">Cost</th>
                                <th scope="col">Pre Discount</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Sub Total</th>
                                <th scope="col">Show Analytics</th>
                                <th scope="col">Download Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>{data.course_name}</td>
                                <td>${data.mrp}</td>
                                <td>${data.prediscount}</td>
                                <td>1</td>
                                <td>${data.subtotal}</td>
                                <td className='blog-icons text-center'>
                                    <Link to={"/app/user-analytics/"+data.user_id}><img src={BlogEye} /></Link>
                                </td>
                                <td className='blog-icons text-center'>
                                    <a href={baseurl+data.invoice_url} download><img src={Download} /></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Bill Amount */}
                <div className='bill-amount'>
                    <ul>
                        <li>Sub Total <span>${data.subtotal}</span></li>
                        <li>Pre Discount <span className='color-green'>${data.prediscount}</span></li>
                        <li>Coupon Discount <span className='color-green'>${data.coupon_discount_amount}</span></li>
                        <li>GST <span className='color-red'>${data.gst_amount}</span></li>
                        <li>Total Biled Amount<span>${data.order_amount_with_gst}</span></li>
                    </ul>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
