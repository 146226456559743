import React from 'react'

export const RefundProcessModal = () => {
    return (
        <div className='refund-process-content'>
            <h1>Process Refunds</h1>
            <div className='refund-process'>
                <div>
                    <p>Order ID: <span>OID75778799888</span></p>
                    <p>Paid Amount: <span>$78</span></p>
                    <p>Payment Method: <span>UPI</span></p>
                </div>
                <div>
                    <p>Order Date: <span>02-05-2022|06:45:00AM</span></p>
                    <p>Payment Status: <span>Success</span></p>
                </div>
            </div>
            <hr />
            <div className='user-change-pass'>
                <div class="form-group status-dropdown refund-select">
                    <label for="exampleInputEmail1">Select Payment Method</label>
                    <select class="form-select" aria-label="Default select example">
                        <option selected>Bank</option>
                        <option value="1">UPI</option>
                        <option value="1">Credit card</option>
                    </select>
                </div>
                <div class="form-group user-change-pass-mr">
                    <label for="exampleInputEmail1">Enter Amount</label>
                    <input type="text" class="form-control" placeholder="Enter amount" />
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Reference Id</label>
                    <input type="text" class="form-control" placeholder="Enter Refrence Id" />
                </div>
                <div className='margin-top-1'>
                    <button>Submit</button>
                </div>
            </div>
        </div>
    )
}
