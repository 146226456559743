import React, { useEffect, useState } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import { ViewSlider } from './ViewSlider';
import Modal from 'react-bootstrap/Modal';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
export const SliderPage = () => {
    const { user } = useSelector((state) => state.user);
    const [lgShow, setLgShow] = useState(false);
    const [fetchData, setFetchdata] = useState(true)
    const token = user.token;
    const [data, setdata] = useState([]);
    const [view, setViewId] = useState('');
    const baseurl=axios.defaults.baseURL;
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    useEffect(() => {
        axios.get('/get_slider', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setdata(res.data.Data)
        })
    }, [fetchData])
    const deleteSlider = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const data = {
                    id,
        
                };
                JSON.stringify(data)
                axios.delete('/delete_slider', data)
                    .then(res => {
                        console.log(res);
                        fetchDatacall()
                        toast.success('Slider succesfully deleted');
                    })
                    .catch(err => toast.error('Error in Slider  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
       
    };
   
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Slider</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Slider</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Slider</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Slider</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Slider</h6>
                    <Link to="/app/add-slider"><button variant="primary">Add <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Image</th>
                                <th scope="col">Heading</th>
                                <th scope="col">Description</th>
                                <th scope="col">Link URL</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, i) => (

                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td><img src={`${baseurl+item.img}`} style={{ width: "100px" }} /></td>
                                    <td>{item.Heading}</td>
                                    <td>{item.Descriptions}</td>
                                    <td>{item.ButtonLink} </td>
                                    <td>{item.status}</td>
                                    <td className='blog-icons'>
                                        <Link to={'/app/edit-slider/'+item._id} data-toggle="tooltip" title="Edit Slider"> <img src={BlogEdit} /></Link>
                                        <Link to="#" onClick={() => setViewId(item._id)} data-toggle="tooltip" title="View Slider Details"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => deleteSlider(item._id)} data-toggle="tooltip" title="Delete Slider"><img src={BlogDelete} /></Link>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewSlider data={view} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}
