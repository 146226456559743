import React,{useEffect,useState} from 'react';
import BlogImg from "../../../../src/images/blog.png"
import { devInstance as axios } from '../../../Plugin/axios';

export const WedgetModal9 = () => {
    const [data,setData] = useState('');
    const baseurl=axios.defaults.baseURL;
    useEffect(()=>{
        axios.get('/getwidgets10').then((res)=>{
            setData(res.data.data[0])
       
                })
      },[])
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Heading:</h6>
                    <p>{data.heading}</p>
                </div>
                <div>
                    <h6>Features Heading 1:</h6>
                    <p>{data.feature1heading}</p>
                </div>
                <div>
                    <h6>Content:</h6>
                    <p>{data.feature1content}</p>
                </div>
                <div>
                    <h6>Button Link:</h6>
                    <p>{data.feature1buttonurl}</p>
                </div>
                <div>
                    <h6>Features Heading 2:</h6>
                    <p>{data.feature2heading}</p>
                </div>
                <div>
                    <h6>Content:</h6>
                    <p>{data.feature2content}</p>
                </div>
                <div>
                    <h6>Button Link:</h6>
                    <p>{data.feature2buttonurl}</p>
                </div>
                <div>
                    <h6>Features Heading 3:</h6>
                    <p>{data.feature3heading}</p>
                </div>
                <div>
                    <h6>Content:</h6>
                    <p>{data.feature3content}</p>
                </div>
                <div>
                    <h6>Button Link:</h6>
                    <p>{data.feature3buttonurl}</p>
                </div>
                <div>
                    <h6>Features Heading 4:</h6>
                    <p>{data.feature4heading}</p>
                </div>
                <div>
                    <h6>Content:</h6>
                    <p>{data.feature4content}</p>
                </div>
                <div>
                    <h6>Button Link:</h6>
                    <p>{data.feature4buttonurl}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Details Description : </h6>
                <p>{data.Description}</p>
            </div>
        </div>
    )
}
