import React, { useState, useEffect } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { CategoryModal } from './CategoryModal';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
export const ManageCourse = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    const [data, setdata] = useState([])
    const [view, setview] = useState('')
    const [fetchData, setFetchdata] = useState(true)
    const baseurl = axios.defaults.baseURL
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    useEffect(() => {
        axios.get('/api/category/getCategory', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',

            }
        }).then((res) => {
            setdata(res.data.Data)
        })
    }, [fetchData])
    const deleteCourse = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const data = {
                    id
        
                };
                JSON.stringify(data)
                axios.delete("/api/category/del_par_Category/"+id, {
                    headers: {
                        'x-access-token': token,
                        'Accept': 'application/json',
        
                    }
                })
                    .then(res => {
        
                        toast.success('Category succesfully deleted');
                        fetchDatacall()
        
                    })
                    .catch(err => toast.error('Error in Category deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    const viewe = (_id) => {
        const data = { _id };
        axios.post("/api/category/get_ID_Category", data).then(res => {


            localStorage.setItem(
                "Detail",
                JSON.stringify(res.data.data)
            );
            fetchDatacall()

        });
    };

    const [lgShow, setLgShow] = useState(false);
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Course Category</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Course Category</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Course Category</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Course Category</p>
                    </div>
                </div>
            </div>

            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Course Category</h6>
                    <Link to="/app/add-category"><button>Add Category <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll' >
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Category Icon</th>
                                <th scope="col">Category Name</th>
                                <th scope="col">Short Description</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td><img style={{height:100,width:100}} src={baseurl+item.Cat_Img} /></td>
                                    <td>{item.Cat_Category_Name} </td>
                                    <td>{item.Cat_Short_Description}</td>
                                    <td>{item.Cat_Status}</td>
                                    <td className='blog-icons'>
                                        <Link to={"/app/edit-category/"+item._id} data-toggle="tooltip" title="Edit Course Category"> <img src={BlogEdit} /></Link>
                                        <Link to="#" onClick={() => setview(item._id)} data-toggle="tooltip" title="View Course Category"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => deleteCourse(item._id)} data-toggle="tooltip" title="Delete Course Category"><img src={BlogDelete} /></Link>
                                    </td>
                                </tr>))}

                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategoryModal data={view} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}
