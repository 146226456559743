import React, { useState } from 'react';
import BlogImg from "../../../../../src/images/blog.png"
import BlogEye from "../../../../../src/images/purple-eye.svg"
import BlogDelete from "../../../../../src/images/purple-delete.svg"
import BlogEdit from "../../../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { WedgetModal5 } from '../WidgetModal5';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { devInstance as axios } from "../../../../Plugin/axios";

export const Widgets6 = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token
    const [data, setdata] = useState([])
    const baseurl = axios.defaults.baseURL;
    useEffect(() => {
        axios.get('/getwidget6', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setdata(res.data.data)
        })
    }, [])
    const [lgShow, setLgShow] = useState(false);
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Widgets</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Widgets</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Widgets</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Widgets</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
            <Link to="/app/home-widget"><button>Widgets 1</button></Link>
                {/* <Link to="/app/home-widget2"><button className='account-btn'>Widgets 2</button></Link> */}
                <Link to="/app/home-widget3" ><button >Widgets 2</button></Link>
                <Link to="/app/home-widget4" ><button>Widgets 3</button></Link>
                <Link to="/app/home-widget5" ><button>Widgets 4</button></Link>
                {/* <Link to="/app/home-widget6"><button className='account-btn'>Widgets 5</button></Link> */}
                <Link to="/app/home-widget7" ><button>Widgets 5</button></Link>
                <Link to="/app/home-widget8" ><button>Widgets 6</button></Link>
                <Link to="/app/home-widget9"><button>Widgets 7</button></Link>
                <Link to="/app/home-widget10"><button>Widgets 8</button></Link>
                <Link to="/app/home-widget11"><button>Widgets 9</button></Link>
                <Link to="/app/home-widget12"><button>Widgets 10</button></Link>
            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Widgets 5</h6>
                    {/* <Link to="/app/edit-home-widget6"><button variant="primary">Add <img src={BlogAdd} /></button></Link> */}
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Image</th>
                                <th scope="col">Heading</th>
                                <th scope="col">Details Description</th>
                                <th scope="col">Button Link</th>
                                <th scope="col" className='table-head-right'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.updatedAt}</td>
                                    <td><img src={`${baseurl+item.img}`} style={{ width: "100px" }} /></td>
                                    <td>{item.heading}</td>

                                    <td>{item.Detail_Description}</td>

                                    <td>{item.ButtonLink}</td>
                                    <td className='blog-icons table-td-right'>
                                        <Link to="/app/edit-home-widget6" data-toggle="tooltip" title="Edit Home Widget"> <img src={BlogEdit} /></Link>
                                        <Link to="#" data-toggle="tooltip" title="View Home Widgets"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        {/* <Link to="#" data-toggle="tooltip" title="Delete Home Widgets"><img src={BlogDelete} /></Link> */}
                                    </td>
                                </tr>))}
                        </tbody>
                    </table>
                </div>
                <Modal
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <WedgetModal5 />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
