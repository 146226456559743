import React, { useEffect } from 'react'
import { Country, State, City } from "country-state-city";
import { useState } from 'react';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
export const ContactUsForm = () => {
    const [countryName, setCountryname] = useState([]);
    const [country, setCountry] = useState([]);
    const [statename, setStateName] = useState([]);
    const [cityname, setcityName] = useState([]);
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [AddressName,setAddressName] = useState('');
    const [FullAddress,setFullAddress] = useState('');
    const [ZipCode,setZipCode]  = useState('');
    const [email,setemail]  = useState('');
    const [whatsapp_number,setwhatsapp_number]  = useState('');
    const [mobile_number,setmobile_number]  = useState('');
    const [ GoogleIframe,setGoogleIframe] = useState('');
    const [view, setViewId] = useState('');
    const { user } = useSelector((state) => state.user);
    const token = user.token
    const navigate = useNavigate();
    const handleAddressName = (e) => {
        setAddressName(e.target.value);
      };
      const handleZipCode = (e) => {
        setZipCode(e.target.value);
      };
      const handleFullAddress = (e) => {
        setFullAddress(e.target.value);
      };
      const handleGoogleIframe = (e) => {
        setGoogleIframe(e.target.value);
      };
      
    useEffect(() => { 
      setCountryname(Country.getAllCountries());
    }, []);
    const handlecountry = (e) => {
      setCountry(e.target.value);
    };

    useEffect(() => {
      setStateName(State.getStatesOfCountry(country));
    }, [country]);

    const handelstate = (e) => {
      setstate(e.target.value);
    };
  
    useEffect(() => {
      setcityName(City.getCitiesOfState(country, state));
    }, [country, state]);
    const handelCity = (e) => {
      setcity(e.target.value);
    };
 const handleSubmit =async(e)=>{
    const data ={email,whatsapp_number,mobile_number,FullAddress,country,city,state,ZipCode,AddressName,GoogleIframe}

    axios.post('/contactdetails',data,{
        headers: {
       'x-access-token': token,
        
            }}).then((res)=>{
                if(res.data.code ===200){
                    toast.success('Contact Us Added')
                    navigate('/app/contact-us')
                  }else{
                    toast.error('Please try again later')
                  }
    })
 }
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Contact us</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Contact us</p>
            </div>
            <div className="draft-input1">
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Address Name</label>
                        <input type="text" class="form-control" placeholder="Enter you address name here " value={AddressName}  onChange={handleAddressName}/>
                    </div>
                </div>
                <div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Google Map (Iframe)</label>
                        <textarea rows="5" type="text" class="form-control" placeholder="Enter your iframe here" value={GoogleIframe} onChange={handleGoogleIframe} ></textarea>
                    
                    <span style={{height:"100px"}} dangerouslySetInnerHTML={{__html:GoogleIframe}}></span>
                    </div>
                </div>
            </div>
            <div className="draft-input2">
                <div className="draft-mr mobile-mt">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">Country </label>
                        <select class="form-select" onChange={handlecountry} aria-label="Default select example">
                            <option selected >Select your country</option>
                            {countryName.map((item, i) => (
                  <option key={i} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
                        </select>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">State</label>
                        <select class="form-select" onChange={handelstate}aria-label="Default select example">
                            <option selected >Select your state</option>
                            {statename.map((index, i) => (
                  <option key={i} value={index.isoCode}>
                    {index.name}
                  </option>
                ))}
                        </select>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">City</label>
                        <select class="form-select"onChange={handelCity} aria-label="Default select example">
                            <option   selected>Select your City</option>
                            {cityname.map((item, i) => (
                  <option key={i} value={item.name}>{item.name}</option>
                ))}
                        </select>
                    </div>
                </div>
                <div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Zip Code</label>
                        <input type="text" class="form-control" placeholder="Enter your zip code here"  value={ZipCode} onChange={handleZipCode}/>
                    </div>
                </div>
            </div>
            <div className="draft-input1">
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Full Address</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Enter full address here' value={FullAddress} onChange={handleFullAddress}></textarea>
                    </div>
                </div>
                <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="text" class="form-control" placeholder="Enter your Email here"  value={email} onChange={(e)=>setemail(e.target.value)}/>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Mobile Number</label>
                        <input type="text" class="form-control" placeholder="Enter your Mobile Number here"  value={mobile_number} onChange={(e)=>setmobile_number(e.target.value)}/>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Whatsapp Number</label>
                        <input type="text" class="form-control" placeholder="Enter your Whatsapp Number here"  value={whatsapp_number} onChange={(e)=>setwhatsapp_number(e.target.value)}/>
                    </div>
            </div>
            {/* Editor */}
            <div>
                <div className="draft-update-btn">
                    <button onClick={handleSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
