import React,{useState,useEffect} from 'react';
import BlogImg from "../../../../src/images/blog.png"


export const WedgetModal1 = () => {
 
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Heading:</h6>
                    <p>Lorem Ipsum</p>
                </div>
                <div>
                    <h6>Short Description:</h6>
                    <p>ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                <div>
                    <h6>Features Heading:</h6>
                    <p>ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                <div>
                    <h6>Features Icons:</h6>
                    <img src={BlogImg} />
                </div>
                <div>
                    <h6>Button Link :</h6>
                    <p>https://testurl.com</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Details Description : </h6>
                <p>ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis nisl cursus bibendum
                    sit nulla accumsan sodales ornare.</p>
            </div>
        </div>
    )
}
