import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import './category.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
export const AddCategory = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token
    const [Cat_Category_Name, setCat_Category_Name] = useState('');
    const [Cat_URL_Slug, setCat_URL_Slug] = useState('');
    const [Cat_Short_Description, setCat_Short_Description] = useState('');
    const [Cat_Meta_Title, setCat_Meta_Title] = useState('');
    const [Cat_Meta_Description, setCat_Meta_Description] = useState('');
    const [Cat_Meta_Keyword, setCat_Meta_Keyword] = useState('');
    const [Cat_Canonical, setCat_Canonical] = useState('');
    const [Cat_Twitter_Cards, setCat_Twitter_Cards] = useState('');
    const [Cat_OG_Tag, setCat_OG_Tag] = useState('');
    const [Cat_Featured, setCat_Featured] = useState('');
    const [Cat_Delete, setCat_Delete] = useState('');
    const [Cat_Img, setCat_Img] = useState('');
    const [Cat_Status, setCat_Status] = useState('');
    const [is_Premium, setis_Premium] = useState(0);
    const [checked, setChecked] = useState(false);
    const [file, setfile] = useState('');
    const navigate = useNavigate();
    const handleCat_category_name = (e) => {
        setCat_Category_Name(e.target.value);
        var b = e.target.value.toLowerCase().replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        setCat_URL_Slug(b);
    }
    const handleCat_URL_Slug = (e) => {
        setCat_URL_Slug(e.target.value);
    }
    const handleCat_Short_Description = (e) => {
        setCat_Short_Description(e.target.value);
    }
    const handleCat_Meta_Title = (e) => {
        setCat_Meta_Title(e.target.value);
    }
    const handleCat_Meta_Description = (e) => {
        setCat_Meta_Description(e.target.value);
    }
    const handleCat_Canonical = (e) => {
        setCat_Canonical(e.target.value);
    }
    const handleCat_Meta_Keyword = (e) => {
        setCat_Meta_Keyword(e.target.value);
    }
    const handleCat_Twitter_Cards = (e) => {
        setCat_Twitter_Cards(e.target.value);
    }
    const handleCat_OG_Tag = (e) => {
        setCat_OG_Tag(e.target.value);
    }
    const handleCat_Featured = (e) => {
        setCat_Featured(e.target.value);
    }
    const handleCat_Delete = (e) => {
        setCat_Delete(e.target.value);
    }
    const handleCat_Img = (e) => {
        setCat_Img(e.target.files[0]);
        setfile(URL.createObjectURL(e.target.files[0]));
    }
    const handleCat_Status = (e) => {
        setCat_Status(e.target.value);
    }
    const handleChangepremium = (e) => {
        setis_Premium(e.target.checked ? "1" : "0");
        if (e.target.checked) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }
    const categorySchema = Yup.object().shape({
        Cat_Category_Name: Yup.string().required("Category Name is required"),
        // Cat_URL_Slug: Yup.string().required("URL Slug is required"),
        Cat_Short_Description: Yup.string().required(" Short Description is required*"),

        Cat_Img: Yup.string().required("Image is required*"),
        Cat_Status: Yup.string().required(" Status are required*"),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Cat_Category_Name: '',
            Cat_Meta_Description: '',
            Cat_Canonical: '',
            Cat_OG_Tag: '',
            Cat_Meta_Title: '',
            Cat_Short_Description: '',
            Cat_URL_Slug: '',
            Cat_Twitter_Cards: '',
            Cat_Img: '',
            Cat_Meta_Keyword: '',
            Cat_Status: '',
            is_Premium: 0,
        },
        validationSchema: categorySchema,
        onSubmit: values => {
            var formdata = new FormData();
            formdata.append("Cat_Category_Name", values.Cat_Category_Name);
            formdata.append("Cat_Meta_Description", values.Cat_Meta_Description);
            formdata.append("Cat_Canonical", values.Cat_Canonical);
            formdata.append("Cat_OG_Tag", values.Cat_OG_Tag);
            formdata.append("Cat_Meta_Title", values.Cat_Meta_Title);
            formdata.append("Cat_Short_Description", values.Cat_Short_Description);
            formdata.append("Cat_URL_Slug", values.Cat_URL_Slug);
            formdata.append("Cat_Twitter_Cards", values.Cat_Twitter_Cards);
            // formdata.append("department", value.department);
            formdata.append("img", Cat_Img);
            formdata.append("Cat_Meta_Keyword", values.Cat_Meta_Keyword);
            formdata.append("Cat_Status", values.Cat_Status);
            formdata.append("is_Premium", is_Premium);
            console.log(formdata);
            JSON.stringify(formdata);
            axios.post('/api/category/AddCategory', formdata, {
                headers: {
                    'x-access-token': token,

                }
            }).then((res) => {
                if (res.data.code === 200) {
                    toast.success('Add Category Done')
                    navigate('/app/course-category');
                }
            }).catch(() => {
                toast.error('Please try again later')
            })

        },
    });


    const {
        errors,
        touched,
        values,
        setFieldValue
    } = formik;


    return (
        <div style={{ marginBottom: "4rem" }}>
            <form onSubmit={formik.handleSubmit}>
                <div className='hide-in-desktop'>
                    <div className='d-flex profile-section'>
                        <p className='profile-head'>Add Category</p>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Add Category</p>
                    </div>
                </div>
                <div className='hide-in-mobile'>
                    <div className='profile-section'>
                        <p className='profile-head'>Add Category</p>
                        <div className='d-flex'>
                            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                            <p className='profile-pera'>Add Category</p>
                        </div>
                    </div>
                </div>
                <div className="draft-input1">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Category Name</label>
                            <input type="text" class="form-control" placeholder="Enter category name here" name='Cat_Category_Name' value={values.Cat_Category_Name} onChange={(e) => {
                                handleCat_category_name(e);
                                formik.handleChange(e);
                            }} />
                            {errors.Cat_Category_Name && touched.Cat_Category_Name && (
                                <p style={{ color: "red" }}>{errors.Cat_Category_Name}</p>
                            )}
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Short Description</label>
                            <input type="text" class="form-control" placeholder="Enter your short description here" name='Cat_Short_Description' value={values.Cat_Short_Description} onChange={(e) => {
                                handleCat_Short_Description(e);
                                formik.handleChange(e);
                            }} />

                            {errors.Cat_Short_Description && touched.Cat_Short_Description && (
                                <p style={{ color: "red" }}>{errors.Cat_Short_Description}</p>
                            )}
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Slug</label>
                            <input type="text" class="form-control" placeholder="Enter slug here" name='Cat_URL_Slug' value={Cat_URL_Slug} onChange={(e) => {
                                handleCat_URL_Slug(e);
                                formik.handleChange(e);
                            }} />
                            {errors.Cat_URL_Slug && touched.Cat_URL_Slug && (
                                <p style={{ color: "red" }}>{errors.Cat_URL_Slug}</p>
                            )}
                        </div>
                    </div>
                    <div className="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Canonical URL</label>
                            <input type="text" class="form-control" placeholder="Enter canonical url here" name='Cat_Canonical' value={values.Cat_Canonical} onChange={(e) => {
                                handleCat_Canonical(e);
                                formik.handleChange(e);
                            }} />

                        </div>
                    </div>
                </div>
                <div className="draft-input2">
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Browse Category Icon</label>
                            <input type="file" class="form-control" placeholder="Enter you heading / title here" name='Cat_Img' onChange={(e) => {
                                handleCat_Img(e); formik.handleChange(e);
                            }} />
                            <img src={file} style={{ height: 100 }} />
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group status-dropdown">
                            <label for="exampleInputEmail1">Status</label>
                            <select class="form-select" aria-label="Default select example" name='Cat_Status' value={values.Cat_Status} onChange={(e) => {
                                handleCat_Status(e);
                                formik.handleChange(e);
                            }} >
                                <option selected>Choose</option>
                                <option value="Active">Active</option>
                                <option value="Away">Away</option>
                            </select>
                            {errors.Cat_Status && touched.Cat_Status && (
                                <p style={{ color: "red" }}>{errors.Cat_Status}</p>
                            )}
                        </div>
                    </div>
                    <div className="draft-mr">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Twitter Cards</label>
                            <input type="text" class="form-control" placeholder="Enter twitter card here" name='Cat_Twitter_Cards' value={values.Cat_Twitter_Cards} onChange={(e) => {
                                handleCat_Twitter_Cards(e);
                                formik.handleChange(e);
                            }} />

                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">OG Tags</label>
                            <input type="text" class="form-control" placeholder="Enter og tags here" name='Cat_OG_Tag' value={values.Cat_OG_Tag} onChange={(e) => {
                                handleCat_OG_Tag(e);
                                formik.handleChange(e);
                            }} />

                        </div>
                    </div>
                </div>
                <div className='premium-toggle'>
                    <div class="form-group">
                        <label> Premium</label>
                        <div className="toggle-switch">
                            <input type="checkbox" className="checkbox1" onChange={(e) => handleChangepremium(e)} checked={checked} name="checkbox" id="fgfgfg" />
                            <label className="label" htmlFor="fgfgfg">
                                <span className="inner" />
                                <span className="switch" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="draft-mr2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Meta Title</label>
                        <input type="text" class="form-control" placeholder="Enter meta tittle here" name='Cat_Meta_Title' value={values.Cat_Meta_Title} onChange={(e) => {
                            handleCat_Meta_Title(e);
                            formik.handleChange(e);
                        }} />

                    </div>
                </div>
                <div className="draft-mr2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Meta Keywords</label>
                        <input type="text" class="form-control" placeholder="Enter meta keywords here" name='Cat_Meta_Keyword' value={values.Cat_Meta_Keyword} onChange={(e) => {
                            handleCat_Meta_Keyword(e);
                            formik.handleChange(e);
                        }} />

                    </div>
                </div>
                <div className="draft-mr2">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Meta Descriptions</label>
                        <input type="text" class="form-control" placeholder="Enter meta description here" name='Cat_Meta_Description' value={values.Cat_Meta_Description} onChange={(e) => {
                            handleCat_Meta_Description(e);
                            formik.handleChange(e);
                        }} />

                    </div>
                </div>



                {/* Editor */}
                <div>
                    <div className="draft-update-btn margin-top-1">
                        <button type="submit">Update</button>
                    </div>
                </div></form>
            <ToastContainer />
        </div>
    )
}
