import React, { useState,useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import BlogEdit from "../../../src/images/purple-pencil.svg";
import ChapterImg from "../../../src/images/chapter-img.png";
import CCIcon from "../../../src/images/cc-icon.svg";
import GreyTick from "../../../src/images/grey-tick.svg";
import PurpleTick from "../../../src/images/purple-tick.svg";
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
export const UserAnalytics = () => {
    const {link} = useParams();
    const [data,setData]=useState('');
    const baseurl = axios.defaults.baseURL
    const [analyticdata,setanalyticdata]=useState('');
    const { user } = useSelector((state) => state.user);
    const token = user.token
    useEffect(()=>{
       
        fetchdata();
        fetchanlydata();
      },[])
    const fetchdata = ()=>{
        axios.get("/edituser/"+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
           
            setData(res.data.data);
        });
    }
    const fetchanlydata = ()=>{
        axios.get("/getvideoanalytic/"+link,{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
           
            setanalyticdata(res.data.data);
        });
    }
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>User Profile</p>
                <p className='profile-home'><img src="./images/purple-home.svg" />Page</p>
                <p className='profile-pera'>User Profile</p>
            </div>
            <div className='user-view-section'>
                <div className='user-view-col'>
                    <div className='profile-info1'>
                        <div>
                        <img src={baseurl+data.img} width={200} height={200} />
                        </div>
                        <div>
                            <p>Students Name: <span>{data.first_name+" "+data.last_name}</span></p>
                            <p>Student Mobile Number: <span>{data.mobile_number}</span></p>
                            <p>WhatsApp Mobile No : <span>{data.whatsapp_numbers}</span></p>
                            <p>Student Email Id: <span>{data.student_email}</span></p>
                            <p>Parents Email Id: <span>{data.parent_email}</span></p>
                            <p>Gender <span>{data.gender}</span></p>
                            <p>DOB: <span>{data.dob ? (data.dob.split("T"))[0] : "NA"}</span></p>
                        </div>
                    </div>
                    <div></div>
                    <div className='profile-info2'>
                    <div>
                            <p>Full Address: <span>{data.address}</span></p>
                            <p>Country : <span>{data.country_id}</span></p>
                            <p>State: <span>{data.state_id}</span></p>
                            <p>City: <span>{data.city_id}</span></p>
                            {/* <p>Zip Code: <span></span></p> */}
                            <p>Registration Date & Time: <span> {data.createdAt ? (data.createdAt.split("T"))[0] : "NA"}</span></p>
                        </div>
                        <div>
                            <img src={BlogEdit} />
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <h3>Order & Analytics</h3>
                    <div className='order-analytics'>
                    <Link to={"/app/view-user/"+link}><button >Order History</button></Link>
                        <Link to={"/app/user-analytics/"+link}><button className='account-btn'>Analytics</button></Link>
                        <Link to={"/app/user-change-password/"+link}><button >Change Password</button></Link>
                    </div>
                </div>
                {analyticdata && analyticdata.map((item,i)=>(
                    <div className='chapter-section-bg' key={i}>
                    <div className='chapter-section'>
                        <div>
                            <img height={100} src={baseurl+item.img} />
                        </div>
                        <div className='chapter-head'>
                            <div>
                                <h6>{item.Course}:</h6>
                                <h6> {item.Video_title}</h6>
                                <p><img src={CCIcon} /> Video - {item.Video_Duration}</p>
                                <div className='chapter-progress'>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">100%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='progress-verify'>
                            <img src={PurpleTick} />
                        </div>
                    </div>
                </div>
                ))}
                
                
            </div>
        </div>
    )
}
