import React from 'react';
import { Link } from 'react-router-dom';
import WebMingo from "../../../src/images/web-mingo.png";
import "./smsApiPage.css";
export const SmsApiPage = () => {
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>SMS API</p>
                <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                <p className='profile-pera'>SMS API</p>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button >Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
                <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
                <Link to="/app/email-api"><button>Email</button></Link>
                <Link to="/app/sms-api"><button  className='account-btn'>SMS</button></Link>
                <Link to="/app/socialmedia"><button>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button>Header & Footer</button></Link>
                <Link to="/app/storage-api"><button>Storage</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
                <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
                <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

            </div>

            <div className='sms-api-inputs'>
                <div className='company-logo'>
                    <img src={WebMingo} />
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">API Key</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                </div>
                <div>
                    <div class="form-group margin-top-3">
                        <label for="exampleInputEmail1">Auth Key</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                    <div className='update-btn'><button>Update</button></div>
                </div>
                
            </div>
           
        </div>
    )
}
