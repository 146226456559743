import React, { useEffect, useState } from 'react';
import BlogImg from "../../../src/images/blog.png"
import { devInstance as axios } from "../../Plugin/axios";

export const ViewCoursesModal = (props) => {
    const [data,setData]=useState('')
    const baseurl = axios.defaults.baseURL;
    useEffect(()=>{
        const data = { '_id':props.data }
        axios.post("/api/course/get_ID_Course", data).then(res => {
        setData(res.data.data)

    });
    },[])
    console.log(data)
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Category</h6>
                    <p>{data.CategoryId}</p>
                </div>
                <div>
                    <h6>Course Name</h6>
                    <p>{data.Course_Name}</p>
                </div>
                <div>
                    <h6>Image</h6>
                    <p><img src={baseurl+data.img} style={{"height":100,"width":100}} /></p>
                </div>
                <div>
                    <h6>Course Duration</h6>
                    <p>{data.Course_Duration
}</p>
                </div>
                <div>
                    <h6>Url Slug</h6>
                    <p>{data.Course_URL_Slug}</p>
                </div>
                <div>
                    <h6>Course Inclusion</h6>
                    <p dangerouslySetInnerHTML={{__html:data.Course_Inclusion}}></p>
                </div>
                <div>
                    <h6>What You Learn</h6>
                    <p dangerouslySetInnerHTML={{__html:data.learn}}></p>
                </div>
                <div>
                    <h6>Course Detail</h6>
                    <p dangerouslySetInnerHTML={{__html:data.Course_Details_Description}}></p>
                </div>
                {
                   data.Features_Heading && JSON.parse(data.Features_Heading).map((item,index)=>
                       (
                        <>
                         <div>
                        <h6>Features Heading</h6>
                        <p>{item}</p>
                    </div>
                    <div>
                        <h6>Features Details</h6>
                        <p>{JSON.parse(data.Features_Details)[index]}</p>
                    </div>
                        </>
                       )
                        
                    )
                }
               
                <div>
                    <h6>Course MRP</h6>
                    <p>{data.Course_Fees}</p>
                </div>
                <div>
                    <h6>Discount</h6>
                    <p>{data.Course_Discount
}</p>
                </div>
                <div>
                    <h6>Offered Price</h6>
                    <p>{data.Course_Offered_Price}</p>
                </div>
                <div>
                    <h6>Canonical URL</h6>
                    <p>{data.Course_Canonical}</p>
                </div>
                <div>
                    <h6>Twitter Cards</h6>
                    <p>{data.Course_Twitter_Card}</p>
                </div>
                <div>
                    <h6>OG Tags</h6>
                    <p>{data.Course_OG_Tag}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Short Description</h6>
                <p>{data.Course_Short_Description}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Title</h6>
                <p>{data.Course_Meta_Title}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Keywords</h6>
                <p>{data.Course_Meta_Keyword
}</p>
            </div>
            <div className='video-description'>
                <h6>Meta Description</h6>
                <p>{data.Course_Meta_Description}</p>
            </div>
        </div>
    )
}
