import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import 'draft-js/dist/Draft.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Editor, EditorTools,EditorUtils } from '@progress/kendo-react-editor';
import { devInstance as axios } from "../../Plugin/axios";

import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
export const AddNewBlog = () => {
    const { user } = useSelector((state) => state.user); 
    const body1 = React.createRef();
    const token =user.token
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
      } = EditorTools;
const navigate = useNavigate();
const[Blog_title,setBlog_title]=useState('');
const[Url_Slug,setUrl_slug]=useState('');
const[Canonical_URL,setCanonical_URL]=useState('')
const[Twitter_card,setTwitter_card]=useState('');
const[img,setimg]=useState('');
const[file1,setfile1]=useState('');
const[file2,setfile2]=useState('');
const[Banner,setBanner]=useState('');
const[OG_Tags,setOG_Tags]=useState('');
const[short_description,setshort_description]=useState('');
const[body,setbody]=useState('');
const[Meta_title,setMeta_title]=useState('');
const[Status,setStatus]=useState('');
const[Meta_keyword,setMeta_keyword]=useState('');

const handleBlog_title =(e)=>{
    setBlog_title(e.target.value);
    var b = e.target.value.toLowerCase().replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
    setUrl_slug(b);

}

const handleUrl_slug =(e)=>{
    setUrl_slug(e.target.value);
}
const handleCanonical_URL =(e)=>{
    setCanonical_URL(e.target.value);
}
const handleTwitter_card =(e)=>{
    setTwitter_card(e.target.value);
}
const handleimg =(e)=>{
    setimg(e.target.files[0]);
    setfile1(URL.createObjectURL(e.target.files[0]))
}

const handleBanner =(e)=>{
    setBanner(e.target.files[0]);
    setfile2(URL.createObjectURL(e.target.files[0]))
}
const handleOG_Tags =(e)=>{
    setOG_Tags(e.target.value);
}
const handlebody =(e)=>{
    setbody(e.target.value);
}
const handleStatus =(e)=>{
    setStatus(e.target.value);
}
const handleMeta_title =(e)=>{
    setMeta_title(e.target.value);
}
const handleMeta_keyword =(e)=>{
    setMeta_keyword(e.target.value);
}
 
const handleSubmit =async(e)=>{
    e.preventDefault();
    const view1 = body1.current.view;
    var body = EditorUtils.getHtml(view1.state);
    const data ={Blog_title,short_description,Url_Slug,Canonical_URL,Meta_title,Meta_keyword,img,Banner,Status,body,OG_Tags,Twitter_card}
    axios.post('/blog',data, {
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"}}).then((res)=>{
               if(res.data.code===200){
                toast.success('Blog is created')
                navigate('/app/blogPage')
               }else{
                toast.error('please try again later')
               }
            })
}

    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Blog</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Add New Blog</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Blog</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Add New Blog</p>
                    </div>
                </div>
            </div>
            <div className="draft-input1">
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Blog Tittle</label>
                        <input type="text" class="form-control" placeholder=""  value={Blog_title} onChange={handleBlog_title}/>
                    </div>
                </div>
                <div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Url Slug</label>
                        <input type="text" class="form-control" placeholder="" value={Url_Slug} onChange={handleUrl_slug}/>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Canonical URL</label>
                        <input type="text" class="form-control" placeholder="" value={Canonical_URL} onChange={handleCanonical_URL} />
                    </div>
                </div>
                <div className="">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Twitter card </label>
                        <input type="text" class="form-control" placeholder=""  value={Twitter_card} onChange={handleTwitter_card}/>
                    </div>
                </div>
            </div>
            <div className="draft-input2">
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Thumb Image </label>
                        <input type="file" class="form-control" placeholder="" onChange={(e)=>{
                            handleimg(e)
                        }} />
                        {file1 &&(
                <img src={file1} style={{"height":100,"width":100}} />
               )}   
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Banner Image </label>
                        <input type="file" class="form-control" placeholder="" onChange={(e)=>{
                            handleBanner(e)
                        }} />
                        {file2 &&(
                <img src={file2} style={{"height":100,"width":100}} />
               )}   
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">OG Tags </label>
                        <input type="text" class="form-control" placeholder="" value={OG_Tags} onChange={handleOG_Tags} />
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">Status </label>
                        <select class="form-select" aria-label="Default select example" value={Status} onChange={handleStatus}>
                            <option value="Active">Active</option>
                            <option value="Away">Away</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="draft-input2">
            <div class="form-group">
                        <label for="exampleInputEmail1">Blog Short Description</label>
                        <input type="text" class="form-control" placeholder=""  value={short_description} onChange={(e)=>setshort_description(e.target.value)}/>
                    </div>
            </div>
            <div className="draft-mr2">
                <div class="form-group">
                    <label for="exampleInputEmail1">Meta Tittle </label>
                    <input type="text" class="form-control" placeholder=""value={Meta_title} onChange={handleMeta_title}/>
                </div>
            </div>
            <div className="draft-mr2">
                <div class="form-group">
                    <label for="exampleInputEmail1">Meta keywords *</label>
                    <input type="text" class="form-control" placeholder=""value={Meta_keyword} onChange={handleMeta_keyword} />
                </div>
            </div>
            {/* Editor */}
            <div>
                <div className='draft-editor'>
                <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
    height: 300
  }} value={body} ref={body1} onChange={handlebody}  />;
                </div>
                <div className="draft-update-btn">
                    <button onClick={handleSubmit}>Update</button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

