import React, { useState, useEffect } from 'react';
import BlogImg from "../../../../src/images/blog.png"
import BlogEye from "../../../../src/images/purple-eye.svg"
import BlogDelete from "../../../../src/images/purple-delete.svg"
import BlogEdit from "../../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { CategoryModal } from '../../CategoryPage/CategoryModal';
import { devInstance as axios } from "../../../Plugin/axios";
import { useSelector } from "react-redux";
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import { CallbackModal } from './CallbackModal';
export const Callback = () => {
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    const [data, setdata] = useState([])
    const [view, setview] = useState('')
    const [fetchData, setFetchdata] = useState(true)
    const baseurl = axios.defaults.baseURL
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    useEffect(() => {
      getalcallback();
    }, [])

    const getalcallback = ()=>{
      axios.get('/getalcallback', {
        headers: {
            'x-access-token': token,
            'Accept': 'application/json',

        }
    }).then((res) => {
        setdata(res.data.data)
    })
    }
    const deletecallback = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              axios.delete("/deletecallback/"+id, {
                    headers: {
                        'x-access-token': token,
                        'Accept': 'application/json',
        
                    }
                })
                    .then(res => {
                        toast.success('Callback succesfully deleted');
                        getalcallback()
                    })
                    .catch(err => toast.error('Error in Category deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    const approve = (id) => {
      Swal.fire({
          title: 'Are you sure, you want to approve it?',
          icon: 'info',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'yes',
          denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.get("/approvecallback/"+id, {
                  headers: {
                      'x-access-token': token,
                      'Accept': 'application/json',
      
                  }
              })
                  .then(res => {
                      toast.success('Callback succesfully approved');
                      getalcallback()
                  })
                  .catch(err => toast.error('Error in Category deletion'))
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        })
      
  };
    const viewe = (_id) => {
        const data = { _id };
        axios.post("/api/category/get_ID_Category", data).then(res => {


            localStorage.setItem(
                "Detail",
                JSON.stringify(res.data.data)
            );
            fetchDatacall()

        });
    };

    const [lgShow, setLgShow] = useState(false);
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Callbacks</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Callbacks</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Callbacks</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Callbacks</p>
                    </div>
                </div>
            </div>

            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Callbacks</h6>
                    {/* <Link to="/app/add-category"><button>Add Category <img src={BlogAdd} /></button></Link> */}
                </div>
                <div className='mobile-table-scroll' >
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Request Id</th>
                                <th scope="col">Student Name</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Email id</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Detail</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td>#{item.callback_id} </td>
                                    <td>{item.user_detail && item.user_detail[0] ? item.user_detail[0].first_name+" "+item.user_detail[0].last_name : "NA"} </td>
                                    <td>{item.user_detail && item.user_detail[0] ? item.user_detail[0].mobile_number : "NA" } </td>
                                    <td>{item.user_detail && item.user_detail[0] ? item.user_detail[0].user_email : "NA"} </td>
                                    <td>{item.subject_detail && item.subject_detail[0] ? item.subject_detail[0].name : "NA"} </td>
                                    <td>{item.query} </td>
                                    <td>{item.status} </td>
                                    <td className='blog-icons'>

                                        <Link to="#" onClick={() => setview(item)} data-toggle="tooltip" title="Edit Subject"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => deletecallback(item._id)} data-toggle="tooltip" title="Delete Course Category"><img src={BlogDelete} /></Link>
                                      {item.status == "pending" && (
                                        <Link to="#" onClick={() => approve(item._id)} data-toggle="tooltip" title="Approve"><Button className='btn btn-sm'>Close Request</Button></Link>
                                      )}  
                                    </td>
                                </tr>))}

                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CallbackModal data={view} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}
