import React, { useEffect, useState } from "react";
import BlogImg from "../../../src/images/blog.png";
import { devInstance as axios } from "../../Plugin/axios";
import BlogDownload from "../../../src/images/download-icon.svg";
export const ViewCourseVideoModal = (props) => {
  const [data, setData] = useState("");
  const baseurl = axios.defaults.baseURL;
  useEffect(() => {
    const data = { _id: props.data };
    axios.get("/getvideobycourse/" + props.data).then((res) => {
      setData(res.data.data);
    });
  }, []);
  return (
    <div>
      <div className="row">
        {data && data.map((item,i)=>(
            <div className="card mx-3 mb-3" style={{width: "90%"}}>
          <div className="card-body">
            <h5 className="card-title">{item.Video_title}</h5>
            <div className="row">
                <div className="col-md-4">
                <label for="id1">Video(480px)({item._id})</label>
            <iframe id="id1" src={item.webViewLink480.replace("view","preview")} width="100%" height="200px" allow="autoplay"></iframe>
            <a href={item.webContentLink480} download data-toggle="tooltip" title="Download Video"><img src={BlogDownload} /></a>
                </div>
                <div className="col-md-4">
                <label for="exampleInputEmail1">Video(720px)({item._id})</label>
            <iframe src={item.webViewLink720.replace("view","preview")} width="100%" height="200px" allow="autoplay"></iframe>
            <a href={item.webContentLink720} download data-toggle="tooltip" title="Download Video"><img src={BlogDownload} /></a>

                </div>
                <div className="col-md-4">
                <label for="exampleInputEmail1">Video(1080px)({item._id})</label>
            <iframe src={item.webViewLink1080.replace("view","preview")} width="100%" height="200px" allow="autoplay"></iframe>
            <a href={item.webContentLink1080} download data-toggle="tooltip" title="Download Video"><img src={BlogDownload} /></a>

                </div>
            </div>
            
            
            
            
          </div>
        </div>
        ))}
        
       
      </div>
    </div>
  );
};
