import React, { useEffect } from 'react'
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Editor, EditorTools,EditorUtils } from '@progress/kendo-react-editor';
import Loader from '../Loader/Loader';
import ProgressBar from 'react-bootstrap/ProgressBar';

export const VideoForm = () => {
    const body1 = React.createRef();
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
      } = EditorTools;
    const { user } = useSelector((state) => state.user);
    const token =user.token;
   const [category,setCategory]=useState();
   const[Course ,setCourse] =useState();
   const [category2,setCategory2]=useState([]);
   const[Course2 ,setCourse2] =useState([]);
   const[Subject,setsubject]=useState('');
   const[Video_title,setVideo_title]=useState('');
   const[Short_description,setShort_description]=useState('');
   const[Video_Duration,setVideo_Duration]=useState('');
   const[URL_Slug,setURL_Slug]=useState('');
   const[Assisment,setAssisment]=useState('');
   const[videofile,setvideofile]=useState('');
   const[videofile2,setvideofile2]=useState('');
   const[videofile3,setvideofile3]=useState('');
   const[img,setimg]=useState('');
   const[Video1080,setVideo1080]=useState('');
   const[Video720,setVideo720]=useState('');
   const[Video480,setVideo480]=useState('');
   const [file, setFile] = useState();
   const[Status,setStatus]=useState('Active');
   const[webContentLink1080,setwebContentLink1080]=useState('');
   const[webViewLink1080,setwebViewLink1080]=useState('');
   const[webContentLink720,setwebContentLink720]=useState('');
   const[webViewLink720,setwebViewLink720]=useState('');
   const[webContentLink480,setwebContentLink480]=useState('');
   const[webViewLink480,setwebViewLink480]=useState('');
   const[body,setbody]=useState('');
   const[progress1,updateProgress1]=useState(0);
   const[progress2,updateProgress2]=useState(0);
   const[progress3,updateProgress3]=useState(0);
   const [file2, setFile2] = useState();
    const navigate = useNavigate();
    const[isloading,setisloading]=useState(false);
   const handelvideofile=(e)=>{
    setvideofile(e.target.files[0])
  }
  const handlebody =(e)=>{
    setbody(e.target.value);
}
  const handelvideofile2=(e)=>{
    setvideofile2(e.target.files[0])
  }
  const handelvideofile3=(e)=>{
    setvideofile3(e.target.files[0])
  }
  const handleStatus =(e)=>{
    setStatus(e.target.value);
}
  const handelcategory=(e)=>{
    setCategory(e.target.value)
    axios.get('/api/course/getcoursesbycategory/'+e.target.value).then((res) => {
        setCourse2(res.data.data)
    })
  }
  const handelCourse=(e)=>{
    setCourse(e.target.value)
  }
  const handelsubject=(e)=>{
    setsubject(e.target.value)
  }
  const handelVideo_title=(e)=>{
    setVideo_title(e.target.value)
    var b = e.target.value.toLowerCase().replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
    setURL_Slug(b)
  }
  const handelURL_Slug=(e)=>{
    setURL_Slug(e.target.value)
  }

  const handelShort_description=(e)=>{
    setShort_description(e.target.value)
  }
  const handelVideo_Duration=(e)=>{
    setVideo_Duration(e.target.value)
  }
  const handelAssisment=(e)=>{
    setAssisment(e.target.files[0])
    setFile2(URL.createObjectURL(e.target.files[0]))
  }
  const handelimg=(e)=>{
    setimg(e.target.files[0])
    setFile(URL.createObjectURL(e.target.files[0]))
  }
  const videoUpload=(e)=>{
    setisloading(true);
    const data ={'videofile':e.target.files[0]}
    axios.post('/upload',data, {
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress1(percentComplete);
          },
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"
        }}).then((res)=>{
            setVideo1080(res.data.google_id)
            axios.get('/generatePublicUrl/'+res.data.google_id,{
                headers: {
                    'x-access-token': token,
                    'Accept' : 'application/json',}}).then((res)=>{
                        
                        setwebContentLink1080(res.data.webContentLink)
                        setwebViewLink1080(res.data.webViewLink)
                        setisloading(false);
                    })
        }).catch((err)=>{
            console.log(err)
            setisloading(false);
            toast.error('Video not uploaded')
            
        })

  }
  const videoUpload2=(e)=>{
    setisloading(true);
    const data ={'videofile':e.target.files[0]}
    axios.post('/upload',data, {
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress2(percentComplete);
          },
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"
        }}).then((res)=>{
            setVideo720(res.data.google_id)
            axios.get('/generatePublicUrl/'+res.data.google_id,{
                headers: {
                    'x-access-token': token,
                    'Accept' : 'application/json',}}).then((res)=>{
                        
                        setwebContentLink720(res.data.webContentLink)
                        setwebViewLink720(res.data.webViewLink)
                        setisloading(false);
                    });
        }).catch((err)=>{
            console.log(err)
            setisloading(false);
            toast.error('Video not uploaded')
            
        })
    

  }
  const videoUpload3 =(e)=>{
    setisloading(true);
    const data ={'videofile':e.target.files[0]}
    axios.post('/upload',data, {
        onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            console.log(percentComplete);
            updateProgress3(percentComplete);
          },
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"
        }}).then((res)=>{
            setVideo480(res.data.google_id)
            axios.get('/generatePublicUrl/'+res.data.google_id,{
                headers: {
                    'x-access-token': token,
                    'Accept' : 'application/json',}}).then((res)=>{
                        
                        setwebContentLink480(res.data.webContentLink)
                        setwebViewLink480(res.data.webViewLink)
                        setisloading(false);
                    });
        }).catch((err)=>{
            console.log(err)
            setisloading(false);
            toast.error('Video not uploaded')
            
        })

  }
   const handelSubmit =(e)=>{
    const view1 = body1.current.view;
    var body = EditorUtils.getHtml(view1.state);
    const data ={body,Status,webViewLink480,webContentLink480,webViewLink720,webContentLink720,webViewLink1080,webContentLink1080,Video480,Video720,Video1080,"Category":category,Course,Short_description,Video_title,Video_Duration,img,Assisment,URL_Slug}
    
    axios.post('/video',data, {
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',
            "Content-Type": "multipart/form-data"
        }
    
      }).then((res)=>{
       if(res.data.code===200)
       {
        toast.success('Video successfully updated')
        navigate("/app/manage-video");
       }else{
        toast.error('please try again later')
       }
      })
   }

   useEffect(()=>{
    axios.get('/api/category/getCategory',{
        headers: {
            'x-access-token': token,
            'Accept' : 'application/json',}}).then((res)=>{
               setCategory2(res.data.Data)
            })
           
},[])
// useEffect(()=>{
//     axios.get('/api/course/getCourse',{
//         headers: {
//             'x-access-token': token,
//             'Accept' : 'application/json',}}).then((res)=>{
//       setCourse2(res.data.Data)  
//       console.log(res.data.Data)
//     })
// },[])
    return (
        <>
        {isloading && <Loader />}
         <div style={{marginBottom:'5rem'}}>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Add Video</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Add Video</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Add Video</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Add Video</p>
                    </div>
                </div>
            </div>
            <div className="draft-input2">
                <div className="draft-mr">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">Select Category</label>
                        <select class="form-select" aria-label="Default select example" value={category} onChange={handelcategory}>
                            <option value="">Select Category</option>
                            {category2 && category2.map((item,i)=>(
                            <option value={item.Cat_Category_Name}>{item.Cat_Category_Name}</option>))}
                        </select>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">Select Course </label>
                        <select class="form-select" aria-label="Default select example" value={Course} onChange={handelCourse}>
                            <option value="">Select Course</option>
                            {Course2 && Course2.map((item,i)=>(
                            <option value={item.Course_Name}>{item.Course_Name}</option>))}
                        </select>
                    </div>
                </div>
                
                <div className="">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Enter Video Title </label>
                        <input type="text" class="form-control" placeholder="Enter video tittle" value={Video_title} onChange={handelVideo_title} />
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group status-dropdown">
                        <label for="exampleInputEmail1">Status </label>
                        <select class="form-select" aria-label="Default select example" value={Status} onChange={handleStatus}>
                            <option value="Active">Active</option>
                            <option value="Away">Away</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="draft-input2">
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Video Duration</label>
                        <input type="text" class="form-control" placeholder="Enter video duration here" value={Video_Duration} onChange={handelVideo_Duration} />
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Short Description </label>
                        <input type="text" class="form-control" placeholder="Enter short description here" value={Short_description}  onChange={handelShort_description}/>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">URL Slug</label>
                        <input type="text" class="form-control" placeholder="Enter url slug here" value={URL_Slug}  onChange={handelURL_Slug}/>
                    </div>
                </div>
                <div >
                    <div class="form-group">
                        <label for="exampleInputEmail1">Select Assignment File</label>
                        <input type="file" class="form-control" placeholder=""  onChange={(e)=>{handelAssisment(e)}}/>
                        <iframe src={file2}></iframe>
                    </div>
                </div>
            </div>
            <div className="draft-input2">
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Select Video Image</label>
                        <input type="file" class="form-control" placeholder="" onChange={(e)=>{
                            handelimg(e)
                        }} />
                     {file && (
                        <img src={file} style={{"height":100,"width":100}} />
                     )}   
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Select Video File(1080px)</label>
                        <input type="file" class="form-control" placeholder=""   onChange={(e)=>{videoUpload(e)}}/>
                       {
                        progress1 > 0 && <ProgressBar now={progress1} label={`${progress1}%`}/>
                       }
                        
                        <iframe src={webViewLink1080.replace("view","preview")} width="400" height="200" allow="autoplay"></iframe>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Select Video File(720px)</label>
                        <input type="file" class="form-control mb-3" placeholder=""   onChange={(e)=>{videoUpload2(e)}}/>
                        {
                        progress2 > 0 && <ProgressBar now={progress2} label={`${progress2}%`}/>
                       }
                        <iframe src={webViewLink720.replace("view","preview")} width="400" height="200" allow="autoplay"></iframe>
                    </div>
                </div>
                <div className="draft-mr">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Select Video File(480px)</label>
                        <input type="file" class="form-control mb-3" placeholder=""   onChange={(e)=>{videoUpload3(e)}}/>
                        {
                        progress3 > 0 && <ProgressBar now={progress3} label={`${progress3}%`}/>
                       }
                        <iframe src={webViewLink480.replace("view","preview")} width="400px" height="200px" allow="autoplay"></iframe>
                    </div>
                </div>
            </div>
            {/* Editor */}
            <div>
                <div className='about-editor'>
                <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
    height: 300
  }} value={body} ref={body1} onChange={handlebody}  />;
                </div>
                <div className="draft-update-btn">
                {isloading ==false ? <button onClick={handelSubmit}>Add</button> : <button onClick={()=>alert("Please wait video is still uploading")} className='btn btn-danger'>...please Wait</button> }
                </div>
            </div>
            <ToastContainer/>
        </div>
        </>
       
    )
}
