import React, { useState } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import BlogDownload from "../../../src/images/download-icon.svg";
import Modal from 'react-bootstrap/Modal';
import { VideoModal } from './VideoModal';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { devInstance as axios } from "../../Plugin/axios";
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
export const ManageVideo = () => {
    const [video, setvideo] = useState([])
    const [view, setview] = useState('')

    const [videow, setvideow] = useState()
    const [videoc, setvideoc] = useState()
    const [videog, setvideog] = useState()
    const { user } = useSelector((state) => state.user);
    const token = user.token;
    const baseurl = axios.defaults.baseURL;
    useEffect(() => {
        fetchDatacall();
    }, [])
    const fetchDatacall=()=>{
        axios.get('/videos', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
            }
        }).then((res) => {
            console.log(res.data.data)
            setvideo(res.data.data)
            
        })
    }
    const [lgShow, setLgShow] = useState(false);
    var data = '';
    var datad = '';
    useEffect(()=>{
        
    },[videog])
    const deletevideo = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.post('/videosDelete/'+id,{},{
                    headers: {
                        'x-access-token': token,
                        'Accept' : 'application/json',
                        "Content-Type": "multipart/form-data"}}).then(res => {
                        fetchDatacall()
                        toast.success('Video succesfully deleted');
                    })
                    .catch(err => toast.error('Error in Blog  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage Video’s</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage Video’s</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage Video’s</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage Video’s</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table table-scroll'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Video’s</h6>
                    <Link to="/app/video-form"><button variant="primary">Add <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Image</th>
                                <th scope="col">Course Name</th>
                                <th scope="col">Course Category</th>
                                <th scope="col">Video Tittle</th>
                                <th scope="col">Time Duration</th>
                                <th scope="col">Video Link</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {video && video.map((item, i) => (
                                <tr key={i}>
                                    
                                    <td>{item.createdAt}</td>
                                    <td><img src={`${baseurl+item.img}`} style={{ width: '5rem' }} /></td>
                                    <td>{item.Course}</td>
                                    <td>{item.Category} </td>
                                    <td>{item.Video_title}</td>
                                    <td>{item.Video_Duration}</td>
                                    <td>{item.webViewLink1080}</td>
                                    <td>{item.Status}</td>
                                    <td className='blog-icons'>
                                        <Link to={"/app/edit-video/"+item._id} data-toggle="tooltip" title="Edit Video"><img src={BlogEdit} /></Link>
                                        <a href={item.webContentLink1080} download data-toggle="tooltip" title="Download Video 1080px"><img src={BlogDownload} /></a>
                                        <a href={item.webContentLink720} download data-toggle="tooltip" title="Download Video 720px"><img src={BlogDownload} /></a>
                                        <a href={item.webContentLink480} download data-toggle="tooltip" title="Download Video 480px"><img src={BlogDownload} /></a>
                                        <Link to="#" onClick={()=>setview(item._id)} data-toggle="tooltip" title="View Video Details"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link onClick={() => deletevideo(item._id)} data-toggle="tooltip" title="Delete Video"><img src={BlogDelete} /></Link>
                                    </td>
                                </tr>))}

                        </tbody>
                    </table>
                </div>
            </div>
            {/* Modal */}
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VideoModal data={view} />
                </Modal.Body>
            </Modal>
            <ToastContainer />

        </div>
    )
}
