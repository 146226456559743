import React, { useState ,useEffect} from 'react';
import BlogImg from "../../../src/images/blog.png"
import { devInstance as axios } from "../../Plugin/axios";
export const ViewTeamModal = (props) => {
    console.log(props)
    const [data,setData]=useState('')
    const baseurl=axios.defaults.baseURL;
    useEffect(()=>{
        viewe();
    },[])
    const viewe = () => {
        const data = { _id:props.data };
        axios.post("/teambyid", data).then(res => {
            setData(res.data.data);
        });
    };
  
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Full Name</h6>
                    <p>{data.FullName}</p>
                </div>
                <div>
                    <h6>Designation</h6>
                    <p>{data.Designation}</p>
                </div>
                <div>
                    <h6>Status:</h6>
                    <p>{data.status}</p>
                </div>
                <div>
                    <h6>Profile Image </h6>
                    <img src={`${baseurl+data.img}`} style={{width:'5rem'}} />
                </div>
            </div>
            <div className='video-description'>
                <h6>Short Detail</h6>
                <p>{data.ShortDetail
}</p>
            </div>
        </div>
    )
}
