import React, { useState, useEffect } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { json, Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ViewTeamModal } from './ViewTeamModal';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';



////////////////////// our Team Fuctional component///////////////////////////////////////////////////////////////

export const OurTeamPage = () => {
    const { user } = useSelector((state) => state.user);
    const [teams, setTeams] = useState([])
    const [fetchData, setFetchdata] = useState(true)
    const [page, setPage] = useState(1);
    const [view, setViewId] = useState('');
    const token = user.token
    console.log(token)
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    useEffect(() => {
        axios.get('/get_ourteams', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setTeams(res.data.Data)
        })
    }, [fetchData])
    ////////////////////////////delete api//////////////////////////////////////////////////
    const deleteour_team = (id) => {
        const data = {
            id,

        };
        JSON.stringify(data)
        axios.delete('/delete_ourteam', data)
            .then(res => {
                console.log(res);
                fetchDatacall()
                toast.success('Team member succesfully deleted');
            })
            .catch(err => toast.error('Error in Team member  deletion'))
    };
    const viewe = (_id) => {
        const data = { _id };
        axios.post("/teambyid", data,{
            headers: {
                'x-access-token': token,
                'Accept' : 'application/json'
                }
            }).then(res => {
                console.log(res.data.data)
            localStorage.setItem(
                "team",
                JSON.stringify(res.data.data)
            );
            fetchDatacall()

        });
    };
    const baseurl=axios.defaults.baseURL;
    if (!teams) { <div>No Record Found</div> }
    const [lgShow, setLgShow] = useState(false);
    const navigate = useNavigate();
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Our Team</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Our Team</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Our Team</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Our Team</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Team</h6>
                    <Link to="/app/add-team"><button>Add <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Image</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Short Detail</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams && teams.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.createdAt}</td>
                                    <td><img src={`${baseurl+item.img}`} style={{ width: "100px" }} /></td>
                                    <td>{item.FullName} </td>
                                    <td>{item.Designation}</td>
                                    <td>{item.ShortDetail}</td>
                                    <td>{item.status}</td>
                                    <td className='blog-icons'>
                                        <Link to={'/app/edit-team/'+item._id} data-toggle="tooltip" title="Edit Team"><img src={BlogEdit} /></Link>
                                        <Link to="#" onClick={() => setViewId(item._id)} data-toggle="tooltip" title="View Team details"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link onClick={() => deleteour_team(item.id)} data-toggle="tooltip" title="Delete Team"><img src={BlogDelete} /></Link>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton >
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewTeamModal data={view} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}
