import React,{useState,useEffect} from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
export const ManageUser = () => {
    const [data,setData]=useState([]);
    const baseurl = axios.defaults.baseURL
    const { user } = useSelector((state) => state.user);
    const token =user.token
    useEffect(()=>{
        fetchdata();
    },[])
    const fetchdata = ()=>{
        axios
        .get("/getuser",{
            headers: {
                'x-access-token': token
            }})
        .then((res) => {
            console.log(res.data.data)
            setData(res.data.data);
        });
    }
    const delete_user = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const data = {
                    id,
        
                };
               
                JSON.stringify(data)
                axios.delete('/deleteuser/'+id,{
                    headers: {
                        'x-access-token': token
                    }})
                    .then(res => {
                        console.log(res);
                        fetchdata()
                        toast.success('User succesfully deleted');
                    })
                    .catch(err => toast.error('Error in User  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };


    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Manage User</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Manage User</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Manage User</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Manage User</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage User</h6>
                    {/* <Link to="/add-home-widget"><button variant="primary">Add <img src={BlogAdd} /></button></Link> */}
                </div>
                <div className='mobile-table-scroll'>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Profile</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Student Email Id</th>
                            <th scope="col">Course Category</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Status</th>
                            <th scope="col" className='table-head-right'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item,index)=>(
                            <tr key={index}>
                            <td>{item.updatedAt}</td>
                            <td>{item.img ?(
                                <img src={baseurl+item.img} style={{height:100,width:100}} />
                            ):"NA"}</td>
                            <td>{item.first_name+""+item.last_name}</td>
                            <td>{item.mobile_number}</td>
                            <td>{item.student_email}</td>
                            <td>{item.course_category_id}</td>
                            <td>{item.gender}</td>
                            <td>{item.user_status == true ? "Active":"De-Active"}</td>
                            <td className='blog-icons table-td-right'>
                                <Link to={"/app/edit-user/"+item._id} data-toggle="tooltip" title="Edit User Details"> <img src={BlogEdit} /></Link>
                                <Link to={"/app/view-user/"+item._id} data-toggle="tooltip" title="View User Details"><img src={BlogEye} /></Link>
                                <Link to="#" onClick={()=>delete_user(item._id)} data-toggle="tooltip" title="Delete User"><img src={BlogDelete} /></Link>
                            </td>
                        </tr>
                        ))}
                        
                        
                    </tbody>
                </table>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
