import React, { useState, useEffect } from "react";
import BlogEye from "../../../src/images/purple-eye.svg";
import BlogDelete from "../../../src/images/purple-delete.svg";
import { Link } from "react-router-dom";
import Download from "../../../src/images/sm-download.svg";
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
export const ManageTransaction = () => {
  const [data, setData] = useState([]);
  const [dataf, setDataf] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [page, setpage] = useState("");
  const [pagef, setpagef] = useState("");
  const [activ1, setactive1] = useState(true);
  const token = user.token;
  useEffect(() => {
    fetchdata();
    fetchdataf();
  }, []);
  const fetchdata = () => {
    axios
      .get("/getactiveorder?page=0&size=15", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setData(res.data.data.docs);
        setpage(res.data.data.totalPages);
      });
  };
  const fetchdataf = () => {
    axios
      .get("/getpendingorder?page=0&size=15", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setDataf(res.data.data.docs);
        setpagef(res.data.data.totalPages);
      });
  };
  const pageclick = (data) => {
    let currentPage = data.selected;
    axios
      .get("/getactiveorder?page=" + currentPage + "&size=15", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setData(res.data.data.docs);
        setpage(res.data.data.totalPages);
      });
  };
  const pageclickf = (data) => {
    let currentPage = data.selected;
    axios
      .get("/getpendingorder?page=" + currentPage + "&size=15", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setDataf(res.data.data.docs);
        setpagef(res.data.data.totalPages);
      });
  };
  return (
    <div>
      <div className="hide-in-desktop">
        <div className="d-flex profile-section">
          <p className="profile-head">Manage Transaction</p>
          <p className="profile-home">
            <img src="../images/purple-home.svg" />
            Page
          </p>
          <p className="profile-pera">Manage Transaction</p>
        </div>
      </div>
      <div className="hide-in-mobile">
        <div className="profile-section">
          <p className="profile-head">Manage Transaction</p>
          <div className="d-flex">
            <p className="profile-home">
              <img src="../images/purple-home.svg" />
              Page
            </p>
            <p className="profile-pera">Manage Transaction</p>
          </div>
        </div>
      </div>
      <div className="pages-tabs admin-table">
        <div className="d-flex justify-content-between">
          <h6>Manage Transaction</h6>
        </div>
        <div>
          <div className="order-analytics manage-transaction">
            <Link to="#">
              <button
                className={activ1 == true ? "account-btn" : ""}
                onClick={() => setactive1(true)}
              >
                Successful Transactions
              </button>
            </Link>
            <Link to="#">
              <button
                className={activ1 == false ? "account-btn" : ""}
                onClick={() => setactive1(false)}
              >
                Pending & Failed Transactions
              </button>
            </Link>
            {/* <Link to="/user-change-password"><button>Refunds</button></Link> */}
          </div>
        </div>
        {activ1 == true ? (
          <div className="transaction-table admin-table table-scroll">
            <table class="table table-striped ">
              <thead>
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Student Name</th>
                  <th scope="col">Email ID</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Sub Total</th>
                  <th scope="col">Discounts</th>
                  <th scope="col">Taxes</th>
                  <th scope="col">Billed Amount</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col" className="table-head-right">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => (
                        <tr key={i}>
                          <td>{item.createdAt}</td>
                          <td>#{item.order_id}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.mobile_number}</td>
                          <td>${item.subtotal}</td>
                          <td>${item.coupon_discount_amount}</td>
                          <td>${item.gst_amount}</td>
                          <td>${item.order_amount_with_gst}</td>
                          <td>{item.payment_status}</td>
                          <td>#{item.trns_id}</td>
                          <td className="blog-icons table-td-right">
                            <Link
                              to={"/app/view-order/" + item._id}
                              data-toggle="tooltip"
                              title="View Order"
                            >
                              <img src={BlogEye} />
                            </Link>
                            <Link
                              to={"/app/invoice/" + item._id}
                              data-toggle="tooltip"
                              title="Download Invoice"
                            >
                              <img src={Download} />
                            </Link>
                            {/* <Link to="#" data-toggle="tooltip" title="Delete Transaction"><img src={BlogDelete} /></Link> */}
                          </td>
                        </tr>
                  ))}
              </tbody>
              <div className="mt-3">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={pageclick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </table>
          </div>
        ) : (
          <div className="transaction-table admin-table table-scroll">
            <table class="table table-striped ">
              <thead>
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Student Name</th>
                  <th scope="col">Email ID</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Sub Total</th>
                  <th scope="col">Discounts</th>
                  <th scope="col">Taxes</th>
                  <th scope="col">Billed Amount</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col" className="table-head-right">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataf &&
                  dataf.map((item, i) => (
                        <tr key={i}>
                          <td>{item.createdAt}</td>
                          <td>#{item.order_id}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.mobile_number}</td>
                          <td>${item.subtotal}</td>
                          <td>${item.coupon_discount_amount}</td>
                          <td>${item.gst_amount}</td>
                          <td>${item.order_amount_with_gst}</td>
                          <td>{item.payment_status}</td>
                          <td>#{item.trns_id}</td>
                          <td className="blog-icons table-td-right">
                            <Link
                              to={"/app/view-order/" + item._id}
                              data-toggle="tooltip"
                              title="View Order"
                            >
                              <img src={BlogEye} />
                            </Link>
                            <Link
                              to={"/app/invoice/" + item._id}
                              data-toggle="tooltip"
                              title="Download Invoice"
                            >
                              <img src={Download} />
                            </Link>
                            {/* <Link to="#" data-toggle="tooltip" title="Delete Transaction"><img src={BlogDelete} /></Link> */}
                          </td>
                        </tr>
                  ))}
              </tbody>
              <div className="mt-3">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pagef}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={pageclickf}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
