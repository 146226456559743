import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./socialMedia.css"
import { ToastContainer, toast } from 'react-toastify';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";

export const SocialMedia = () => {
    const { user } = useSelector((state) => state.user);
    const [err, seterr] = useState('');
    const [success, setsuccess] = useState('');
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [pinterest, setpinterest] = useState('');
    const [twitter, settwitter] = useState('');
    const [linkedin, setlinkedin] = useState('');
    const [youtube, setyoutube] = useState('');
    const [google, setgoogle] = useState('');
    const [facebook_in_footer, setfacebook_in_footer] = useState('');
    const [instagram_in_footer, setinstagram_in_footer] = useState('');
    const [twitter_in_footer, settwitter_in_footer] = useState('');
    const [pinterest_in_footer, setpinterest_in_footer] = useState('');
    const [linkedin_in_footer, setlinkedin_in_footer] = useState('');
    const [youtube_in_footer, setyoutube_in_footer] = useState('');
    const [google_in_footer, setgoogle_in_footer] = useState('');

    const token = user.token
    const [input, setInput] = useState({
        facebook: "",
        facebook_in_footer: "",
        instagram: "",
        instagram_in_footer: "",
        twitter: "",
        twitter_in_footer: "",
        pinterest: "",
        pinterest_in_footer: "",
        linkedin: "",
        linkedin_in_footer: "",
        youtube: "",
        youtube_in_footer: "",
        google: "",
        google_in_footer: "",
    });
    const handlesubmit = async (e) => {
        var data = { facebook, facebook_in_footer, instagram, instagram_in_footer, twitter, twitter_in_footer, pinterest, pinterest_in_footer, linkedin, linkedin_in_footer, youtube, youtube_in_footer, google, google_in_footer }
        axios.put('/updatesociallmedia', data, {
            headers: {
                'x-access-token': token,

            }
        }).then((res) => {
            if (res.data.code === 400) {
                toast.error('error found !please try again latter')
                seterr(res.data.message)
            } else {
                toast.success('successfully Updated')
                setsuccess(res.data.message)
            }
        })
    }
    useEffect(() => {
        viewe();
    }, [])
    const viewe = () => {
        axios.get("/getsocialmedia", {
            headers: {
                'x-access-token': token,
            }
        }).then(res => {
            setInput(res.data.data);
            setfacebook(res.data.data.facebook)
            setfacebook_in_footer(res.data.data.facebook_in_footer)
            setinstagram(res.data.data.instagram)
            setinstagram_in_footer(res.data.data.instagram_in_footer)
            settwitter(res.data.data.twitter)
            settwitter_in_footer(res.data.data.twitter_in_footer)
            setpinterest(res.data.data.pinterest)
            setpinterest_in_footer(res.data.data.pinterest_in_footer)
            setlinkedin(res.data.data.linkedin)
            setlinkedin_in_footer(res.data.data.linkedin_in_footer)
            setyoutube(res.data.data.youtube)
            setyoutube_in_footer(res.data.data.youtube_in_footer)
            setgoogle(res.data.data.google)
            setgoogle_in_footer(res.data.data.google_in_footer)
        });
    }
    return (
        <div>
            <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Social Media Settings</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Social Media Settings</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Social Media Settings</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Social Media Settings</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button >Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button >GST & Invoice</button></Link>
                <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
                <Link to="/app/email-api"><button >Email</button></Link>
                <Link to="/app/sms-api"><button>SMS</button></Link>
                <Link to="/app/social-media"><button className='account-btn'>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button >Header & Footer</button></Link>
                <Link to="/app/storage-api"><button>Storage</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token</button></Link>
                <Link to="/app/backup-restore"><button>Backup & Re-Store</button></Link>
                <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

            </div>
            <div className='social-media'>
                <div className='social-media-icons'>
                    <div>
                        <p>Facebook</p>
                        <img src="../icons/facebook.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="" value={facebook} onChange={(e) => setfacebook(e.target.value)} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle1" name="vehicle1" value="1" onChange={(e) => setfacebook_in_footer(e.target.checked ? "1" : "0")} checked={facebook_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle1">Show on Footer</label>
                        </div>
                    </div>
                </div>
                <div className='social-media-icons'>
                    <div>
                        <p>Instagram</p>
                        <img src="../icons/instagram.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={(e) => setinstagram(e.target.value)} placeholder="" value={instagram} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle2" name="vehicle1" value="1" onChange={(e) => setinstagram_in_footer(e.target.checked ? "1" : "0")} checked={instagram_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle2">Show on Footer</label>
                        </div>
                    </div>
                </div>

                <div className='social-media-icons'>
                    <div>
                        <p>Twitter</p>
                        <img src="../icons/twitter.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={(e) => settwitter(e.target.value)} placeholder="" value={twitter} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle3" name="vehicle1" value="1" onChange={(e) => settwitter_in_footer(e.target.checked ? "1" : "0")} checked={twitter_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle3">Show on Footer</label>
                        </div>
                    </div>
                </div>
                <div className='social-media-icons'>
                    <div>
                        <p>Pinterest</p>
                        <img src="../icons/pinterest.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={(e) => setpinterest(e.target.value)} placeholder="" value={pinterest} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle4" name="vehicle1" value="1" onChange={(e) => setpinterest_in_footer(e.target.checked ? "1" : "0")} checked={pinterest_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle4">Show on Footer</label>
                        </div>
                    </div>
                </div>
                <div className='social-media-icons'>
                    <div>
                        <p>Linkelnd</p>
                        <img src="../icons/linkedin.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={(e) => setlinkedin(e.target.value)} placeholder="" value={linkedin} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle5" name="vehicle1" value="1" onChange={(e) => setlinkedin_in_footer(e.target.checked ? "1" : "0")} checked={linkedin_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle5">Show on Footer</label>
                        </div>
                    </div>
                </div>
                <div className='social-media-icons'>
                    <div>
                        <p>Youtube</p>
                        <img src="../icons/youtube.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={(e) => setyoutube(e.target.value)} placeholder="" value={youtube} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle6" name="vehicle1" value="1" onChange={(e) => setyoutube_in_footer(e.target.checked ? "1" : "0")} checked={youtube_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle6">Show on Footer</label>
                        </div>
                    </div>
                </div>
                <div className='social-media-icons'>
                    <div>
                        <p>G+</p>
                        <img src="../icons/google-plus.svg" />
                    </div>
                    <div className='social-media-mt'>
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={(e) => setgoogle(e.target.value)} placeholder="" value={google} />
                        </div>
                    </div>
                    <div className='checkbox-mt'>
                        <div className='checkbox checkbox-mr'>
                            <input type="checkbox" id="vehicle7" name="vehicle1" value="1" onChange={(e) => setgoogle_in_footer(e.target.checked ? "1" : "0")} checked={google_in_footer == "1" ? "true" : ""} />
                            <label for="vehicle7">Show on Footer</label>
                        </div>
                        <button className='update-setting' onClick={handlesubmit}>Update Settings</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
