import axios from "axios";
export const devInstance = axios.create({ baseURL: "https://decodechamps.com/node-backend/Abacusly-Jan-2023/" });
// export const devInstance = axios.create({ baseURL: "http://localhost:8000/" });
// const productionInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL }); // will change later

export default {
    install: (app) => {
        app.config.globalProperties.$https = devInstance;
        app.provide('axios', devInstance)
    }
};