import React, { useState } from 'react';
import BlogImg from "../../../../../src/images/blog.png"
import BlogEye from "../../../../../src/images/purple-eye.svg"
import BlogDelete from "../../../../../src/images/purple-delete.svg"
import BlogEdit from "../../../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { WedgetModal11 } from '../WidgetModal11';
import { useEffect } from 'react';
import { devInstance as axios } from "../../../../Plugin/axios";

export const Widgets12 = () => {
  const [lgShow, setLgShow] = useState(false);

  const [data, setdata] = useState([])
  useEffect(() => {
    axios.get('/getwidgets12').then((res) => {
      setdata(res.data.data)
    })
  }, [])
  return (
    <div>
      <div className='hide-in-desktop'>
        <div className='d-flex profile-section'>
          <p className='profile-head'>Manage Widgets</p>
          <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
          <p className='profile-pera'>Manage Widgets</p>
        </div>
      </div>
      <div className='hide-in-mobile'>
        <div className='profile-section'>
          <p className='profile-head'>Manage Widgets</p>
          <div className='d-flex'>
            <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
            <p className='profile-pera'>Manage Widgets</p>
          </div>
        </div>
      </div>
      <div className='pages-tabs'>
        <Link to="/app/home-widget"><button>Widgets 1</button></Link>
        {/* <Link to="/app/home-widget2"><button className='account-btn'>Widgets 2</button></Link> */}
        <Link to="/app/home-widget3" ><button >Widgets 2</button></Link>
        <Link to="/app/home-widget4" ><button>Widgets 3</button></Link>
        <Link to="/app/home-widget5" ><button>Widgets 4</button></Link>
        {/* <Link to="/app/home-widget6"><button>Widgets 5</button></Link> */}
        <Link to="/app/home-widget7" ><button>Widgets 5</button></Link>
        <Link to="/app/home-widget8" ><button>Widgets 6</button></Link>
        <Link to="/app/home-widget9" ><button>Widgets 7</button></Link>
        <Link to="/app/home-widget10" ><button>Widgets 8</button></Link>
        <Link to="/app/home-widget11"  ><button>Widgets 9</button></Link>
        <Link to="/app/home-widget12" ><button className='account-btn'>Widgets 10</button></Link>
      </div>
      <div className='pages-tabs admin-table'>
        <div className='d-flex justify-content-between'>
          <h6>Manage Widgets 10</h6>
          {/* <Link to="/app/edit-home-widget12"><button variant="primary">Add <img src={BlogAdd} /></button></Link> */}
        </div>
        <table class="table table-striped view-order-table">
          <thead>
            <tr>
              <th scope="col">Date & Time</th>
              <th scope="col">Heading</th>
              <th scope="col">Details Description</th>
              <th scope="col" className='table-head-right'>Action</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((item, i) => (
              <tr key={i}>
                <td>{item.updatedAt}</td>
                <td>{item.heading}</td>
                <td><div
                  dangerouslySetInnerHTML={{ __html: item.Detail_Description }}
                /> </td>
                <td className='blog-icons table-td-right'>
                  <Link to="/app/edit-home-widget12" data-toggle="tooltip" title="Edit Home Widget"> <img src={BlogEdit} /></Link>
                  <Link to="#" data-toggle="tooltip" title="View Home Widgets"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                  {/* <Link to="#" data-toggle="tooltip" title="Delete Home Widgets"><img src={BlogDelete} /></Link> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          show={lgShow}
          onHide={() => setLgShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WedgetModal11 />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}
