import React,{useState,useEffect} from 'react';
import BlogImg from "../../../../src/images/blog.png"
import { devInstance as axios } from '../../../Plugin/axios';
export const ViewHomeModal = () => {
    const [data,setData]=useState('')
    const baseurl=axios.defaults.baseURL;
    useEffect(()=>{
        viewe();
    },[])
    const viewe = () => {
       
        axios.get("/getwidget1").then(res => {
            setData(res.data.data[0]);
           
        });
    };
    
    return (
        <div>
            <div className='video-modal'>
                <div>
                    <h6>Heading:</h6>
                    <p>{data.heading}</p>
                </div>
                <div>
                    <h6>Short Description:</h6>
                    <p>{data.Short_Description}</p>
                </div>
                <div>
                    <h6>Feature Heading1:</h6>
                    <p>{data.Features_heading1}</p>
                </div>
                <div>
                    <h6>Features Image1:</h6>
                    <img src={baseurl+data.img1} />
                </div>
                <div>
                    <h6>Button Text1 :</h6>
                    <p>{data.button1_txt}</p>
                </div>
                <div>
                    <h6>Button Link1 :</h6>
                    <p>{data.ButtonLink1}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Features Description1 : </h6>
                <p>{data.Features_Description1}</p>
            </div>
            <div className='video-modal'>
              
                <div>
                    <h6>Feature Heading2:</h6>
                    <p>{data.Features_heading2}</p>
                </div>
                <div>
                    <h6>Features Image2:</h6>
                    <img style={{height:100}} src={baseurl+data.img2} />
                </div>
                <div>
                    <h6>Button Text2 :</h6>
                    <p>{data.button2_txt}</p>
                </div>
                <div>
                    <h6>Button Link2 :</h6>
                    <p>{data.ButtonLink2}</p>
                </div>
            </div>
            <div className='video-description'>
                <h6>Features Description2 : </h6>
                <p>{data.Features_Description2}</p>
            </div>
            <div className='video-modal'>
              
              <div>
                  <h6>Feature Heading3:</h6>
                  <p>{data.Features_heading2}</p>
              </div>
              <div>
                  <h6>Features Image3:</h6>
                  <img style={{height:100}} src={baseurl+data.img3} />
              </div>
              <div>
                  <h6>Button Text3 :</h6>
                  <p>{data.button3_txt}</p>
              </div>
              <div>
                  <h6>Button Link3 :</h6>
                  <p>{data.ButtonLink3}</p>
              </div>
          </div>
          <div className='video-description'>
              <h6>Features Description4 : </h6>
              <p>{data.Features_Description2}</p>
          </div>
          <div className='video-modal'>
              
              <div>
                  <h6>Feature Heading4:</h6>
                  <p>{data.Features_heading4}</p>
              </div>
              <div>
                  <h6>Features Image4:</h6>
                  <img style={{height:100}} src={baseurl+data.img4} />
              </div>
              <div>
                  <h6>Button Text4 :</h6>
                  <p>{data.button4_txt}</p>
              </div>
              <div>
                  <h6>Button Link4 :</h6>
                  <p>{data.ButtonLink4}</p>
              </div>
          </div>
          <div className='video-description'>
              <h6>Features Description4 : </h6>
              <p>{data.Features_Description4}</p>
          </div>
        </div>
    )
}
