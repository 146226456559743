import React, { useState, useEffect } from 'react';
import BlogImg from "../../../src/images/blog.png"
import BlogEye from "../../../src/images/purple-eye.svg"
import BlogDelete from "../../../src/images/purple-delete.svg"
import BlogEdit from "../../../src/images/purple-pencil.svg";
import BlogAdd from "../../../src/images/blog-add.svg";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ViewContactModal } from './ViewContactModal';
import { devInstance as axios } from "../../Plugin/axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const ContactUs = () => {
    const [lgShow, setLgShow] = useState(false);

    const { user } = useSelector((state) => state.user);
    const [contact, setContact] = useState([])
    const [fetchData, setFetchdata] = useState(true)
    const [page, setPage] = useState(1);
    const [view, setViewId] = useState('');
    const token = user.token
    console.log(token)
    const fetchDatacall = () => { setFetchdata((t) => !t) }
    useEffect(() => {
        axios.get('/getcontactus', {
            headers: {
                'x-access-token': token,
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            setContact(res.data.Data)
        })
    }, [fetchData])
    console.log(contact)

    ////////////////////////////delete api//////////////////////////////////////////////////
    const delete_contact = (id) => {
        Swal.fire({
            title: 'Are you sure, you want to delete it?',
            icon: 'info',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'yes',
            denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const data = {
                    id,
        
                };
               
                JSON.stringify(data)
                axios.delete('/delete_contact/'+id)
                    .then(res => {
                        console.log(res);
                        fetchDatacall()
                        toast.success('Contact Details succesfully deleted');
                    })
                    .catch(err => toast.error('Error in Team member  deletion'))
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
    };




    return (
        <div>
             <div className='hide-in-desktop'>
                <div className='d-flex profile-section'>
                    <p className='profile-head'>Contact Us</p>
                    <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                    <p className='profile-pera'>Contact Us</p>
                </div>
            </div>
            <div className='hide-in-mobile'>
                <div className='profile-section'>
                    <p className='profile-head'>Contact Us</p>
                    <div className='d-flex'>
                        <p className='profile-home'><img src="../images/purple-home.svg" />Page</p>
                        <p className='profile-pera'>Contact Us</p>
                    </div>
                </div>
            </div>
            <div className='pages-tabs admin-table table-scroll'>
                <div className='d-flex justify-content-between'>
                    <h6>Manage Contact</h6>
                    <Link to="/app/contact-us-form"><button>Add <img src={BlogAdd} /></button></Link>
                </div>
                <div className='mobile-table-scroll'>
                    <table class="table table-striped ">
                        <thead>
                            <tr>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Address Name</th>
                                <th scope="col">Full Address</th>
                                <th scope="col">ZipCode</th>
                                <th scope="col">Country</th>
                                <th scope="col">State</th>
                                <th scope="col">City</th>
                                <th scope="col">Email</th>
                                <th scope="col">Whatsapp Number</th>
                                <th scope="col">Mobile Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contact && contact.map((item, i) => (
                                <tr>
                                    <td>{item.createdAt}</td>
                                    <td>{item.FullAddress} </td>
                                    <td>{item.AddressName} </td>
                                    <td>{item.ZipCode}</td>
                                    <td>{item.country}</td>
                                    <td>{item.state}</td>
                                    <td>{item.city}</td>
                                   
                                    <td>{item.email}</td>
                                    <td>{item.whatsapp_number}</td>
                                    <td>{item.mobile_number}</td>

                                    <td className='blog-icons'>
                                        <Link to={'/app/edit-contact-us/'+item._id} data-toggle="tooltip" title="Edit Contact Details"> <img src={BlogEdit} /></Link>
                                        <Link to="#" onClick={()=>setViewId(item._id)} data-toggle="tooltip" title="View Contact Details"><img src={BlogEye} onClick={() => setLgShow(true)} /></Link>
                                        <Link to="#" onClick={() => delete_contact(item._id)} data-toggle="tooltip" title="Delete Contact"><img src={BlogDelete} /></Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewContactModal data={view}/>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    )
}
