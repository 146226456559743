import React from 'react'
import './footer.css';

export const Footer = () => {
    return (
        <div>
            <div class="footer">
                <p>COPYRIGHT © 2022, All rights Reserved</p>
            </div>
        </div>
    )
}
