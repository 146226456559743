import * as Yup from 'yup';


 export const confirmpass = Yup.object().shape({
    ad_password: Yup.string()
        .min(4, 'Password Too Short!')
        .max(50, 'Password Too Long!')
       
        .required('password is required, please enter password'),
     confirmPassword:Yup.string().oneOf([Yup.ref('ad_password'),null],"password must be match")
    });

