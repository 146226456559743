import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import SMTP from "../../images/smtp.png"
import SendGrid from "../../images/send-grid.png";
import "./emailApi.css"
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { devInstance as axios } from "../../Plugin/axios";
export const  EmailApiPage = ()  =>{

    const { user } = useSelector((state) => state.user);
    const [port,setPort]=useState('');
const[host,sethost]=useState('');
const[mailer,setmailer]=useState('');
const[username,setusername]=useState('');
const[encryption,setencryption]=useState('');
const[fromname,setfromname]=useState('');
const[fromemail,setfromemail]=useState('');
const[User,setUser]=useState('');
const[service,setservice]=useState('');
const[ secure,setsecure]=useState('');
const[ pass,setpass]=useState('');
const[_id,set_id]=useState('')
const token = user.token

 useEffect(()=>{
axios.get('/getemail',{
    headers: {
        'x-access-token': token,
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
    }

}).then((res)=>{
   console.log(res.data[0])
    sethost(res.data[0].host)
    setPort(res.data[0].port)
    setUser(res.data[0].user)
    setpass(res.data[0].pass)
    setservice(res.data[0].service)
    setmailer(res.data[0].mailer)
    setusername(res.data[0].user)
    setfromemail(res.data[0].fromemail)
    setfromname(res.data[0].fromname)
    setencryption(res.data[0].encryption)
    set_id('63ce8883c0a7d39f7b2ef606')
})
 },[])



const handlehost = (e) => {
    sethost(e.target.value);
  };
  const handleport = (e) => {
    setPort(e.target.value);
  };
  const handleaduser= (e) => {
    setUser(e.target.value);
  };
  const handleservice = (e) => {
    setservice(e.target.value);
  };
  const handlesecure = (e) => {
    setsecure(e.target.value);
  };
  const handlepass = (e) => {
    setpass(e.target.value);
  };
  const handelsubmit=async(e)=>{
    e.preventDefault();
    const data ={  
    port,
    mailer,
    username,
    encryption,
    fromname,
    fromemail,
    pass,
    host,
    _id
}
JSON.stringify(data)
    axios.put('/email_setting',data, {
        headers: {
            'x-access-token': token,
            
        }
    
      }).then((res)=>{
     if(res.data.code ===400){
        toast.error('error occured try again later')
     }else{
        toast.success('SMTP  success fully updated')
     }


    }
 ) }
    return (
        <div>
            <div className='d-flex profile-section'>
                <p className='profile-head'>Email API</p>
                <p className='profile-home'><img src="../../images/purple-home.svg" />Page</p>
                <p className='profile-pera'>Email API</p>
            </div>
            <div className='pages-tabs'>
                <Link to="/app/account"><button >Account Setting</button></Link>
                <Link to="/app/gst-invoice"><button >GST & Invoice Setting</button></Link>
                <Link to="/app/payment-gateway"><button >Payment Gateway</button></Link>
                <Link to="/app/email-api"><button className='account-btn'>Email</button></Link>
                <Link to="/app/sms-api"><button>SMS</button></Link>
                <Link to="/app/socialmedia"><button>Social Media</button></Link>
                <Link to="/app/headerandfooter"><button>Header and Footer</button></Link>
                <Link to="/app/storageapi"><button >Storage API</button></Link>
                <Link to="/app/firebase-api"><button>Firebase Token API</button></Link>
                <Link to="/app/bank-acount"><button>Bank Account Detail</button></Link>

            </div>
            <div className='email-api-bg'>
                {/* <div className='email-api'>
                    <div className='company-logo'>
                        <img src={SendGrid} />
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">API Key</label>
                            <input type="text" class="form-control" placeholder="" />
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">AUTH Key</label>
                            <input type="text" class="form-control" placeholder="" />
                        </div>
                    </div>
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Hash Key</label>
                            <input type="text" class="form-control" placeholder="" />
                        </div>
                    </div>
                </div> */}

                <div className='email-api-inputs'>
                    <div className='company-logo smtp-mr'>
                        <img src={SMTP} />
                    </div>
                    <div>
                    <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL MAILER</label>
                            <input type="text" class="form-control" placeholder="" value={mailer} onChange={(e)=>setmailer(e.target.value)}/>
                        </div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL HOST</label>
                            <input type="text" class="form-control" placeholder="" value={host} onChange={handlehost}/>
                        </div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL PORT</label>
                            <input type="text" class="form-control" placeholder="" value={port} onChange={handleport}/>
                        </div>
                    </div>
                    <div>
                    <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL USERNAME</label>
                            <input type="text" class="form-control" placeholder="" value={username} onChange={(e)=>setusername(e.target.value)}/>
                        </div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1" >MAIL PASSWORD</label>
                            <input type="text" class="form-control" placeholder="" value={pass} onChange={handlepass}/>
                        </div>
                    </div>
                    <div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL ENCRYPTION</label>
                            <input type="email" class="form-control" placeholder="" value={encryption} onChange={(e)=>setencryption(e.target.value)}/>
                        </div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL FROM</label>
                            <input type="email" class="form-control" placeholder="" value={fromemail} onChange={(e)=>setfromemail(e.target.value)}/>
                        </div>
                        <div class="form-group margin-top-3">
                            <label for="exampleInputEmail1">MAIL FROM NAME</label>
                            <input type="text" class="form-control" placeholder="" value={fromname} onChange={(e)=>setfromname(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                    </div>
                   
                </div>
                <div className='update-btn'  onClick={handelsubmit}><button>Update</button></div>
            </div>
            <ToastContainer/>
        </div>
    )
}
